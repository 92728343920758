import { spIcons } from '@casestack/supplypike-ui';
import { CellValue } from './types';

interface OwnProps<T extends Record<string, CellValue>> {
    state: string;
    rowsWithValidationErrors: Map<number, T>;
    numRows: number;
    dataType: string;
}

export default function ModalInputTableValidationMessage<T extends Record<string, CellValue>>(props: OwnProps<T>) {
    return (
        <>
            {props.state === 'review' && props.rowsWithValidationErrors.size > 0 && (
                <div className="sp-subtitle mt-2">
                    <div className="inline-block align-middle">
                        <spIcons.small.alert />
                    </div>
                    <span className="ml-2">
                        {props.rowsWithValidationErrors.size} of {props.numRows} added {props.dataType} failed
                        validation. Please fix the errors or remove those {props.dataType}.
                    </span>
                </div>
            )}

            {props.state === 'review' && props.rowsWithValidationErrors.size === 0 && (
                <div className="sp-subtitle mt-2">
                    <div className="inline-block align-middle">
                        <spIcons.small.check />
                    </div>
                    <span className="ml-1">All {props.dataType} are ready to submit.</span>
                </div>
            )}
        </>
    );
}
