import { ReactElement, ReactNode, useState } from 'react';
import { useHover, useFloating, useInteractions, offset, flip } from '@floating-ui/react';

type TooltipWrapperAnchor = 'left' | 'right' | 'top';

interface OwnProps {
    content: ReactNode;
    children: ReactNode;
    tooltipOffset?: number;
    anchor?: TooltipWrapperAnchor;
    useLight?: boolean;
}

export function TooltipWrapper({ content, children, tooltipOffset, anchor, useLight }: OwnProps): ReactElement {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const { refs, floatingStyles, context } = useFloating({
        placement: anchor,
        ...(offset ? { middleware: [offset(tooltipOffset), flip()] } : {}),
        open: showTooltip,
        onOpenChange: setShowTooltip,
    });

    const hover = useHover(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    // `pointer-events-none` prevents fast mouse movement to tooltip from keeping things open.
    const className = `sp-tooltip ${useLight ? 'light' : 'dark'} small w-max max-w-xs z-10 pointer-events-none`;

    /**
     * `onPointer*` is used instead of `onMouse*` due to the latter's broken
     * behavior in some browsers when a child is a disabled input.
     */
    return (
        <div ref={refs.setReference} {...getReferenceProps()}>
            {children}
            {showTooltip && content && (
                <div
                    className={className}
                    role="tooltip"
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps}
                >
                    {typeof content === 'string' ? <span>{content}</span> : content}
                </div>
            )}
        </div>
    );
}
