import { FC } from 'react';
import { File, Supplier } from '../../../types';
import { PageTableColumn, PageTableColumnWithComponent, PageTableColumnWithKey } from '../../common/page-table';
import Placeholder, { roundedStyle } from '../../common/placeholder';
import { TooltipWrapper } from '../../common/tooltip-wrapper';
import { spIcons } from '@casestack/supplypike-ui';

export const fileNameColumn: PageTableColumnWithKey<File> = {
    key: 'name',
    name: 'File Name',
    placeholder: <Placeholder style={roundedStyle('30ch', '1rem')} />,
    supportsSort: true,
};

export const createdAtColumn: PageTableColumnWithComponent<File> = {
    key: 'createdAt',
    name: 'Date Added',
    alignment: 'right' as const,
    component: (params: { data: File }) => {
        const date = params.data.createdAt;
        return (
            <div>
                {date.toLocaleTimeString('en-US')}, {date.toLocaleDateString('en-US')}
            </div>
        );
    },
    placeholder: (
        <div className="flex justify-end">
            <Placeholder style={roundedStyle('10ch', '1rem')} /> <Placeholder style={roundedStyle('12ch', '1rem')} />
        </div>
    ),
    supportsSort: true,
};

function createSuppliersCell(suppliers: Supplier[]): FC<{
    data: File;
}> {
    const SupplierCell: FC<{ data: File }> = ({ data }) => {
        const suppliersWithFile = suppliers.filter(sup => data.supplierIds.includes(sup.id));
        const allSuppliersWithFile = (
            <>
                {suppliersWithFile.map(supplier => (
                    <div key={`${supplier.id}-${data.id}`}>{supplier.name}</div>
                ))}
            </>
        );
        return (
            <div>
                <TooltipWrapper key={data.id} content={allSuppliersWithFile}>
                    {suppliersWithFile.length && (
                        <p>
                            {suppliersWithFile[0].name}{' '}
                            {suppliersWithFile.length > 1 && `+${suppliersWithFile.length - 1}`}
                        </p>
                    )}
                </TooltipWrapper>
            </div>
        );
    };
    return SupplierCell;
}

export function getSuppliersColumn(suppliers: Supplier[]): PageTableColumn<File> {
    return {
        key: 'supplierNames',
        name: 'Supplier(s)',
        alignment: 'left' as const,
        component: createSuppliersCell(suppliers),
        placeholder: (
            <div>
                <Placeholder style={roundedStyle('10ch', '1rem')} />
            </div>
        ),
        supportsSort: true,
    };
}

function createSourcesCell(integrationIdsToProvidersMap: Map<string, string>): FC<{
    data: File;
}> {
    const SourcesCell: FC<{ data: File }> = ({ data }) => {
        const providerNames: string[] = [];
        for (const integrationId of data.integrationIds) {
            const providerName = integrationIdsToProvidersMap.get(integrationId);
            if (providerName) {
                providerNames.push(providerName);
            }
        }
        const allProvidersList = (
            <>
                {providerNames.map(providerName => (
                    <div key={`${providerName}-${data.id}`}>{providerName}</div>
                ))}
            </>
        );
        return (
            <div className="w-full">
                <TooltipWrapper key={data.id} content={allProvidersList}>
                    <p>
                        {providerNames[0]} {providerNames.length > 1 && `+${providerNames.length - 1}`}
                    </p>
                </TooltipWrapper>
            </div>
        );
    };
    return SourcesCell;
}

export function getSourcesColumn(integrationIdsToProvidersMap: Map<string, string>): PageTableColumn<File> {
    return {
        key: 'providers',
        name: 'Source(s)',
        alignment: 'left' as const,
        component: createSourcesCell(integrationIdsToProvidersMap),
        placeholder: (
            <div>
                <Placeholder style={roundedStyle('10ch', '1rem')} />
            </div>
        ),
        supportsSort: true,
    };
}

export function getDownloadColumn(
    handleClickDownload: (supplierIds: string[], id: string) => void
): PageTableColumn<File> {
    return {
        key: 'download',
        // Don't display a name in the table headers for this column
        name: '',
        alignment: 'right' as const,
        component: (params: { data: File }) => {
            return (
                <div className="flex justify-center">
                    <button
                        type="button"
                        className="sp-icon-button neutral"
                        // The class 'sp-icon-button' sets the height and width to 2.5rem, however we want a smaller size for this component.
                        // So to override those values we put the height and width into the 'style' attribute.
                        style={{ height: '2rem', width: '2rem' }}
                        onClick={() => handleClickDownload(params.data.supplierIds, params.data.id)}
                    >
                        <spIcons.normal.download />
                    </button>
                </div>
            );
        },
        placeholder: <Placeholder style={roundedStyle(40, 40)} className="inline-block" />,
        supportsSort: false,
    };
}
