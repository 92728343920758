import * as React from 'react';

import { spColors } from '@casestack/style-themes';
import { SpButton } from '@casestack/supplypike-ui';

import { DateSelectionOption } from '../logic/date-selection';

export interface DateOptionComponentProps {
    option: DateSelectionOption;
    onClick: (option: DateSelectionOption) => void;
    onMouseEnter: (option: DateSelectionOption) => void;
    onMouseLeave: (option: DateSelectionOption) => void;
    /**
     * Indicates that this option is the start _or_ end of a time range.
     */
    isSelected?: boolean;
    /**
     * Indicates that this option is the start _and_ end of a time range.
     */
    isDoubleSelected?: boolean;
    /**
     * Indicates that this option is the current day/week.
     */
    isNow?: boolean;
    style?: React.CSSProperties;
}

export function DateOptionComponent(props: DateOptionComponentProps): JSX.Element {
    const [hover, setHover] = React.useState<boolean>(false);

    const nowBorderStyle: React.CSSProperties =
        props.isNow && !props.isSelected
            ? {
                  boxShadow: `inset 0px 0px 0px 1px ${spColors.all.neutral500}`,
              }
            : {};
    const doubleSelectBorderStyle: React.CSSProperties = props.isDoubleSelected
        ? {
              boxShadow: `inset 0px 0px 0px 2px ${spColors.all.blue900}`,
          }
        : {};
    const hoverStyle: React.CSSProperties =
        hover && !props.isSelected
            ? {
                  background: spColors.textThemes.light.background.blue100,
              }
            : {};
    const style: React.CSSProperties = {
        ...nowBorderStyle,
        ...doubleSelectBorderStyle,
        ...hoverStyle,
        ...props.style,
    };

    const id = `${props.option.id}`;
    const coords = document.getElementById(id)?.getBoundingClientRect();

    // `onMouseEnter` and `onMouseLeave` are inconsistent, so instead, listen to all mouse events and check bounding box.
    const { onMouseEnter, onMouseLeave, option } = props;
    React.useEffect(() => {
        const handleMouseLeave = (event: MouseEvent) => {
            const x = event.x;
            const y = event.y;
            if (coords) {
                // For computer rendering, y coordinates increase while going down.  So "bottom" has a greater y coordinate than "top".
                if (x < coords.left || x > coords.right || y < coords.top || y > coords.bottom) {
                    if (hover) {
                        setHover(false);
                        onMouseLeave(option);
                    }
                } else {
                    if (!hover) {
                        setHover(true);
                        onMouseEnter(option);
                    }
                }
            }
        };

        document.addEventListener('mousemove', handleMouseLeave);
        return () => {
            document.removeEventListener('mousemove', handleMouseLeave);
        };
    }, [coords, hover, onMouseEnter, onMouseLeave, setHover, option]);

    return (
        <div id={id}>
            <SpButton
                label={props.option.label}
                theme="circle"
                colorTheme={props.isDoubleSelected || props.isSelected ? 'blue' : 'neutral'}
                onClick={() => props.onClick(props.option)}
                disabled={props.option.isDisabled}
                style={style}
            ></SpButton>
        </div>
    );
}
