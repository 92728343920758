import React, { ReactElement, useEffect, useMemo, useState, useContext } from 'react';
import { SpButton } from '@casestack/supplypike-ui';
import { Supplier } from '../../../types';

import PopoverButton from '../../common/popover-button';
import SimpleSelect, { SimpleSelectOption } from '../../common/simple-select';
import { AuthenticatedProps } from '../../../auth/page-with-auth';
import { DateRangeInput } from '../../common/date-inputs/date-range-input';
import {
    DocumentRequestStatus,
    DocumentRequestPendingSubstatus,
    isDocumentRequestStatus,
    purchaseOrderNumberStatuses,
} from '../../../api/types';
import { Integration } from '../../../types';
import { thirdPartyTrackEvent } from '../../../third-party';
import { Tooltip } from '../../common/tooltip';
import { UrlParamsContext } from '../../../contexts/url-params-context';
import { MultiSupplierSelect } from '../../common/multi-supplier-selector';
import { uniqBy } from 'lodash';
import { getSupplierAndRetailerEventData } from '../../../third-party/segment-utils';
import { getRetailersInPriorityOrder } from '../../../util/retailer-utils';
import { getDateFilterLabel, getIntegrationFilterLabel, getStatusFilterLabel } from '../filters-utils';
import { capitalizeFirstCharacter } from '../../../util/string-utils';

export interface PurchaseOrderNumberFilters {
    integrations?: Integration[];
    statuses?: DocumentRequestStatus[];
    gteDate?: Date;
    lteDate?: Date;
}

export interface PurchaseOrderNumbersFiltersProps extends AuthenticatedProps {
    setFiltersCallback: (filters: PurchaseOrderNumberFilters) => void;
    selectedSuppliers?: Supplier[];
    setSelectedSuppliers?: (suppliers: Supplier[]) => void;
    /** This boolean flag will only be here while we are supporting both the single and multi supplier views. */
    useMultiSupplierView?: boolean;
}

const FILTER_KEYS: (keyof PurchaseOrderNumberFilters)[] = ['integrations', 'statuses', 'gteDate', 'lteDate'];

export default function PurchaseOrderNumbersFiltersComponent(props: PurchaseOrderNumbersFiltersProps): ReactElement {
    const { supplier, suppliers, useMultiSupplierView, selectedSuppliers } = props;
    const { searchParams, updateSearchParam, deleteSearchParam } = useContext(UrlParamsContext);
    const [retailers, setRetailers] = useState<{ name: string; isDisabled: boolean }[]>([]);

    // This gets all active retailers for ALL suppliers this user has access to.
    // This is used for populating the retailers in the multiSupplier selector.
    useEffect(() => {
        const activeRetailers: Set<string> = new Set();
        for (const retailer of suppliers?.map(x => x.retailerName) ?? []) {
            activeRetailers.add(retailer);
        }
        const retailersInPriorityOrder = getRetailersInPriorityOrder(Array.from(activeRetailers));
        // We dont care about the 'isDisabled' property since we only display retailers this user has access to.
        // However the MultiSupplierSelector requires 'isDisabled', so we can just always set it to false.
        setRetailers(retailersInPriorityOrder.map(retailer => ({ name: retailer, isDisabled: false })));
    }, [setRetailers, suppliers]);

    const supportedSuppliers = props.suppliers?.map(x => ({ ...x, label: capitalizeFirstCharacter(x.name) }));

    const [datePopoverIsOpen, setDatePopoverIsOpen] = useState(false);

    const statusOptions: SimpleSelectOption<DocumentRequestStatus | DocumentRequestPendingSubstatus>[] = useMemo(
        () =>
            purchaseOrderNumberStatuses.reduce(
                (accu: SimpleSelectOption<DocumentRequestStatus | DocumentRequestPendingSubstatus>[], curr) => {
                    // skip located for PO numbers page
                    if (curr === DocumentRequestPendingSubstatus.LOCATED) {
                        return accu;
                    }
                    return [...accu, { id: curr, label: capitalizeFirstCharacter(curr), value: curr }];
                },
                []
            ),
        []
    );

    // This works for both single and multi supplier views.
    const integrationOptions: SimpleSelectOption<Integration>[] = useMemo(() => {
        if (useMultiSupplierView) {
            const selectedSups = selectedSuppliers ?? [];
            const selectedSuppliersIntegrations = uniqBy(
                selectedSups
                    .flatMap(supplier => supplier?.integrations.map(i => ({ id: i.id, label: i.name, value: i })))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
                integration => integration.id
            );
            return selectedSuppliersIntegrations;
        } else {
            return (
                supplier?.integrations
                    .map(i => ({ id: i.id, label: i.name, value: i }))
                    .sort((a, b) => a.label.localeCompare(b.label)) ?? []
            );
        }
    }, [supplier, selectedSuppliers, useMultiSupplierView]);

    const keyedIntegrationOptions: Record<string, Integration> = useMemo(
        () => Object.assign({}, ...integrationOptions.map(i => ({ [i.id]: i.value }))),
        [integrationOptions]
    );

    /** parse the URL filters and if we turn off a filter if we have selected all options (or no options) */
    const filters = useMemo(() => {
        const rawGte = searchParams.get('gteDate');
        const rawLte = searchParams.get('lteDate');
        const betterFilters: PurchaseOrderNumberFilters = {
            lteDate: rawLte !== null ? new Date(Number.parseInt(rawLte)) : undefined,
            gteDate: rawGte !== null ? new Date(Number.parseInt(rawGte)) : undefined,
            statuses: searchParams.getAll('statuses').reduce((agg: DocumentRequestStatus[], curr) => {
                if (isDocumentRequestStatus(curr)) {
                    return [...agg, curr];
                }
                return agg;
            }, []),
            integrations: searchParams.getAll('integrations').reduce((agg: Integration[], id) => {
                const integration: Integration = keyedIntegrationOptions[id];
                if (integration) {
                    return [...agg, integration];
                }
                return agg;
            }, []),
        };
        if (
            betterFilters.integrations?.length === 0 ||
            betterFilters.integrations?.length === integrationOptions.length
        ) {
            betterFilters.integrations = undefined;
        }
        if (betterFilters.statuses?.length === 0 || betterFilters.statuses?.length === statusOptions.length) {
            betterFilters.statuses = undefined;
        }
        return betterFilters;
    }, [searchParams, integrationOptions, statusOptions, keyedIntegrationOptions]);

    function updateFilters(key: keyof PurchaseOrderNumberFilters, values: string[]) {
        const supplierAndRetailerEventData = getSupplierAndRetailerEventData(selectedSuppliers ?? [], suppliers ?? []);
        updateSearchParam(key, values);
        deleteSearchParam('pageIndex');
        thirdPartyTrackEvent('Filters updated', {
            filters: filters,
            ...(selectedSuppliers && supplierAndRetailerEventData),
        });
    }

    function resetFilters() {
        const supplierAndRetailerEventData = getSupplierAndRetailerEventData(selectedSuppliers ?? [], suppliers ?? []);
        for (const key of FILTER_KEYS) {
            deleteSearchParam(key);
        }
        deleteSearchParam('pageIndex');
        thirdPartyTrackEvent('Filters reset', {
            filters: filters,
            ...(selectedSuppliers && supplierAndRetailerEventData),
        });
    }

    const hasActiveFilters = Object.values(filters).some(v => v !== undefined);

    // send filters data to our parent
    const { setFiltersCallback } = props;
    useEffect(() => {
        setFiltersCallback(filters);
    }, [filters, setFiltersCallback]);

    return (
        <React.Fragment>
            <>
                <PopoverButton
                    theme="filter"
                    key="integration-filter"
                    className="mt-2 ml-2"
                    label={getIntegrationFilterLabel(integrationOptions.length, filters.integrations)}
                    active={!!filters.integrations}
                    disabled={!props.supplier || integrationOptions.length === 0}
                >
                    <SimpleSelect
                        options={integrationOptions}
                        multiple={true}
                        clearText="All Sources"
                        value={filters.integrations ?? []}
                        isEqual={(a, b) => a?.id === b?.id}
                        onChange={values =>
                            updateFilters(
                                'integrations',
                                values.map(i => i.id)
                            )
                        }
                    />
                </PopoverButton>
                <PopoverButton
                    theme="filter"
                    key="status-filter"
                    className="mt-2 ml-2"
                    label={getStatusFilterLabel(filters.statuses)}
                    active={!!filters.statuses}
                    disabled={!props.supplier}
                >
                    <SimpleSelect
                        options={statusOptions}
                        multiple={true}
                        clearText="All Statuses"
                        value={filters.statuses ?? []}
                        onChange={values => updateFilters('statuses', values)}
                    />
                </PopoverButton>
            </>
            <div className="flex align-center" style={{ width: 500 }}>
                <PopoverButton
                    theme="filter"
                    key="date-filter"
                    className="mt-2 ml-2"
                    label={getDateFilterLabel(filters.gteDate, filters.lteDate)}
                    active={filters.gteDate !== undefined || filters.lteDate !== undefined}
                    disabled={!props.supplier}
                    showPopover={datePopoverIsOpen}
                    onClick={() => setDatePopoverIsOpen(true)}
                    onRequestClose={() => setDatePopoverIsOpen(false)}
                >
                    <div className="p-6">
                        <DateRangeInput
                            label="Date Filter"
                            tooltip={
                                <Tooltip title="How To Use">
                                    By default, there is no date filter, meaning we show records for all dates. To
                                    enable a date filter, first select a start date. Then, optionally, select an end
                                    date. Press <b>Apply</b> to apply your changes. If your filter has only start date,
                                    we will show all records on and after that date.
                                </Tooltip>
                            }
                            timeScale={{
                                paginationTimeScale: 'month',
                                subPageTimeScale: 'week',
                                selectionTimeScale: 'day',
                            }}
                            start={filters.gteDate}
                            end={filters.lteDate}
                            noSelectImplies="unbounded range"
                            singleSelectImplies="unbounded end"
                            onRequestClose={() => {
                                setDatePopoverIsOpen(false);
                            }}
                            onChange={(start: Date | undefined, end: Date | undefined) => {
                                updateFilters('gteDate', start ? [start.valueOf().toString(10)] : []);
                                updateFilters('lteDate', end ? [end.valueOf().toString(10)] : []);
                            }}
                        />
                    </div>
                </PopoverButton>
                {useMultiSupplierView && supportedSuppliers && selectedSuppliers && (
                    <MultiSupplierSelect
                        retailers={retailers}
                        supportedSuppliers={supportedSuppliers}
                        selectedSuppliers={selectedSuppliers}
                        selectedSuppliersChanged={props.setSelectedSuppliers}
                    />
                )}
                <div className="mt-2 ml-2">
                    <SpButton
                        label="reset filters"
                        theme="text"
                        colorTheme="neutral"
                        disabled={!props.supplier || !hasActiveFilters}
                        onClick={resetFilters}
                    ></SpButton>
                </div>
            </div>
        </React.Fragment>
    );
}
