import { spColors, spTypography } from '@casestack/style-themes';

import { WeekSelectionTimeScale, WmWeekSelectionTimeScale } from '../logic/time-scale';

export interface DateSubPageLabelProps {
    timeScale: WeekSelectionTimeScale | WmWeekSelectionTimeScale;
    label: string;
}

export function DateSubPageLabel(props: DateSubPageLabelProps): JSX.Element {
    return (
        <div
            className={spTypography.h3}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                color: spColors.textThemes.white.text.neutral700,
                height: 32,
                // Actually, we will have 4px more padding from the date-buttons,
                // so it will add to 16px.
                marginBottom: 12,
            }}
        >
            <span>{props.label}</span>
        </div>
    );
}
