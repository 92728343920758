import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import './popover.css';

export type PopoverAnchor = 'left' | 'right';

interface OwnProps {
    visible: boolean;
    onRequestClose: () => void;
    children: ReactNode;
    anchor?: PopoverAnchor;
}

/**
 * A simple dropdown-like component.
 * @todo Improve a11y.
 */
export default function Popover(props: OwnProps): ReactElement | null {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.visible) {
            containerRef.current?.focus();
        }
    }, [props.visible]);

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape' && props.visible) {
            props.onRequestClose();
            event.stopPropagation();
        }
    }

    const anchorClassName = props.anchor === 'right' ? 'top-0 right-0' : 'top-0 left-0';

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return !props.visible ? null : (
        <div className="popover-container" role="dialog" onKeyDown={handleKeyDown} ref={containerRef}>
            <div className="popover-background" onClick={props.onRequestClose} role="none" tabIndex={-1} />
            <div className={`popover-content ${anchorClassName}`}>{props.children}</div>
        </div>
    );
    /* eslint-enable */
}
