import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import AddNewModalValidationMessage from '../../../common/modal-input-table/modal-input-table-validation-message';
import { CreateDocumentRequestDto } from '../../../../api/types';
import ToastsContext from '../../../../contexts/toasts-context';
import { thirdPartyTrackEvent } from '../../../../third-party';
import { Integration, Supplier } from '../../../../types';
import CloseButton from '../../../common/close-button';
import ModalInputTable from '../../../common/modal-input-table';
import { ModalTableColumn } from '../../../common/modal-input-table/types';
import { PonModalRow } from './types';
import { requiredColumns, optionalColumns } from './columns';
import { normalizeIntegrationName } from './utils';
import ModalInputTableConfirmationPopup from '../../../common/modal-input-table/modal-input-table-confirmation-popup';
import ModalInputTableFooter from '../../../common/modal-input-table/modal-input-table-footer';
import { pluralize } from '../../../../util/string-utils';
import { setRow, addFinalBlank, isRowBlank, addRows, deleteRow } from '../add-new-modal/utils';
import Modal from '../../../common/modal';
import PopoverButton from '../../../common/popover-button';
import { truncate } from '../../../../util/string-utils';
import SimpleSelect from '../../../common/simple-select';
import { AddNewModalProps } from '../add-new-modal/tabs';
import ContextNotification from '../../../common/context-notification';
import { spIcons } from '@casestack/supplypike-ui';

/** Mostly arbitrary. */
const MAX_LABEL_LENGTH = 32;

type ModalState = 'initial' | 'review';

export default function CreatePurchaseOrderNumberModal(props: AddNewModalProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<ModalState>('initial');
    const [rows, setRows] = useState<PonModalRow[]>([{}]);
    const [showPopover, setShowPopover] = useState(false);
    const [optionalCols, setOptionalCols] = useState<ModalTableColumn<PonModalRow>[]>([]);
    const [carrierIntegrations, setCarrierIntegrations] = useState<Integration[]>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(getInitialSelectedSupplier());
    const toasts = useContext(ToastsContext);
    const { hasDataCallback } = props;

    const rowsWithValidationErrors: Map<number, PonModalRow> = useMemo(() => {
        const map = new Map<number, PonModalRow>();
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.validationErrors && row.validationErrors.length) {
                map.set(i, row);
            }
        }
        return map;
    }, [rows]);

    function getInitialSelectedSupplier() {
        // If this is not the multiSupplierView, just use the supplier from props
        if (!props.multiSupplierView) {
            return props.supplier;
        } else if (props.selectedSuppliers.length === 1) {
            // If they only have one supplier selected from the mutli supplier selector, default to that one
            return props.selectedSuppliers[0];
        } else {
            // Otherwise, require them to select a supplier
            return null;
        }
    }

    const hasData: boolean = useMemo(() => {
        const hasData = !!rows[0]?.purchaseOrderNumber;
        hasDataCallback(hasData);
        return hasData;
    }, [rows, hasDataCallback]);

    useEffect(() => {
        // We need to update the columns and integrations we show in the modal whenever the selectedSupplier changes
        if (selectedSupplier) {
            const carriers = selectedSupplier.integrations.filter(i => i.isCarrier);
            const cols: ModalTableColumn<PonModalRow>[] = [];

            if (carriers.some(i => i.usesDestinationZip)) {
                const col = optionalColumns.find(col => col.columnName === 'destinationZip');
                if (col) {
                    cols.push(col);
                }
            }
            if (carriers.some(i => i.usesTrackingNumber)) {
                const col = optionalColumns.find(col => col.columnName === 'trackingNumber');
                if (col) {
                    cols.push(col);
                }
            }
            if (carriers.length) {
                const col = optionalColumns.find(col => col.columnName === 'suggestedIntegration');
                if (col) {
                    cols.push(col);
                }
            }
            setOptionalCols(cols);
            setCarrierIntegrations(carriers);
        }
    }, [selectedSupplier]);

    function validateRows() {
        if (selectedSupplier) {
            const lastRow = rows[rows.length - 1];
            const nonBlankRows = lastRow && isRowBlank(lastRow) ? rows.slice(0, rows.length - 1) : rows;

            /**
             * Create a new filtered array based on presence of errors, as well as a
             * mapping back to indices within the original array.
             * This allows us to send a smaller request to the backend for validation
             * while also preserving the order of the original array.
             */
            const { narrowedRows, indexMap } = nonBlankRows.reduce(
                (acc: { narrowedRows: PonModalRow[]; indexMap: Map<number, number> }, row, index) => {
                    if (state === 'initial' || (row.validationErrors && row.validationErrors.length > 0)) {
                        acc.indexMap.set(index, acc.narrowedRows.length);
                        acc.narrowedRows.push(row);
                    }
                    return acc;
                },
                { narrowedRows: [], indexMap: new Map() }
            );

            const dtos: CreateDocumentRequestDto[] = narrowedRows.map(row => ({
                purchaseOrderNumber: row.purchaseOrderNumber,
                destinationZip: row.destinationZip,
                trackingNumber: row.trackingNumber,
                suggestedIntegrationId: row.suggestedIntegration?.id,
            }));

            setLoading(true);
            props.apiClient.validateDocumentRequests(selectedSupplier.id, dtos).then(errorsMap => {
                let numRowsWithValidationErrors = 0;
                const nextRows = nonBlankRows.map((row, index) => {
                    const narrowedIndex = indexMap.get(index);
                    const validationErrors = narrowedIndex !== undefined ? errorsMap.get(narrowedIndex) : undefined;
                    if (validationErrors) {
                        numRowsWithValidationErrors++;
                        return { ...row, validationErrors };
                    } else {
                        return { ...row, validationErrors: undefined };
                    }
                });
                thirdPartyTrackEvent('PON Creation Modal validated', {
                    failedCount: numRowsWithValidationErrors,
                    successCount: nextRows.length - numRowsWithValidationErrors,
                });
                setRows(nextRows);
                setLoading(false);
                setState('review');
            });
        }
    }

    function submitRows() {
        if (selectedSupplier) {
            const dtos: CreateDocumentRequestDto[] = rows.map(row => ({
                purchaseOrderNumber: row.purchaseOrderNumber,
                destinationZip: row.destinationZip,
                trackingNumber: row.trackingNumber,
                suggestedIntegrationId: row.suggestedIntegration?.id,
            }));

            setLoading(true);
            thirdPartyTrackEvent('PON Creation Modal submitted', { ponCount: dtos.length });
            props.apiClient.createDocumentRequest(selectedSupplier.id, dtos).then(() => {
                setLoading(false);
                toasts.add({
                    key: `po-numbers-added-${Date.now()}`,
                    color: 'green',
                    title: `${pluralize(dtos.length, 'PO Number')} Added`,
                    body: "We're trekking through all your integrations to find your documents. Please check back soon.",
                });
                props.onRequestClose(true);
            });
        }
    }

    function reset() {
        const nextRows: PonModalRow[] = [];
        addFinalBlank(nextRows);
        setRows(nextRows);
        setLoading(false);
        setState('initial');
        props.setResetConfirmationVisible(false);
    }

    function handlePaste(data: string, index: number): void {
        // We are assuming a valid line should have at least one digit.
        const lines = data
            .split('\n')
            .map(line => line.trim())
            .filter(line => line && /\d/.test(line));

        const normalizedIntegrationNames = carrierIntegrations.map(i => normalizeIntegrationName(i.name));

        const newRows: PonModalRow[] = lines.map(line => {
            const cells = line.split('\t').map(cell => cell.trim());
            let cellIndex = 0;
            const row: PonModalRow = { purchaseOrderNumber: cells[cellIndex++] || undefined };

            if (optionalCols.find(x => x.columnName === 'destinationZip')) {
                row.destinationZip = cells[cellIndex++] || undefined;
            }

            if (optionalCols.find(x => x.columnName === 'trackingNumber')) {
                row.trackingNumber = cells[cellIndex++] || undefined;
            }

            if (optionalCols.find(x => x.columnName === 'suggestedIntegration')) {
                const originalInput = cells[cellIndex++] || undefined;
                if (originalInput) {
                    const normalizedInput = normalizeIntegrationName(originalInput);
                    const index = normalizedIntegrationNames.findIndex(n => n.includes(normalizedInput));
                    if (index !== -1) {
                        row.suggestedIntegration = carrierIntegrations[index];
                    }
                }
            }

            return row;
        });

        addRows(rows, newRows, setRows, state, index, true);
    }

    return (
        <Modal visible={true} onRequestClose={props.handleRequestClose}>
            <div className="modal-close">
                <CloseButton onClick={props.handleRequestClose} />
            </div>

            <header className="modal-header">
                <h2 className="modal-title sp-h2">Add New Purchase Order Numbers</h2>
            </header>

            <div className="modal-body">
                {!props.multiSupplierView ? (
                    <h3 className="sp-h3">{state === 'review' ? 'Review' : props.supplier.name}</h3>
                ) : (
                    <div>
                        {selectedSupplier === null && (
                            <div className="mb-2">
                                <ContextNotification
                                    theme="red"
                                    icon={<spIcons.small.alert />}
                                    title={
                                        'Please make a selection from the "Select a Supplier" list before inputting PO numbers.'
                                    }
                                ></ContextNotification>
                            </div>
                        )}
                        <div className="flex justify-end">
                            <PopoverButton
                                theme="filter"
                                label={selectedSupplier?.label ?? 'Select a Supplier'}
                                disabled={state === 'review' || props.selectedSuppliers.length === 1}
                                anchor="right"
                                showPopover={showPopover}
                                onClick={() => setShowPopover(true)}
                                onRequestClose={() => setShowPopover(false)}
                            >
                                <SimpleSelect
                                    options={props.selectedSuppliers
                                        // Ensure we are only showing suppliers who have access to create document requests
                                        .filter(
                                            supplier =>
                                                supplier.canCreateDocumentRequests ||
                                                props.isAdmin ||
                                                props.isImpersonating
                                        )
                                        .map(supplier => ({
                                            id: supplier.id,
                                            label: truncate(supplier.name, MAX_LABEL_LENGTH),
                                            value: supplier,
                                        }))}
                                    value={selectedSupplier}
                                    onChange={value => {
                                        setSelectedSupplier(value);
                                        setShowPopover(false);
                                    }}
                                />
                            </PopoverButton>
                        </div>
                    </div>
                )}
                {state === 'initial' && optionalCols.find(x => x.columnName === 'destinationZip') && (
                    <div className="sp-caption mt-2">
                        * Destination ZIP code is optional for your integrations. If you ship direct to store, including
                        ZIP code will help us find the appropriate documents.
                    </div>
                )}

                <AddNewModalValidationMessage
                    state={state}
                    rowsWithValidationErrors={rowsWithValidationErrors}
                    numRows={rows.length}
                    dataType={'PO numbers'}
                />

                <div className="h-4" />

                <ModalInputTable<PonModalRow>
                    carrierIntegrations={carrierIntegrations}
                    requiredColumns={requiredColumns}
                    optionalColumns={optionalCols}
                    rows={rows}
                    rowsWithValidationErrors={rowsWithValidationErrors}
                    setRow={(row, index) => setRow(rows, row, index, setRows, state, true)}
                    addRows={(newRows, index) => addRows(rows, newRows, setRows, state, index, true)}
                    deleteRow={index => deleteRow(rows, index, setRows, state, reset, true)}
                    loading={loading}
                    disableInput={selectedSupplier === null}
                    review={state === 'review'}
                    handlePaste={handlePaste}
                />
            </div>

            <ModalInputTableFooter
                state={state}
                hasData={hasData}
                rowsWithValidationErrors={rowsWithValidationErrors}
                loading={loading}
                disableInputButtons={selectedSupplier === null}
                validateRows={validateRows}
                submitRows={submitRows}
                handleRequestClose={props.handleRequestClose}
                setResetConfirmationVisible={props.setResetConfirmationVisible}
            />

            <ModalInputTableConfirmationPopup
                message="Are you sure you want to cancel adding PO numbers?"
                event="PON Creation Modal reset"
                closeConfirmationVisible={props.closeConfirmationVisible}
                resetConfirmationVisible={props.resetConfirmationVisible}
                setCloseConfirmationVisible={props.setCloseConfirmationVisible}
                setResetConfirmationVisible={props.setResetConfirmationVisible}
                onRequestClose={props.onRequestClose}
                reset={reset}
            />
        </Modal>
    );
}
