import { SpButton, SpMultiSupplierSelect } from '@casestack/supplypike-ui';
import { useState, useRef } from 'react';
import { Supplier } from '../../types';
import { isNotNullish } from '../../util/filter-utils';
import { useOutsideClick } from '../hooks/use-outside-click';
import { capitalizeFirstCharacter } from '../../util/string-utils';

type Props = {
    retailers: { name: string; isDisabled: boolean }[];
    supportedSuppliers: Supplier[];
    selectedSuppliers: Supplier[];
    selectedSuppliersChanged?: (suppliers: Supplier[]) => void;
};

/**
 * The SpMultiSupplierSelect component accepts the normal Supplier type, however we need to:
 * - Capitalize the label field.
 * - Replace the 'retailer' field with the retailer's display name.
 * */
function supplierForMultiSupplierSelector(supplier: Supplier) {
    return {
        ...supplier,
        retailer: supplier.retailerName,
        label: capitalizeFirstCharacter(supplier.name),
    };
}

export const MultiSupplierSelect = (props: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useOutsideClick(ref, () => {
        setIsOpen(false);
    });

    const selectedSuppliersChanged = (sups: Pick<Supplier, 'id'>[]) => {
        setIsOpen(false);
        const suppliers: Supplier[] = sups
            .map(supplier => {
                return props.supportedSuppliers.find(s => s.id === supplier.id);
            })
            .filter(isNotNullish);
        if (props.selectedSuppliersChanged) {
            props.selectedSuppliersChanged(suppliers);
        }
    };

    const label =
        props.selectedSuppliers.length > 0
            ? `${props.selectedSuppliers.length} Supplier${props.selectedSuppliers.length !== 1 ? 's' : ''} Selected`
            : 'Select Suppliers';

    return (
        <div className="mt-2 ml-2" ref={ref}>
            <div>
                <SpButton
                    theme="sp-dropdown-button"
                    labelClassName="sp-body"
                    label={label}
                    onClick={() => setIsOpen(isOpen => !isOpen)}
                />
            </div>
            {isOpen && (
                <div className="bg-[#FFF] rounded-lg absolute z-40 border border-neutral-200 shadow-16dp-modal">
                    <SpMultiSupplierSelect
                        retailers={props.retailers}
                        suppliers={props.supportedSuppliers.map(x => supplierForMultiSupplierSelector(x))}
                        selectedSuppliers={props.selectedSuppliers.map(x => supplierForMultiSupplierSelector(x))}
                        selectedSuppliersChanged={selectedSuppliersChanged}
                    />
                </div>
            )}
        </div>
    );
};
