import { ReactElement } from 'react';
import '../demo.css';

export default function ArrowIcon(): ReactElement {
    return (
        <div className="arrow-sideways">
            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9475 1.65625L15.9475 6.65625L10.9475 11.6563" stroke="#506F89" strokeWidth="2" />
                <path d="M5.94751 1.65625L10.9475 6.65625L5.94751 11.6563" stroke="#506F89" strokeWidth="2" />
                <path d="M0.94751 1.65625L5.94751 6.65625L0.94751 11.6563" stroke="#506F89" strokeWidth="2" />
            </svg>
        </div>
    );
}
