import { useState, useEffect } from 'react';

export default function useDebounce<T>(value: T, delayInMs: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delayInMs);
        return () => clearTimeout(handler);
    }, [value, delayInMs]);

    return debouncedValue;
}
