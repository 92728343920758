import { ReactElement, useState } from 'react';
import { ApiClient } from '../../../../api/types';
import { Supplier } from '../../../../types';
import AddAttributesModal from '../add-attributes-modal';
import CreatePurchaseOrderNumberModal from '../create-purchase-order-number-modal';
import { AddNewModalProps } from './tabs';
import { AddNewModalTabName } from './utils';

interface OwnProps {
    apiClient: ApiClient;
    onRequestClose: (refresh?: boolean) => void;
    supplier: Supplier;
    suppliers: Supplier[];
    selectedSuppliers: Supplier[];
    isAdmin: boolean | undefined;
    selectedModal?: AddNewModalTabName;
    multiSupplierView?: boolean;
    isImpersonating?: boolean;
}

export default function AddNewModal(props: OwnProps): ReactElement {
    const [closeConfirmationVisible, setCloseConfirmationVisible] = useState<boolean>(false);
    const [resetConfirmationVisible, setResetConfirmationVisible] = useState<boolean>(false);
    const [hasData, setHasData] = useState<boolean>(false);

    function setCloseConfirmation(close: boolean) {
        setCloseConfirmationVisible(close);
    }

    function setResetConfirmation(reset: boolean) {
        setResetConfirmationVisible(reset);
    }

    const modalProps: AddNewModalProps = {
        apiClient: props.apiClient,
        onRequestClose: props.onRequestClose,
        handleRequestClose: handleRequestClose,
        supplier: props.supplier,
        suppliers: props.suppliers,
        selectedSuppliers: props.selectedSuppliers,
        closeConfirmationVisible: closeConfirmationVisible,
        resetConfirmationVisible: resetConfirmationVisible,
        setCloseConfirmationVisible: setCloseConfirmation,
        setResetConfirmationVisible: setResetConfirmation,
        hasDataCallback: setHasData,
        multiSupplierView: props.multiSupplierView,
        isAdmin: props.isAdmin,
        isImpersonating: props.isImpersonating,
    };

    function handleRequestClose() {
        if (closeConfirmationVisible || resetConfirmationVisible) {
            return;
        } else if (hasData) {
            setCloseConfirmationVisible(true);
        } else {
            props.onRequestClose();
        }
    }

    return (
        <>
            {props.selectedModal === 'attributes'
                ? AddAttributesModal(modalProps)
                : CreatePurchaseOrderNumberModal(modalProps)}
        </>
    );
}
