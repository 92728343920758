import { CoreEnvironmentKey } from '@casestack/core-authentication';
import { getCoreEnvironment } from '../env/core-environment';

const STAGE_CLIENT_ID = 'RmtvsXGKoWwOBiBfVueCcvV8rffWpBG3';
const PROD_CLIENT_ID = 'Wf9tSXl0x2BdX922jHtBcrXRRuOyhSDO';

export function getAuthConfig() {
    const url = new URL(window.location.href);
    const coreEnvironment = getCoreEnvironment();

    const clientId = (() => {
        switch (coreEnvironment) {
            case CoreEnvironmentKey.localv2:
            case CoreEnvironmentKey.staging:
            case CoreEnvironmentKey.stagingv2:
                return STAGE_CLIENT_ID;
            case CoreEnvironmentKey.production:
                return PROD_CLIENT_ID;
            default:
                throw new Error(`Invalid hostname: ${url.hostname}`);
        }
    })();

    return {
        product: 'documentExplorer',
        baseUrl: url.origin,
        callbackLocation: 'callback',
        environment: coreEnvironment,
        clientId,
    } as const;
}
