import { SpButton, spIcons } from '@casestack/supplypike-ui';
import { ReactElement } from 'react';

export interface OwnProps {
    theme: string;
    icon?: React.ReactNode;
    title?: string;
    actionLabel?: string;
    onClose?(): void;
    onActionClick?(): void;
    children?: React.ReactNode;
}

const colorsForSubtitle = ['green', 'red'];

export default function ContextNotification(props: OwnProps): ReactElement {
    const subtitleColor = colorsForSubtitle.includes(props.theme) ? `text-${props.theme}-900` : '';

    return (
        <div className={`sp-context-notification ${props.theme}`}>
            <div className="sp-context-notification-icon-wrapper">{props.icon || <spIcons.normal.infoCircle />}</div>
            <div className="sp-context-notification-inner-wrapper">
                <div className="sp-context-notification-content-wrapper">
                    <div className="flex flex-col">
                        <p className={`sp-subtitle ${subtitleColor}`}>{props.title}</p>
                        {props.children && <p className="sp-body">{props.children}</p>}
                    </div>
                    {props.actionLabel && (
                        <div className="flex flex-row">
                            <SpButton
                                label={props.actionLabel}
                                theme="outline-button"
                                colorTheme="neutral"
                                onClick={() => props.onActionClick?.()}
                            />
                        </div>
                    )}
                </div>
                {props.onClose && (
                    <spIcons.normal.x className="sp-context-notification-close" onClick={() => props.onClose?.()} />
                )}
            </div>
        </div>
    );
}
