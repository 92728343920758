import { DocumentRequestPendingSubstatus, DocumentRequestStatus, FileKind } from '../../api/types';
import { Integration } from '../../types';
import { capitalizeFirstCharacter } from '../../util/string-utils';
import { dateRangeToString } from '../common/date-inputs/date-range-input';

export function getIntegrationFilterLabel(numIntegrationOptions: number, integrations?: Integration[]): string {
    if (numIntegrationOptions === 0) {
        return 'No Sources';
    }
    if (integrations === undefined) {
        return 'Source';
    }
    switch (integrations.length) {
        case 0: {
            throw new Error('No sources selected');
        }
        case 1: {
            return integrations[0].name;
        }
        default: {
            return `${integrations.length} Sources`;
        }
    }
}

export function getDateFilterLabel(start?: Date, end?: Date, label?: string): string {
    if (!start && !end) {
        return label ?? 'Date';
    } else {
        return dateRangeToString(start, end);
    }
}

export function getStatusFilterLabel(statuses?: (DocumentRequestStatus | DocumentRequestPendingSubstatus)[]): string {
    if (statuses === undefined) {
        return 'Status';
    }
    switch (statuses.length) {
        case 0: {
            throw new Error('No statuses selected');
        }
        case 1: {
            return capitalizeFirstCharacter(statuses[0]);
        }
        default: {
            return `${statuses.length} Statuses`;
        }
    }
}

export function getFileKindsFilterLabel(kinds?: FileKind[]): string {
    if (kinds === undefined) {
        return 'Document Type';
    }
    switch (kinds.length) {
        case 0: {
            throw new Error('No document types selected');
        }
        case 1: {
            return kinds[0].toUpperCase();
        }
        default: {
            return `${kinds.length} Types`;
        }
    }
}
