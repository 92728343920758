import React from 'react';
import { createRoot } from 'react-dom/client';
import './de-styles.css';
import './index.css';
import App from './app';
import reportWebVitals from './report-web-vitals';
import { initLogger, initTracker } from './third-party';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initLogger();
initTracker();
