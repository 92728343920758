import { spIcons } from '@casestack/supplypike-ui';
import { ReactElement } from 'react';

export type ToastColor = 'neutral' | 'red' | 'green' | 'blue';

interface OwnProps {
    color?: ToastColor;
    title: string;
    body: string;
    onRequestClose: () => void;
    className?: string;
}

export default function Toast(props: OwnProps): ReactElement {
    return (
        <div className={`sp-toast items-center ${props.color || 'neutral'} ${props.className || ''}`}>
            <spIcons.normal.infoCircle className="sp-toast-icon" />
            <div className="sp-toast-content-wrapper flex-grow">
                <div>
                    <div className="sp-subtitle">{props.title}</div>
                    <p className="opacity-85 sp-body">{props.body}</p>
                </div>
            </div>
            <button type="button" className="sp-button icon" onClick={() => props.onRequestClose()}>
                <spIcons.normal.x />
            </button>
        </div>
    );
}
