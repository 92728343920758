import { CoreEnvironmentKey } from '@casestack/core-authentication';

import CONFIG from '../config';
import { Environment, getEnvironment } from './environment';

export const getCoreEnvironment = (): CoreEnvironmentKey => {
    const env = getEnvironment();
    switch (env) {
        case Environment.LOCAL:
            if (CONFIG.apiBaseUrl === 'fake') {
                return CoreEnvironmentKey.localv2;
            } else {
                return CoreEnvironmentKey.stagingv2;
            }
        case Environment.STAGING:
            return CoreEnvironmentKey.stagingv2;
        case Environment.PRODUCTION:
            return CoreEnvironmentKey.production;
    }
};
