import { Environment, getEnvironment } from '../env/environment';
import { Supplier, User } from '../types';
import { initSegmentInternal } from './segment-internal';

interface Identity {
    /** Core user id */
    id: string;
    /** Core organization id */
    company_id: string;
    /** Core organization name */
    company: string;
    email: string;
    name: string;
    supplier_id?: string;
    supplier?: string;
    isDocumentExplorerUser: true;
}

export class Segment {
    static userIdentity: Identity;

    /**
     * Instead of actually calling this, we put a snippet into `index.html`.
     * So, we can't easily disable Segment fully.
     */
    static initialize(): void {
        initSegmentInternal(Segment.getWriteKey());
    }

    // TODO: move this somewhere else, into config maybe?
    static getWriteKey(): string {
        switch (getEnvironment()) {
            case Environment.LOCAL: {
                return 'zD8XCMbqtBJetdUtBczXpoIj691wXZMB';
            }
            case Environment.STAGING: {
                return 'zjqCRKsIZDtxhgoHmfR985GAyYJDEGqH';
            }
            case Environment.PRODUCTION: {
                return 'kX4cmnfoNZFq5kswfHEi9YgznQpY0Vx7';
            }
        }
    }

    static identify(user: User, supplier?: Supplier): void {
        const identity: Identity = {
            id: user.id,
            company_id: user.organizationId,
            company: '',
            email: user.email,
            name: user.name,
            isDocumentExplorerUser: true,
            ...(supplier ? { supplier_id: supplier.id, supplier: supplier.name } : {}),
        };
        this.userIdentity = identity;

        /** Everything in `identity` except `id` */
        const traits: Record<string, unknown> = {
            company_id: identity.company_id,
            company: identity.company,
            email: identity.email,
            name: identity.name,
            supplier_id: identity.supplier_id,
            supplier: identity.supplier,
            isDocumentExplorerUser: true,
        };
        analytics.identify(identity.id, traits, {
            Intercom: {
                hideDefaultLauncher: false,
                user_hash: user.intercom.userHash,
            },
            All: !user.impersonation.isImpersonating,
        } as object);
    }

    static group(user: User, supplier?: Supplier): void {
        analytics.group(user.organizationId, {
            name: '',
            isDocumentExplorer: true,
            ...(supplier ? { supplier_id: supplier.id, supplier_name: supplier.name } : {}),
        });
    }

    static trackPage(properties?: Record<string, unknown>): void {
        analytics.page(properties);
    }

    static trackEvent(eventName: string, properties?: Record<string, unknown>): void {
        const props = properties ?? {};
        Object.assign(props, { identity: this.userIdentity });
        analytics.track(eventName, props);
    }

    static reset(): void {
        analytics.reset();
    }
}
