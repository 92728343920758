import { createContext, ReactNode, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const UrlParamsContext = createContext<{
    searchParams: URLSearchParams;
    updateSearchParam: (key: string, value: string | string[]) => void;
    deleteSearchParam: (key: string) => void;
}>({
    searchParams: new URLSearchParams(),
    updateSearchParam: (_key, _value) => undefined,
    deleteSearchParam: _key => undefined,
});
UrlParamsContext.displayName = 'UrlParamsContext';

export default function UrlParams(props: { children: ReactNode }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const updateSearchParam = useCallback(
        (key: string, value: string | string[]) => {
            const previous = Array.isArray(value) ? searchParams.getAll(key) : searchParams.get(key);

            if (previous === value) {
                return;
            }

            setSearchParams(params => {
                params.delete(key);
                if (Array.isArray(value)) {
                    for (const val of value) {
                        params.append(key, val);
                    }
                } else {
                    params.set(key, value);
                }

                return params;
            });
        },
        [searchParams, setSearchParams]
    );

    const deleteSearchParam = useCallback(
        (key: string) => {
            setSearchParams(params => {
                params.delete(key);
                return params;
            });
        },
        [setSearchParams]
    );

    return (
        <UrlParamsContext.Provider value={{ searchParams, updateSearchParam, deleteSearchParam }}>
            {props.children}
        </UrlParamsContext.Provider>
    );
}
