import { SpButton, spIcons } from '@casestack/supplypike-ui';
import { spColors } from '@casestack/style-themes';
import { ReactElement, useState, useRef } from 'react';
import { downloadCsv } from '../../util/download-csv';
import Modal from './modal';
import { thirdPartyTrackEvent } from '../../third-party';
import { TooltipWrapper } from './tooltip-wrapper';
import { Supplier } from '../../types';
import { getSupplierAndRetailerEventData } from '../../third-party/segment-utils';

interface OwnProps<T extends Record<string, unknown>> {
    fileName: string;
    getData: (signal: AbortSignal) => Promise<T[]>;
    columnWhiteList?: (keyof T)[];
    columnBlackList?: (keyof T)[];
    downloadTransform?: (x: T) => Record<string, unknown>;
    disabled: boolean;
    selectedSuppliers?: Supplier[];
    suppliers?: Supplier[];
}

/** limit maximum query size so the query takes ~30s for standard customers */
export const MAX_DOWNLOADABLE_RECORDS = 25000;
const CANNOT_DOWNLOAD_MESSAGE = `A maxiumum of ${MAX_DOWNLOADABLE_RECORDS} records can be downloaded at once. Please use the filters to narrow the search before downloading.`;

export default function DownloadCsvButton<T extends Record<string, unknown>>(props: OwnProps<T>): ReactElement {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const abortControllerRef = useRef(new AbortController());

    return (
        <>
            <Modal
                visible={modalOpen}
                onRequestClose={() => {
                    abortControllerRef.current.abort();
                    setModalOpen(false);
                }}
                widthClass="narrow"
            >
                <div style={{ display: 'flex', flexFlow: 'horizontal nowrap', height: '100px' }}>
                    <div
                        style={{
                            background: spColors.all.blues.blue700.replace(', 1)', ', 0.1)'),
                            width: '100px',
                            display: 'flex',
                        }}
                    >
                        <div className="sp-loading-spinner large" style={{ margin: 'auto' }} />
                    </div>
                    <p className="sp-body" style={{ margin: 'auto 0 auto 16px' }}>
                        Exporting data...
                    </p>
                </div>
            </Modal>

            <TooltipWrapper content={props.disabled && CANNOT_DOWNLOAD_MESSAGE} anchor="left">
                <SpButton
                    {...props}
                    label={'Download CSV'}
                    disabled={props.disabled}
                    iconRight={spIcons.normal.download}
                    onClick={async () => {
                        try {
                            abortControllerRef.current.abort();
                            abortControllerRef.current = new AbortController();
                            setModalOpen(true);
                            const data = await props.getData(abortControllerRef.current.signal);
                            const supplierAndRetailerEventData = getSupplierAndRetailerEventData(
                                props.selectedSuppliers ?? [],
                                props.suppliers ?? []
                            );
                            thirdPartyTrackEvent('Exported table data', {
                                rowCount: data.length,
                                ...(props.selectedSuppliers && supplierAndRetailerEventData),
                            });
                            downloadCsv(
                                props.fileName,
                                data,
                                props.columnWhiteList,
                                props.columnBlackList,
                                props.downloadTransform
                            );
                        } catch (e) {
                            // typescript passes back this error as an unknown
                            if ((e as Error).name !== 'AbortError') {
                                throw e;
                            }
                        }
                        setModalOpen(false);
                    }}
                ></SpButton>
            </TooltipWrapper>
        </>
    );
}
