import { FC } from 'react';
import { DocumentRequest } from '../../../types';
import { FileKind } from '../../../api/types';

export const countFileKinds = (data: DocumentRequest) => {
    return data.files.reduce((results: Partial<Record<FileKind, number>>, file) => {
        results[file.kind] = (results[file.kind] ?? 0) + 1;
        return results;
    }, {});
};

const DocumentTypesCell: FC<{ data: DocumentRequest; targetKind?: FileKind }> = ({ data, targetKind }) => {
    const formatKind = (kind: string) => {
        switch (kind) {
            case 'bol':
                return 'BOL';
            case 'pod':
                return 'POD';
            case 'pending':
                return 'Pending';
            default:
                return 'Other';
        }
    };

    const kinds = countFileKinds(data);

    // typescript cannot determine that our record of filekind: number would have keys of filekind
    const uniqueKinds = targetKind ? [targetKind] : (Object.keys(kinds) as FileKind[]);
    return (
        <div className="flex flex-col items-start">
            {uniqueKinds.map((k, i) => (
                <div
                    key={k}
                    className={`sp-category-tag neutral sp-subtitle pointer-events-none ${i > 0 ? 'mt-2' : ''} ${
                        (kinds[k] ?? 0) === 0 ? 'yellow' : ''
                    }`}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {targetKind && targetKind !== 'pending' ? (kinds[k] ?? 0) + ' ' : ''}
                    {formatKind(k)}
                    {targetKind && targetKind !== 'pending' && kinds[k] !== 1 ? 's' : ''}
                </div>
            ))}
        </div>
    );
};

export default DocumentTypesCell;
