import { FC } from 'react';

import { DocumentRequest } from '../../../types';

const PoInfoCell: FC<{ data: DocumentRequest }> = ({ data }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
            <p>PO# {data.purchaseOrderNumber ?? '--'}</p>
            <p>Invoice {data.invoiceNumber ?? '--'}</p>
            <p>ZIP {data.destinationZip ?? '--'}</p>
        </div>
    );
};

export default PoInfoCell;
