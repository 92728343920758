import { ReactElement, useState } from 'react';
import { spColors } from '@casestack/style-themes';
import { Supplier } from '../../types';
import PopoverButton from '../common/popover-button';
import SimpleSelect from '../common/simple-select';
import Navbar from './navbar';
import { truncate } from '../../util/string-utils';
import { Environment, getEnvironment } from '../../env/environment';

/** Mostly arbitrary. */
const MAX_LABEL_LENGTH = 32;

interface OwnProps {
    suppliers: Supplier[] | null;
    supplier: Supplier | null;
    setSupplier: (supplier: Supplier | null) => void;
    retailerName: string;
    isAdmin?: boolean;
    /** Temporary boolean flag for while we are supporting both single and multi supplier views */
    onMultiSupplierPage?: boolean;
}

export default function Header(props: OwnProps): ReactElement {
    const [showPopover, setShowPopover] = useState(false);

    const isLoading = !props.suppliers;
    const hasSuppliers = props.suppliers && props.suppliers.length !== 0;
    const env = getEnvironment();

    // For now, only display the Navbar in local and stage environments
    const navbarComponent = <>{env !== Environment.PRODUCTION && props.isAdmin && <Navbar />}</>;

    return (
        <header>
            <div className="flex items-center py-4">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '12px',
                        flex: '1',
                    }}
                >
                    <p className="sp-h3">{!props.onMultiSupplierPage ? props.retailerName : 'Home'}</p>
                    <p
                        className="sp-h3"
                        style={{
                            borderLeft: `1px solid ${spColors.all.neutral300}`,
                            paddingLeft: '12px',
                        }}
                    >
                        Documents
                    </p>
                </div>
                <div className="hidden md:flex">{navbarComponent}</div>
                {/* Dont show the single supplier switcher if we are on a multi supplier page */}
                {!props.onMultiSupplierPage ? (
                    <div className="flex flex-1 justify-end">
                        <PopoverButton
                            theme="filter"
                            label={
                                props.supplier
                                    ? truncate(props.supplier.name, MAX_LABEL_LENGTH)
                                    : isLoading
                                    ? 'Loading Suppliers...'
                                    : hasSuppliers
                                    ? 'No Supplier Selected'
                                    : 'No Suppliers Available'
                            }
                            disabled={!hasSuppliers}
                            anchor="right"
                            showPopover={showPopover}
                            onClick={() => setShowPopover(true)}
                            onRequestClose={() => setShowPopover(false)}
                            className="flex flex-col"
                        >
                            <SimpleSelect
                                options={(props.suppliers || []).map(supplier => ({
                                    id: supplier.id,
                                    label: truncate(supplier.name, MAX_LABEL_LENGTH),
                                    value: supplier,
                                }))}
                                value={props.supplier}
                                onChange={value => {
                                    props.setSupplier(value);
                                    setShowPopover(false);
                                }}
                            />
                        </PopoverButton>
                    </div>
                ) : (
                    // We need a third element in this div in order to have the tabs centered on the page correctly.
                    // If we are on a multi supplier page, create an empty element with the same flex values as the first element.
                    // See https://stackoverflow.com/questions/28700805/aligning-elements-left-and-center-with-flexbox for more details.
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                        }}
                    />
                )}
            </div>
            {/* Navbar for smaller screens, wraps under the rest of the header components */}
            <div className="flex justify-center md:hidden">{navbarComponent}</div>
            <hr className="border-neutral-300" />
        </header>
    );
}
