import { ModalTableColumn } from '../../../common/modal-input-table/types';
import { AddAttributesRow } from './types';

export const requiredColumns = [
    { columnName: 'purchaseOrderNumber', title: 'PO Number', size: 'medium', inputType: 'text', maxLength: 20 },
] as ModalTableColumn<AddAttributesRow>[];

export const optionalColumns = [
    { columnName: 'destinationZip', title: 'Dest. Zip', size: 'small', inputType: 'text', maxLength: 5 },
    { columnName: 'invoiceNumber', title: 'Invoice Number', size: 'medium', inputType: 'text' },
    { columnName: 'referenceNumbers', title: 'Reference Numbers', size: 'medium', inputType: 'text' },
    { columnName: 'proNumbers', title: 'Pro Numbers', size: 'medium', inputType: 'text' },
    { columnName: 'suggestedProviders', title: 'Suggested Carriers', size: 'medium', inputType: 'text' },
    { columnName: 'trackingNumbers', title: 'Tracking Numbers', size: 'medium', inputType: 'text' },
    { columnName: 'invoiceDate', title: 'Invoice Date', size: 'medium', inputType: 'text' },
] as ModalTableColumn<AddAttributesRow>[];
