import { SpButton } from '@casestack/supplypike-ui';
import { ReactElement, ReactNode, useState } from 'react';
import Popover, { PopoverAnchor } from './popover';
import { spColors } from '@casestack/style-themes';
import { SvgProps } from '@casestack/supplypike-ui/build/lib/util/svg';

/** Full control is passed upwards through optional props. */
interface OwnProps {
    theme: 'filter' | 'text' | 'tabs';
    label: ReactNode;
    active?: boolean; // bearing an effect
    disabled?: boolean;
    showPopover?: boolean;
    onClick?: () => void;
    onRequestClose?: () => void;
    className?: string;
    children: ReactNode;
    anchor?: PopoverAnchor;
    icon?: React.FC<SvgProps>;
}

export default function PopoverButton(props: OwnProps): ReactElement {
    const [showPopover, setShowPopover] = useState(false);

    function handleClick() {
        if (props.onClick) {
            props.onClick();
        } else {
            setShowPopover(true);
        }
    }

    function handleRequestClose() {
        if (props.onRequestClose) {
            props.onRequestClose();
        } else {
            setShowPopover(false);
        }
    }

    switch (props.theme) {
        case 'tabs': {
            return (
                <div className={props.className}>
                    <SpButton
                        label={<span className="flex items-center text-base">{props.label}</span>}
                        iconRight={props.icon}
                        onClick={handleClick}
                        disabled={props.disabled}
                    ></SpButton>
                    <Popover
                        visible={props.showPopover ?? showPopover}
                        onRequestClose={handleRequestClose}
                        anchor={props.anchor}
                    >
                        {props.children}
                    </Popover>
                </div>
            );
        }
        case 'text': {
            return (
                <div className={props.className}>
                    <SpButton
                        label={<span className="flex items-center">{props.label}</span>}
                        theme="text"
                        colorTheme="neutral"
                        onClick={handleClick}
                        disabled={props.disabled}
                    ></SpButton>
                    <Popover
                        visible={props.showPopover ?? showPopover}
                        onRequestClose={handleRequestClose}
                        anchor={props.anchor}
                    >
                        {props.children}
                    </Popover>
                </div>
            );
        }
        case 'filter':
        default: {
            let innerClassName = 'sp-base-button outline-button neutral';

            if (props.active) {
                innerClassName += ' blue bg-blue-100';
            }
            const style = props.active ? { color: spColors.all.blue700 } : {};
            return (
                <div className={props.className}>
                    <button
                        type="button"
                        className={innerClassName}
                        style={style}
                        onClick={handleClick}
                        disabled={props.disabled}
                    >
                        {props.label}
                    </button>
                    <Popover
                        visible={props.showPopover ?? showPopover}
                        onRequestClose={handleRequestClose}
                        anchor={props.anchor}
                    >
                        {props.children}
                    </Popover>
                </div>
            );
        }
    }
}
