import { ReactElement } from 'react';
import './demo.css';
import DemoInput from './demo-input';

interface OwnProps {
    values: string[];
    symbol?: string;
    icon?: ReactElement;
}

export default function DemoInputArray(props: OwnProps): ReactElement {
    return (
        <>
            {props.values.map((value, i) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                        key={i}
                    >
                        <DemoInput value={value} className="demo-input-box" key={i} symbol={props.symbol} />{' '}
                        {props.icon}
                    </div>
                );
            })}
        </>
    );
}
