import { Supplier } from '../types';

export function getSupplierAndRetailerEventData(selectedSuppliers: Supplier[], availableSuppliers: Supplier[]) {
    return {
        retailersSelected: Array.from(new Set(selectedSuppliers.map(x => x.retailerName))),
        retailersAvailable: Array.from(new Set(availableSuppliers.map(x => x.retailerName))),
        numSuppliersSelected: selectedSuppliers.length,
        numSuppliersAvailable: availableSuppliers.length,
    };
}
