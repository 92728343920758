import { ReactElement } from 'react';

export default function NotFoundPage(): ReactElement {
    return (
        <>
            <h1 className="page-title flex justify-center">Error 404: That page could not be found</h1>
            <a className="flex justify-center mt-4" href="/">
                Go back to the main page of Document Explorer
            </a>
        </>
    );
}
