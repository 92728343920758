import { ReactElement } from 'react';

export default function InvoiceIcon(): ReactElement {
    return (
        <>
            <svg width="47" height="47" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25.3225" cy="25" r="24" stroke="#D6FCED" strokeWidth="2" />
                <g clipPath="url(#clip0_81_2437)">
                    <path
                        d="M36.5586 20.2728L26.0295 9.74365H25.1255H24.7903H14.8759V39.4866H36.5634V20.2746L36.5586 20.2728ZM26.0295 11.4961L34.1914 19.6579H26.0295V11.4961ZM16.1152 38.2473V10.9829H24.7903V20.8972H35.3242V38.2472H16.1152V38.2473Z"
                        fill="#D6FCED"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_81_2437">
                        <rect width="30.4687" height="30.4687" fill="white" transform="translate(10.4789 9.375)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}
