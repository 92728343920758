import { ErrorInfo } from 'react';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { CaptureContext } from '@sentry/types';

import { Environment, getEnvironment } from '../env/environment';
import { ThirdPartyContext } from './third-party-context';

// This is the same for every Sentry project, so it seems to be SupplyPike's customerId.
const SENTRY_CUSTOMER_ID = 'o145269';
// These are both found on the "getting started" page of the new Sentry Project.
const SENTRY_PROJECT_ID = '6159855';
const SENTRY_DSN_ID = '632b5bac15fb49aeb9e69a87b34734be';

const SENTRY_DSN = `https://${SENTRY_DSN_ID}@${SENTRY_CUSTOMER_ID}.ingest.sentry.io/${SENTRY_PROJECT_ID}`;

/** A rich error (with additional info) for sending to sentry. */
export class InternalError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(message: string, public extra?: Record<string, any>) {
        super(message);
    }
}

export class SentryLogger {
    /** Also implies that Sentry is enabled in this Environment */
    private static isInitialized: boolean;

    static initialize(): void {
        if (SentryLogger.isEnabled()) {
            Sentry.init({
                release: process.env.REACT_APP_RELEASE,
                dsn: SENTRY_DSN,
                /**
                 * "This enables automatic instrumentation (highly recommended),
                 * but is not necessary for purely manual usage."
                 * https://docs.sentry.io/platforms/javascript/performance/
                 */
                integrations: [new Integrations.BrowserTracing()],
                /**
                 * "set the sampleRate option in your SDK configuration to
                 * a number between 0 (0% of errors sent) and 1 (100% of errors sent)."
                 * https://docs.sentry.io/platforms/javascript/configuration/sampling/
                 */
                tracesSampleRate: 1.0,
                ignoreErrors: [
                    // Ignore errors caused by Outlook safe links
                    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
                    'Non-Error promise rejection captured with value: Object Not Found Matching Id',
                ],
            });
            SentryLogger.isInitialized = true;
        }
    }

    static setUserContext = (user: ThirdPartyContext): void => {
        if (SentryLogger.isInitialized) {
            Sentry.setUser(user);
        }
    };

    static captureException(exception: Error, captureContext?: CaptureContext | undefined): void {
        if (SentryLogger.isInitialized) {
            Sentry.captureException(exception, captureContext);
        }
    }

    static captureMessage(message: string, captureContext?: Sentry.Severity | CaptureContext | undefined): void {
        if (SentryLogger.isInitialized) {
            Sentry.captureMessage(message, captureContext);
        }
    }

    static reportError = (): void => {
        if (SentryLogger.isInitialized) {
            Sentry.showReportDialog();
        }
    };

    static captureReactException = (exception: Error, info: ErrorInfo): void => {
        if (SentryLogger.isInitialized) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const infoAsRecord = info as Record<string, any>;
            Sentry.setExtras(infoAsRecord);
            Sentry.captureException(exception);
        }
    };

    private static isEnabled(): boolean {
        return getEnvironment() === Environment.PRODUCTION;
    }
}
