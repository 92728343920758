import { Supplier } from '../types';

/**
 * Returns the suppliers that are passed as params in the URL.
 * If no suppliers are in the URL, decide which suppliers to return based on if the user is an admin or not.
 */
export function getSelectedSuppliers(suppliers: Supplier[], userIsAdmin: boolean) {
    // Get all supplier ids from the URL
    const supplierIdsFromUrlParams: string[] = new URL(window.location.href).searchParams.getAll('supplier');
    // Get the actual supplier objects from the supplier ids
    const suppliersFromUrlParams: Supplier[] | undefined = suppliers?.filter(x =>
        supplierIdsFromUrlParams.includes(x.id)
    );
    // If there are suppliers in the URL, use those
    if (suppliersFromUrlParams?.length) {
        return suppliersFromUrlParams;
    }

    const sups: Supplier[] = [];
    // If there are no suppliers in the URL and the user is an admin, we only want to select one supplier
    if (userIsAdmin && suppliers?.length) {
        sups.push(suppliers[0]);
    } else {
        // Otherwise if user is not an admin, select ALL suppliers
        sups.push(...(suppliers ?? []));
    }
    return sups;
}
