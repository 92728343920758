import { CSSProperties, ReactElement } from 'react';

import './placeholder.css';

/**
 * If this gets bogged down, switch to numbers only with precalc using Math.min().
 */
export function roundedStyle(width: number | string, height: number | string): CSSProperties {
    width = typeof width === 'number' ? width + 'px' : width;
    height = typeof height === 'number' ? height + 'px' : height;
    return { height, width, borderRadius: `calc(min(${width}, ${height}) / 2)` };
}

export default function Placeholder(props: { className?: string; style?: CSSProperties }): ReactElement {
    return <div className={['placeholder', props.className].filter(s => s).join(' ')} style={props.style} />;
}
