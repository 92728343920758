import { CellValue } from './types';

interface OwnProps<T extends Record<string, CellValue>> {
    state: string;
    hasData: boolean;
    rowsWithValidationErrors: Map<number, T>;
    loading?: boolean;
    disableInputButtons: boolean;
    validateRows: () => void;
    submitRows: () => void;
    handleRequestClose: () => void;
    setResetConfirmationVisible: (reset: boolean) => void;
}

export default function ModalInputTableFooter<T extends Record<string, CellValue>>(props: OwnProps<T>) {
    return (
        <footer className="modal-footer flex justify-end">
            {props.state === 'review' && (
                <div className="flex flex-grow">
                    <button
                        type="button"
                        className="sp-base-button neutral outline-button"
                        onClick={() => props.setResetConfirmationVisible(true)}
                        disabled={props.loading}
                    >
                        Start Over
                    </button>
                </div>
            )}

            <button type="button" className="sp-base-button neutral text" onClick={props.handleRequestClose}>
                Cancel
            </button>

            {(props.state === 'initial' || props.rowsWithValidationErrors.size > 0) && (
                <button
                    type="button"
                    className="ml-2 sp-base-button blue solid"
                    disabled={!props.hasData || props.loading || props.disableInputButtons}
                    onClick={props.validateRows}
                >
                    Validate
                </button>
            )}

            {props.state === 'review' && props.rowsWithValidationErrors.size === 0 && (
                <button
                    type="button"
                    className="ml-2 sp-base-button blue solid"
                    disabled={!props.hasData || props.loading || props.disableInputButtons}
                    onClick={props.submitRows}
                >
                    Submit
                </button>
            )}
        </footer>
    );
}
