import { ReactElement, useState, useMemo, useEffect, useContext } from 'react';
import SearchInput from './search-input';
import useDebounce from '../hooks/debounce';
import { AuthenticatedProps } from '../../auth/page-with-auth';
import { thirdPartyTrackEvent } from '../../third-party';
import { UrlParamsContext } from '../../contexts/url-params-context';
import Tippy from '@tippyjs/react';
import MultiSearchPrompt from './multi-search-prompt';
import MultiSearchModal from './multi-search-modal';
import { splitOnWhitespaceAndCommas } from '../../util/string-utils';
import { Supplier } from '../../types';
import { getSupplierAndRetailerEventData } from '../../third-party/segment-utils';

export interface SearchProps extends AuthenticatedProps {
    setSearchCallback: (search: string | undefined) => void;
    setSearchTooShortCallback: (arg: boolean) => void;
    setMultiSearchCallback?: (isMultiSearch: boolean) => void;
    /** Whether or not to allow multiSearch, which will add a popup modal to allow for larger inputs */
    enableMultiSearch?: boolean;
    selectedSuppliers?: Supplier[];
    label: string;
}

/** We don't search until the user types in this many characters into the search bar */
export const MIN_SEARCH_LENGTH = 3;

export const MAX_MULTI_SEARCH_VALUES = 10_000;

export default function Search(props: SearchProps): ReactElement {
    // Search is kept separate from other filters for debouncing purposes.
    // Note: `sanitizedSearch`, `debouncedSearch`, `validatedSearch` will have `null` as a placeholder
    //       until the actual `initialSearch` is loaded with useEffect hook(s).
    //       Therefore, any hooks which depend upon `validatedSearch` should ignore `null`s.
    //       `undefined` means that the value is loaded, but there is no search filter.
    const { searchParams, updateSearchParam, deleteSearchParam } = useContext(UrlParamsContext);
    const {
        supplier,
        setSearchTooShortCallback,
        setSearchCallback,
        setMultiSearchCallback,
        selectedSuppliers,
        suppliers,
    } = props;

    const [search, setSearch] = useState<string | null | undefined>(() => searchParams.get('search'));
    const [isMultiSearch, setIsMultiSearch] = useState<boolean>(false);
    const [isMultiSearchOpen, setIsMultiSearchOpen] = useState(false);

    const numberFormatter = Intl.NumberFormat('en-US');

    // Update sanitizedSearch from raw search
    const sanitizedSearch = useMemo(() => {
        // remove any leading or trailing whitespace
        const trimmed = search?.trim();
        // If the user types something and then deletes it, there will be an empty string in the search box.
        // replace the empty string with `undefined`, which means no filter.
        return trimmed?.length ? trimmed : undefined;
    }, [search]);
    const debouncedSearch = useDebounce(sanitizedSearch, 500);

    // Update filters from debouncedSearch
    useEffect(() => {
        // ignore placeholder `null` value, which should only exist
        // while we wait for some other useEffects to run after a refresh
        if (debouncedSearch !== null) {
            // allow searches for undefined (which means no search filter)
            // otherwise, only allow searches for 3 or more characters
            if (debouncedSearch === undefined || debouncedSearch.length >= MIN_SEARCH_LENGTH) {
                setSearchTooShortCallback(false);
                setSearchCallback(debouncedSearch);
                if (setMultiSearchCallback) {
                    setMultiSearchCallback(isMultiSearch);
                }
                const supplierAndRetailerEventData = getSupplierAndRetailerEventData(
                    selectedSuppliers ?? [],
                    suppliers ?? []
                );
                thirdPartyTrackEvent('Search set', {
                    type: isMultiSearch ? 'multiSearch' : 'single',
                    ...(selectedSuppliers && supplierAndRetailerEventData),
                });
                // We do not want the search values to be part of the URL if we are doing a multiSearch
                if (debouncedSearch === undefined || isMultiSearch) {
                    deleteSearchParam('search');
                } else {
                    updateSearchParam('search', debouncedSearch);
                }
            } else if (debouncedSearch.length < MIN_SEARCH_LENGTH) {
                setSearchTooShortCallback(true);
            }
        }
    }, [
        debouncedSearch,
        isMultiSearch,
        deleteSearchParam,
        updateSearchParam,
        setSearchTooShortCallback,
        setSearchCallback,
        setMultiSearchCallback,
        selectedSuppliers,
        suppliers,
    ]);

    return (
        <>
            {isMultiSearchOpen && (
                <MultiSearchModal
                    title="Search Multiple POs or Invoices"
                    label={`Search up to ${numberFormatter.format(
                        MAX_MULTI_SEARCH_VALUES
                    )} full PO numbers or invoice numbers separated by commas, spaces, or new lines.`}
                    maxSearchValues={MAX_MULTI_SEARCH_VALUES}
                    initialValue={search ?? ''}
                    onCancel={() => setIsMultiSearchOpen(false)}
                    onSearch={input => {
                        setIsMultiSearchOpen(false);
                        deleteSearchParam('pageIndex');
                        setIsMultiSearch(true);
                        setSearch(input);
                    }}
                />
            )}
            {/* 
                Tippy is used for creating the 'Multiple Search...' popup when clicking on the search input.
                If the 'enableMultiSearch' prop is false or undefined, Tippy will be 'disabled' and we will 
                default to just the basic search input (no popup).
            */}
            <Tippy
                placement="bottom-start"
                offset={[0, 4]}
                disabled={props.enableMultiSearch ? isMultiSearchOpen : true}
                interactive
                trigger="focusin"
                maxWidth={394}
                duration={[0, 0]}
                content={<MultiSearchPrompt onClick={() => setIsMultiSearchOpen(true)} />}
            >
                <div className="flex items-center mt-2 flex-wrap">
                    <div style={{ width: 244 }}>
                        <SearchInput
                            placeholder={props.label}
                            value={
                                isMultiSearch
                                    ? `Multiple (${numberFormatter.format(
                                          Math.min(
                                              splitOnWhitespaceAndCommas(search ?? '').length,
                                              MAX_MULTI_SEARCH_VALUES
                                          )
                                      )})`
                                    : search ?? ''
                            }
                            onChange={val => {
                                deleteSearchParam('pageIndex');
                                setIsMultiSearch(false);
                                setSearch(val);
                            }}
                            disabled={!supplier}
                            isMultiSearch={isMultiSearch}
                            onFocus={e => {
                                // If we are currently doing a multiSearch, re open the modal if the input is clicked again
                                if (isMultiSearch) {
                                    e.preventDefault();
                                    e.currentTarget.blur();
                                    setIsMultiSearchOpen(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </Tippy>
        </>
    );
}
