interface Config {
    apiBaseUrl: string;
}

const CONFIG: Config = {
    // If set to 'fake', then use the fake one.
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '/api',
};

export default CONFIG;
