import { ReactElement, useEffect, useState, useRef, useContext, useCallback } from 'react';
import { spIcons } from '@casestack/supplypike-ui';

import DocumentRequestModal from '../../common/purchase-order-number-modal';
import { PageTableColumn } from '../../common/page-table';
import Placeholder, { roundedStyle } from '../../common/placeholder';
import { DocumentRequestStatus } from '../../../api/types';
import { AuthenticatedProps } from '../../../auth/page-with-auth';
import { DocumentRequest, Page } from '../../../types';
import { thirdPartyTrackEvent } from '../../../third-party';
import { documentRequestsTableColumns, trackerTypeFilters } from './columns';
import TableRootPage, { RetrievePageParams } from '../../common/table';
import { splitRequestsByDocumentType, filterOutStatuses } from './transforms';
import DocumentFiltersComponent, { DocumentFilters } from './filters';
import Search from '../../common/search';
import { UrlParamsContext } from '../../../contexts/url-params-context';
import { cloneDeep } from 'lodash';
import { countFileKinds } from '../../common/table/document-types-cell';

export default function DocumentsPage(props: AuthenticatedProps): ReactElement {
    const [currentPurchaseOrderNumber, setCurrentDocumentRequest] = useState<DocumentRequest | null>(null);
    const [filters, setFilters] = useState<DocumentFilters>({});
    const [search, setSearch] = useState<string | undefined>();
    const [searchTooShort, setSearchTooShort] = useState<boolean>(false);
    const [multiSearch, setMultiSearch] = useState<boolean>(false);
    const prevSupplierId = useRef(props.supplier?.id).current;
    const { updateSearchParam } = useContext(UrlParamsContext);

    const { apiClient, supplier } = props;

    const retrievePage = useCallback(
        async (params: RetrievePageParams) => {
            if (!apiClient || !supplier?.id) {
                return null;
            }
            const { pageSize, pageIndex, signal } = params;
            const response = await apiClient.getDocumentRequests({
                supplierId: supplier.id,
                search,
                multiSearch,
                statuses: filters.statuses && filters.statuses.length > 0 ? filters.statuses : undefined,
                sort: filters.sort ?? undefined,
                pageSize,
                pageIndex,
                signal: signal,
            });

            response.items = splitRequestsByDocumentType(response.items, filters.fileKinds);

            const statuses = filters.statuses;
            if (statuses !== undefined) {
                response.items = filterOutStatuses(response.items, statuses);
            }

            return response;
        },
        [filters, search, apiClient, supplier?.id, multiSearch]
    );

    const setFiltersCallback = useCallback(
        (newFilters: DocumentFilters) => {
            // check if filters actually changed, to avoid infinite update loop
            if (Object.entries(newFilters).toString() !== Object.entries(filters).toString()) {
                setFilters(newFilters);
            }
        },
        [filters]
    );

    useEffect(() => {
        if (prevSupplierId !== props.supplier?.id && prevSupplierId !== undefined) {
            setSearch('');
            setFiltersCallback({});
            updateSearchParam('pageIndex', '0');
        }
    }, [props.supplier?.id, setFiltersCallback, updateSearchParam, prevSupplierId]);

    // Drop current PO number if any underlying thing changes
    useEffect(() => {
        setCurrentDocumentRequest(null);
    }, [props.apiClient, props.supplier]);

    const columns: Array<PageTableColumn<DocumentRequest>> = [
        ...documentRequestsTableColumns,
        {
            key: 'viewDetails',
            name: 'View Details',
            alignment: 'right' as const,
            component: (params: { data: DocumentRequest }) => {
                return (
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="sp-icon-button neutral"
                            disabled={params.data.files.length === 0}
                            onClick={() => {
                                const pon = params.data;
                                thirdPartyTrackEvent('PON Detail Modal opened', {
                                    purchaseOrderNumber: pon.purchaseOrderNumber,
                                });
                                setCurrentDocumentRequest(pon);
                            }}
                        >
                            <spIcons.normal.document />
                        </button>
                    </div>
                );
            },
            placeholder: <Placeholder style={roundedStyle(40, 40)} className="inline-block" />,
        },
    ];

    return (
        <>
            {props.apiClient && props.supplier && currentPurchaseOrderNumber && (
                <DocumentRequestModal
                    apiClient={props.apiClient}
                    supplier={props.supplier}
                    documentRequest={currentPurchaseOrderNumber}
                    onRequestClose={() => {
                        thirdPartyTrackEvent('PON Detail Modal closed');
                        setCurrentDocumentRequest(null);
                    }}
                />
            )}
            <TableRootPage<DocumentRequest>
                header="Purchase Order Numbers"
                columns={columns}
                selectedTab="documents"
                retrievePage={retrievePage}
                getRowKey={item =>
                    item.purchaseOrderNumber ?? '' + item.destinationZip ?? '' + item.invoiceNumber ?? ''
                }
                initialPageData={INITIAL_PAGE}
                apiClient={props.apiClient}
                supplier={props.supplier}
                suppliers={props.suppliers}
                user={props.user}
                filterComponent={
                    <>
                        <Search
                            apiClient={props.apiClient}
                            supplier={props.supplier}
                            suppliers={props.suppliers}
                            user={props.user}
                            setSearchCallback={setSearch}
                            setSearchTooShortCallback={setSearchTooShort}
                            setMultiSearchCallback={setMultiSearch}
                            enableMultiSearch={true}
                            label="Search PO Numbers..."
                        />
                        <DocumentFiltersComponent
                            apiClient={props.apiClient}
                            supplier={props.supplier}
                            suppliers={props.suppliers}
                            user={props.user}
                            setFiltersCallback={setFiltersCallback}
                        />
                    </>
                }
                filtersActive={Object.values(filters).length > 0}
                searchTooShort={searchTooShort}
                downloadTransform={(dr: DocumentRequest) => {
                    const result: Record<string, unknown> = cloneDeep(dr);
                    result.trackingNumbers = dr.attributes?.trackingNumbers?.join(' | ') ?? '';
                    result.referenceNumbers = dr.attributes?.referenceNumbers?.join(' | ') ?? '';
                    result.proNumbers = dr.attributes?.referenceNumbers?.join(' | ') ?? '';
                    result.suggestedProviders = dr.attributes?.suggestedProviders?.join(' | ') ?? '';
                    const docType = dr.trackers?.find(t => t.kind)?.kind;
                    result.documentType = docType ?? '';
                    result.fileCount = docType ? countFileKinds(dr)[docType] ?? '0' : '0';
                    result.foundIntegrations = trackerTypeFilters.finished(dr).join(' | ');
                    result.pendingIntegrations = trackerTypeFilters.pending(dr).join(' | ');
                    result.locatedIntegrations = trackerTypeFilters.located(dr).join('| ');
                    result.failedIntegrations = trackerTypeFilters.failed(dr).join(' | ');
                    return result;
                }}
            />
        </>
    );
}
/** Dummy data used before the first real page of table data. */
const now = new Date();
const DEFAULT_PAGE_SIZE = 20;
const INITIAL_PAGE: Page<DocumentRequest> = {
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 1,
    itemCount: DEFAULT_PAGE_SIZE,
    items: new Array(DEFAULT_PAGE_SIZE).fill(null).map((_, i) => ({
        id: i.toString(),
        supplierId: 'initial-supplier-id',
        purchaseOrderNumber: i.toString(),
        createdBy: 'initial-created-by',
        createdByName: 'initial-created-by-name',
        status: DocumentRequestStatus.PENDING,
        files: [],
        createdAt: now,
        updatedAt: now,
    })),
};
