import { ReactElement, useEffect, useMemo, useRef, useState, useContext } from 'react';

import Link from '../../common/link';
import PageTable, { PageTableColumn } from '../../common/page-table';
import useDebouncedWindowDimensions from '../../hooks/window-dimensions';
import { ApiClient } from '../../../api/types';
import { AuthenticatedProps } from '../../../auth/page-with-auth';
import { Page, Supplier } from '../../../types';
import { UrlParamsContext } from '../../../contexts/url-params-context';
import { MIN_SEARCH_LENGTH } from '../search';
import { TableSort } from './sort-utils';

type TableTab = 'pos' | 'documents' | 'all-files';

/*const TAB_OPTIONS: { id: TableTab; label: string; url: string }[] = [
    {
        id: 'pos',
        label: 'Purchase Order Numbers',
        url: '/purchase-order-numbers',
    },
    {
        id: 'documents',
        label: 'documents',
        url: '/documents',
    },
];*/

const DEFAULT_PAGE_SIZE = 20;

export type RetrievePageParams = {
    apiClient?: ApiClient;
    supplierId?: string;
    pageSize: number;
    pageIndex: number;
    signal: AbortSignal;
};

export interface TableRootPageProps<T extends Record<string, unknown>> extends AuthenticatedProps {
    header: string;
    columns: Array<PageTableColumn<T>>;
    retrievePage: (params: RetrievePageParams) => Promise<Page<T> | null>;
    getRowKey: (x: T) => string;
    initialPageData: Page<T>;
    topRightComponent?: ReactElement;
    selectedTab: TableTab;
    filterComponent?: ReactElement;
    searchTooShort?: boolean;
    filtersActive?: boolean;
    downloadTransform?: (x: T) => Record<string, unknown>;
    multiSupplierView?: boolean;
    selectedSuppliers?: Supplier[];
    hideCsvExport?: boolean;
    /** Message to display when user has no data in the table */
    emptyTableComponent?: JSX.Element;
    /** Message to display when user has no data in the table and filters are set */
    filteredEmptyTableComponent?: JSX.Element;
    sort?: TableSort<T>;
    setSort?: (sort: TableSort<T>) => void;
}

export default function TableRootPage<T extends Record<string, unknown>>(props: TableRootPageProps<T>): ReactElement {
    const { supplier } = props;
    const { searchParams, updateSearchParam, deleteSearchParam } = useContext(UrlParamsContext);

    const abortControllerRef = useRef(new AbortController());
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<Page<T> | null>(null);
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
    const [pageIndex, setPageIndex] = useState<number>(0);

    const { retrievePage } = props;

    const { width } = useDebouncedWindowDimensions(50);

    const isAdmin = props.user?.roles?.some(r => r === 'supplypike.support');

    // Load stuff from the URL whenever it changes
    // TODO: Consider using a custom React hook to handle this more generally,
    //       similar to the hook described here https://www.inkoop.io/blog/syncing-query-parameters-with-react-state/
    useEffect(() => {
        const pageSizeFromUrl = searchParams.get('pageSize');
        const pageIndexFromUrl = searchParams.get('pageIndex');
        const pageSize = pageSizeFromUrl !== null ? Number.parseInt(pageSizeFromUrl) : DEFAULT_PAGE_SIZE;
        const pageIndex = pageIndexFromUrl !== null ? Number.parseInt(pageIndexFromUrl) : 0;
        setPageSize(pageSize);
        setPageIndex(pageIndex);
    }, [searchParams]);

    // Re-request a page of table data if request params change
    useEffect(() => {
        setLoading(true);
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        retrievePage({
            pageSize,
            pageIndex,
            signal: abortControllerRef.current.signal,
        })
            .then(newPage => {
                if (newPage !== null) {
                    setLoading(false);
                }
                setPage(newPage);
            })
            .catch(err => {
                if (err.name !== 'AbortError') {
                    throw err;
                }
            });
        return () => {
            abortControllerRef.current.abort();
        };
    }, [pageSize, pageIndex, retrievePage]);

    function handleRequestPageChange(params: { pageSize: number; pageIndex: number }) {
        if (params.pageSize) {
            updateSearchParam('pageSize', params.pageSize.toString(10));
        } else {
            deleteSearchParam('pageSize');
        }
        if (params.pageIndex) {
            updateSearchParam('pageIndex', params.pageIndex.toString(10));
        } else {
            deleteSearchParam('pageIndex');
        }
    }

    /** Used for displaying error messages. */
    const emptyPage = useMemo<Page<T>>(
        () => ({
            pageIndex: 0,
            pageSize,
            pageCount: 1,
            itemCount: 0,
            items: [],
        }),
        [pageSize]
    );

    function getEmptyTableMessage(): JSX.Element {
        const noIntegrationsMessage = (
            <p className="m-4 sp-body">
                You don&apos;t have any shipping document integrations. Go to the{' '}
                <Link href="https://app.supplypike.com/settings/integrations">Integrations</Link> page to set one up.
                <br />
                <Link href="https://help.supplypike.com/en/articles/5923531-shipping-document-integrations">
                    What is a shipping document integration?
                </Link>
            </p>
        );

        const searchTooShortMessage = (
            <p className="m-4 sp-body">
                Please enter at least {MIN_SEARCH_LENGTH} characters into the search bar to see matches.
            </p>
        );

        const emptyTableFilteredMessage = props.filteredEmptyTableComponent ?? (
            <p className="m-4 sp-body">
                You have no Purchase Order Numbers to display with these filters.
                <br />
                Try adjusting your filters.
            </p>
        );

        const emptyTableMessage = props.emptyTableComponent ?? (
            <p className="m-4 sp-body">
                No shipping documents have been requested. Click the &quot;Add New&quot; button to get started.
                <br />
                <Link href="https://help.supplypike.com/en/articles/5923536-requesting-new-documents">
                    How does the Purchase Order Numbers table get populated?
                </Link>
            </p>
        );

        const hasIntegrations = props.multiSupplierView
            ? props.selectedSuppliers?.find(sup => sup.integrations.length !== 0)
            : props.supplier?.integrations.length === 0;

        if (!hasIntegrations) {
            return noIntegrationsMessage;
        } else if (props.searchTooShort) {
            return searchTooShortMessage;
        } else if (props.filtersActive) {
            return emptyTableFilteredMessage;
        } else {
            return emptyTableMessage;
        }
    }

    return (
        <>
            <h1 className="page-title sp-h1">{props.header}</h1>
            <hr className="border-neutral-200 mt-4" />
            {/* Temporarily hiding tabs */}
            {/*<div className="sp-tab-container" style={{ marginTop: '24px' }}>
                {TAB_OPTIONS.map(option => (
                    <button
                        key={option.id}
                        className={`sp-base-tab ${option.id === props.selectedTab ? 'active' : ''}`}
                        onClick={() => navigate(option.url)}
                    >
                        {option.label}
                    </button>
                ))}
            </div>*/}

            <div className={`flex items-center ${width >= 1200 ? 'mt-2' : ''} flex-wrap`}>
                {width >= 1200 && props.filterComponent}
                {props.topRightComponent && props.topRightComponent}
            </div>
            {width < 1200 && <div className="flex items-center flex-wrap">{props.filterComponent}</div>}
            {supplier?.id && (
                <PageTable<T>
                    columns={props.columns}
                    page={props.searchTooShort ? emptyPage : page || props.initialPageData}
                    getRowKey={props.getRowKey}
                    onRequestPageChange={handleRequestPageChange}
                    retrievePage={({ pageSize, pageIndex }) =>
                        props.retrievePage({
                            apiClient: props.apiClient,
                            supplierId: supplier?.id,
                            pageSize,
                            pageIndex,
                            signal: abortControllerRef.current.signal,
                        })
                    }
                    emptyElement={getEmptyTableMessage()}
                    loading={loading}
                    // hidden until we test it in prod
                    // to see if it breaks with lots of data
                    enableDownload={isAdmin && !props.hideCsvExport}
                    downloadColumnBlackList={[
                        'createdBy',
                        'createdBySource',
                        'files',
                        'supplierId',
                        'trackers',
                        'attributes',
                    ]}
                    downloadTransform={props.downloadTransform}
                    multiSupplierView={props.multiSupplierView}
                    suppliers={props.suppliers ?? undefined}
                    selectedSuppliers={props.selectedSuppliers}
                    sort={props.sort}
                    setSort={props.setSort}
                />
            )}
        </>
    );
}
