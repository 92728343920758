import { spIcons } from '@casestack/supplypike-ui';
import { ReactElement } from 'react';

interface OwnProps {
    onClick: () => void;
}

export default function CloseButton(props: OwnProps): ReactElement {
    return (
        <button type="button" className="sp-icon-button neutral" aria-label="Close" onClick={() => props.onClick()}>
            <spIcons.small.close />
        </button>
    );
}
