import { ReactElement, useMemo, useState, useContext, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import { AddAttributesRequestRow } from '../../../../api/types';
import { thirdPartyTrackEvent } from '../../../../third-party';
import CloseButton from '../../../common/close-button';
import ToastsContext from '../../../../contexts/toasts-context';
import ModalInputTable from '../../../common/modal-input-table';
import { ModalTableColumn } from '../../../common/modal-input-table/types';
import { optionalColumns, requiredColumns } from './columns';
import { AddAttributesRow, CsvRow } from './types';
import ModalInputTableConfirmationPopup from '../../../common/modal-input-table/modal-input-table-confirmation-popup';
import ModalInputTableFooter from '../../../common/modal-input-table/modal-input-table-footer';
import ModalInputTableValidationMessage from '../../../common/modal-input-table/modal-input-table-validation-message';
import { pluralize } from '../../../../util/string-utils';
import { addRows, deleteRow, setRow } from '../add-new-modal/utils';
import Modal from '../../../common/modal';
import { AddNewModalProps } from '../add-new-modal/tabs';
import { SpButton, spIcons } from '@casestack/supplypike-ui';
import { spColors } from '@casestack/style-themes';
import PopoverButton from '../../../common/popover-button';
import SimpleSelect from '../../../common/simple-select';
import ContextNotification from '../../../common/context-notification';
import Confirmation from '../../../common/confirmation';
import { getRetailersInPriorityOrder } from '../../../../util/retailer-utils';

type ModalState = 'initial' | 'review';

export default function AddAttributesModal(props: Readonly<AddNewModalProps>): ReactElement {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<ModalState>('initial');
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [showRemoveErrorsConfirmation, setShowRemoveErrorsConfirmation] = useState<boolean>(false);
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [csvRows, setCsvRows] = useState<AddAttributesRow[]>([]);
    const [selectedRetailer, setSelectedRetailer] = useState<string | null>(null);
    const [retailers, setRetailers] = useState<string[]>([]);
    const [optionalCols, setOptionalCols] = useState<Set<ModalTableColumn<AddAttributesRow>>>(new Set(optionalColumns));
    const toasts = useContext(ToastsContext);

    const { hasDataCallback, suppliers } = props;

    const hasData: boolean = useMemo(() => {
        hasDataCallback(!!csvRows?.length);
        return !!csvRows?.length;
    }, [csvRows, hasDataCallback]);

    const rowsWithValidationErrors: Map<number, AddAttributesRow> = useMemo(() => {
        const map = new Map<number, AddAttributesRow>();
        for (let i = 0; i < csvRows.length; i++) {
            const row = csvRows[i];
            if (row.validationErrors && row.validationErrors.length) {
                map.set(i, row);
            }
        }
        return map;
    }, [csvRows]);

    useEffect(() => {
        // Get a list of all retailers this user has a supplier for
        const retailerSet: Set<string> = new Set();
        for (const supplier of suppliers) {
            retailerSet.add(supplier.retailerName);
        }
        const retailersInPriorityOrder = getRetailersInPriorityOrder(Array.from(retailerSet));
        if (retailersInPriorityOrder.length === 1) {
            setSelectedRetailer(retailersInPriorityOrder[0]);
        }
        setRetailers(retailersInPriorityOrder);
    }, [setRetailers, suppliers]);

    function reset() {
        const nextRows: AddAttributesRow[] = [];
        setCsvRows(nextRows);
        setCsvFile(null);
        setLoading(false);
        setOptionalCols(new Set(optionalColumns));
        setState('initial');
        props.setResetConfirmationVisible(false);
    }

    function removeErrorRows() {
        const csvRowsWithNoErrors = csvRows.filter((_, index) => !rowsWithValidationErrors.has(index));
        // If removing error rows results in no valid rows, reset the modal
        if (csvRowsWithNoErrors.length === 0) {
            reset();
        } else {
            setCsvRows(csvRowsWithNoErrors);
        }
        setShowRemoveErrorsConfirmation(false);
    }

    function validateRows() {
        const dtos: AddAttributesRequestRow[] = csvRows.map(row => getAddAttributesRequestRow(row));

        setLoading(true);
        props.apiClient
            .validateAttributes(
                dtos,
                suppliers.filter(sup => sup.retailerName === selectedRetailer).map(sup => sup.id)
            )
            .then(validationRows => {
                const newRows: AddAttributesRow[] = [];
                let index = 0;
                for (const validationRow of validationRows) {
                    if (!validationRow.isValid) {
                        newRows.push({
                            ...csvRows[index],
                            validationErrors: [{ message: validationRow.errorMessage ?? 'Unknown error occurred' }],
                        });
                    } else {
                        const curRow = csvRows[index];
                        curRow.validationErrors = undefined;
                        newRows.push(curRow);
                    }
                    index++;
                }
                setCsvRows(newRows);
                setLoading(false);
                setState('review');
            });
    }

    function submitRows() {
        const dtos: AddAttributesRequestRow[] = csvRows.map(row => getAddAttributesRequestRow(row));

        setLoading(true);
        thirdPartyTrackEvent('Add Attributes Modal submitted', { attributesCount: dtos.length });
        props.apiClient
            .addAttributes(
                dtos,
                suppliers.filter(sup => sup.retailerName === selectedRetailer).map(sup => sup.id)
            )
            .then(() => {
                setLoading(false);
                toasts.add({
                    key: `attributes-added-${Date.now()}`,
                    color: 'green',
                    title: `${pluralize(
                        dtos.length,
                        'Attributes Row'
                    )} are being processed for each ${selectedRetailer} supplier`,
                    body: 'We will use these attributes to search for your documents. Thanks!',
                });
                props.onRequestClose(true);
            });
    }

    function getAddAttributesRequestRow(row: AddAttributesRow): AddAttributesRequestRow {
        return {
            // If any of these identifiers are empty string, we want to send undefined instead
            purchaseOrderNumber: row.purchaseOrderNumber ? row.purchaseOrderNumber : undefined,
            invoiceNumber: row.invoiceNumber ? row.invoiceNumber : undefined,
            destinationZip: row.destinationZip ? row.destinationZip : undefined,
            attributes: {
                ...(row.referenceNumbers && {
                    referenceNumbers: row.referenceNumbers.split(','),
                }),
                ...(row.proNumbers && { proNumbers: row.proNumbers.split(',') }),
                ...(row.suggestedProviders && {
                    suggestedProviders: row.suggestedProviders.split(','),
                }),
                ...(row.trackingNumbers && { trackingNumbers: row.trackingNumbers.split(',') }),
                ...(row.invoiceDate && { invoiceDate: [row.invoiceDate] }),
            },
        };
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setCsvFile(e.target.files[0]);
            Papa.parse<CsvRow>(e.target.files[0], {
                header: true,
                skipEmptyLines: true,
                beforeFirstChunk: () => {
                    // Clear all optionalColumns before starting the parse
                    // This allows us to only show the columns from the imported CSV file
                    const curCols = optionalCols;
                    curCols.clear();
                    setOptionalCols(curCols);
                },
                complete: results => {
                    const newCsvRows: AddAttributesRow[] = [];
                    for (const row of results.data) {
                        newCsvRows.push({
                            purchaseOrderNumber: row['po number'],
                            invoiceNumber: row['invoice number'],
                            destinationZip: row['dest zip'],
                            referenceNumbers: row['reference numbers'],
                            proNumbers: row['pro numbers'],
                            trackingNumbers: row['tracking numbers'],
                            suggestedProviders: row['suggested carriers'],
                            invoiceDate: row['invoice date'],
                        });
                    }
                    // TODO: combine any rows with the same PO
                    setCsvRows(newCsvRows);
                },
                transformHeader: (header: string) => {
                    // TODO: add more validation here, throw error if bad header, add better header transformation logic
                    // Remove '(optional)' and '.' here in case the user added those as part of the header
                    const modifiedHeader = header
                        .toLowerCase()
                        .replace(/(?:\(optional\)|\.)/gi, '')
                        .trim();
                    const curOptionalColumns = optionalCols;
                    const isOptCol = optionalColumns.find(
                        col => col.title.replace('.', '').toLowerCase() === modifiedHeader
                    );
                    if (isOptCol) {
                        curOptionalColumns.add(isOptCol);
                        setOptionalCols(curOptionalColumns);
                    }
                    return modifiedHeader;
                },
            });
        }
    };

    function handleClick() {
        hiddenFileInput.current?.click();
    }

    return (
        <Modal visible={true} onRequestClose={props.handleRequestClose}>
            <div className="modal-close">
                <CloseButton onClick={props.handleRequestClose} />
            </div>

            <header className="modal-header">
                <h2 className="modal-title sp-h2">Add Attributes</h2>
            </header>

            <div className="modal-body">
                <ModalInputTableValidationMessage
                    state={state}
                    rowsWithValidationErrors={rowsWithValidationErrors}
                    numRows={csvRows.length}
                    dataType={'attribute rows'}
                />
                {csvFile && (
                    <div className="flex justify-between">
                        <h2 className="font-bold ml-2">Review - {selectedRetailer}</h2>
                        {rowsWithValidationErrors.size !== 0 && (
                            <SpButton
                                theme="outline-button"
                                colorTheme="red"
                                label="Remove Errors"
                                onClick={() => setShowRemoveErrorsConfirmation(true)}
                                style={{ color: spColors.all.red700 }}
                            ></SpButton>
                        )}
                        {showRemoveErrorsConfirmation && (
                            <Confirmation
                                onConfirm={() => removeErrorRows()}
                                confirmText="Remove All"
                                onRequestClose={() => setShowRemoveErrorsConfirmation(false)}
                            >
                                <p className="sp-h2 text-center">
                                    Are you sure you want to remove all rows with errors?
                                </p>
                                <p className="sp-body text-center mt-2">You can always restart the process.</p>
                            </Confirmation>
                        )}
                    </div>
                )}
                {!csvFile && (
                    <div>
                        {selectedRetailer === null && (
                            <div className="mb-2">
                                <ContextNotification
                                    theme="red"
                                    icon={<spIcons.small.alert />}
                                    title={
                                        'Please make a selection from the "Select a Retailer" list before uploading a CSV file.'
                                    }
                                ></ContextNotification>
                            </div>
                        )}
                        <div className="flex justify-between">
                            <SpButton
                                onClick={handleClick}
                                label="Upload CSV File"
                                theme="outline-button"
                                style={{
                                    color: selectedRetailer === null ? spColors.all.blue500 : spColors.all.blue700,
                                }}
                                disabled={selectedRetailer === null}
                            />
                            <PopoverButton
                                theme="filter"
                                label={selectedRetailer ?? 'Select a Retailer'}
                                disabled={state === 'review' || retailers.length === 1}
                                anchor="right"
                                showPopover={showPopover}
                                onClick={() => setShowPopover(true)}
                                onRequestClose={() => setShowPopover(false)}
                            >
                                <SimpleSelect
                                    options={retailers.map(retailer => ({
                                        id: retailer,
                                        label: retailer,
                                        value: retailer,
                                    }))}
                                    value={selectedRetailer}
                                    onChange={value => {
                                        setSelectedRetailer(value);
                                        setShowPopover(false);
                                    }}
                                />
                            </PopoverButton>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                ref={hiddenFileInput}
                                style={{ display: 'none' }}
                            />
                        </div>
                        <div className="italic text-sm mt-2">
                            * CSV headers should match the column titles below exactly
                        </div>
                    </div>
                )}

                <div className="h-4" />

                <ModalInputTable<AddAttributesRow>
                    requiredColumns={requiredColumns}
                    optionalColumns={Array.from(optionalCols)}
                    rows={csvRows}
                    rowsWithValidationErrors={rowsWithValidationErrors}
                    setRow={(row, index) => setRow(csvRows, row, index, setCsvRows, state)}
                    addRows={(newRows, index) => addRows(csvRows, newRows, setCsvRows, state, index, true)}
                    deleteRow={index => deleteRow(csvRows, index, setCsvRows, state, reset, true)}
                    loading={loading}
                    review={state === 'review'}
                />
            </div>

            <ModalInputTableFooter
                state={state}
                hasData={hasData}
                rowsWithValidationErrors={rowsWithValidationErrors}
                loading={loading}
                validateRows={validateRows}
                submitRows={submitRows}
                // The functionality for 'disableInputButtons' prop will be implemented in the next story of this epic.
                // For now just passing false so it has no affect and keeps the component happy.
                disableInputButtons={false}
                handleRequestClose={props.handleRequestClose}
                setResetConfirmationVisible={props.setResetConfirmationVisible}
            />

            <ModalInputTableConfirmationPopup
                message="Are you sure you want to cancel adding attributes?"
                event="Add Attributes Modal reset"
                closeConfirmationVisible={props.closeConfirmationVisible}
                resetConfirmationVisible={props.resetConfirmationVisible}
                setCloseConfirmationVisible={props.setCloseConfirmationVisible}
                setResetConfirmationVisible={props.setResetConfirmationVisible}
                onRequestClose={props.onRequestClose}
                reset={reset}
            />
        </Modal>
    );
}
