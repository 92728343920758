import { CarrierTracker, TemporaryCarrierTracker, DocumentRequest } from '../../../types';
import { DocumentRequestStatus, DocumentRequestPendingSubstatus, FileKind } from '../../../api/types';
import { cloneDeep } from 'lodash';

function splitRequestsByDocumentType(requests: DocumentRequest[], onlyFileKinds?: FileKind[]) {
    const output: DocumentRequest[] = [];

    for (const request of requests) {
        if (!request.trackers?.length) {
            // don't push a trackerless request if we're filtering by tracker type
            if (!onlyFileKinds?.length) {
                output.push(request);
            }
            continue;
        }
        const splitTrackers = request.trackers.reduce(
            (prev: Partial<Record<FileKind, (CarrierTracker | TemporaryCarrierTracker)[]>>, curr) => {
                prev[curr.kind] = [curr].concat(prev[curr.kind] ?? []);
                return prev;
            },
            {}
        );

        const uniqueKinds = Object.keys(splitTrackers) as FileKind[];

        // if only one type of doc, no need to split
        if (uniqueKinds.length === 1) {
            if (!onlyFileKinds?.length || onlyFileKinds.includes(uniqueKinds[0])) {
                output.push(request);
            }
            continue;
        }
        for (const kind of uniqueKinds) {
            // don't include the request if it's type doesn't match up with ones specifically requested
            if (onlyFileKinds?.length && !onlyFileKinds.includes(kind)) {
                continue;
            }
            const splitRequest = cloneDeep(request);
            splitRequest.trackers = splitTrackers[kind];
            splitRequest.files = splitRequest.files.filter(file => file.kind === kind);
            output.push(splitRequest);
        }
    }
    return output;
}

function filterOutStatuses(
    requests: DocumentRequest[],
    statuses: (DocumentRequestStatus | DocumentRequestPendingSubstatus)[]
) {
    return requests.filter(r => {
        if (r.files.length && statuses.includes(DocumentRequestStatus.FINISHED)) {
            return true;
        }
        if (
            statuses.includes(DocumentRequestStatus.PENDING) ||
            statuses.includes(DocumentRequestStatus.FAILED) ||
            statuses.includes(DocumentRequestPendingSubstatus.LOCATED)
        ) {
            const trackers = r.trackers ?? [];
            const allFailed = trackers.every(
                t => (t.status === 'failed' && t.failedCount >= 3) || t.status === 'cancelled'
            );
            const anyLocated = trackers.some(t => t.status === 'located');
            if (allFailed && statuses.includes(DocumentRequestStatus.FAILED)) {
                return true;
            } else if (!allFailed && statuses.includes(DocumentRequestStatus.PENDING)) {
                return true;
            } else if (anyLocated && statuses.includes(DocumentRequestPendingSubstatus.LOCATED)) {
                return true;
            }
        }
        return false;
    });
}

export { filterOutStatuses, splitRequestsByDocumentType };
