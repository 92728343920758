import { FC } from 'react';
import { DocumentRequest, CarrierTracker, TemporaryCarrierTracker } from '../../../types';
import { PageTableColumn } from '../../common/page-table';
import Placeholder, { roundedStyle } from '../../common/placeholder';
import DateCell from '../../common/table/date-cell';
import DocumentTypesCell from '../../common/table/document-types-cell';
import PoInfoCell from '../../common/table/po-info-cell';
import ListCell from '../../common/table/list-cell';

function createSpecificDateCell(key: 'createdAt' | 'updatedAt') {
    const SpecificDateCell: FC<{ data: DocumentRequest }> = ({ data }) => <DateCell date={data[key]} />;
    return SpecificDateCell;
}

function extractIntegrationNames(
    data: DocumentRequest,
    condition: (tracker: CarrierTracker | TemporaryCarrierTracker) => boolean
) {
    return (data.trackers ?? []).reduce((prev: string[], curr) => {
        if (condition(curr)) {
            return prev.concat([curr.providerName]);
        }
        return prev;
    }, []);
}

export const trackerTypeFilters = {
    pending: (data: DocumentRequest) =>
        extractIntegrationNames(
            data,
            tracker => data.status === 'pending' && ['pending', 'started'].includes(tracker.status)
        ),
    located: (data: DocumentRequest) =>
        extractIntegrationNames(data, tracker => ['located', 'downloading'].includes(tracker.status)),
    finished: (data: DocumentRequest) =>
        extractIntegrationNames(data, tracker => ['finished'].includes(tracker.status)),
    failed: (data: DocumentRequest) =>
        extractIntegrationNames(
            data,
            // tracker will not be tried again because either
            // (1) it hit the failure retry limit
            // (2) it was manually cancelled because it was unlikely to ever succeed
            // (3) another integration was either finished or located
            tracker =>
                (tracker.status === 'failed' &&
                    (tracker.failedCount >= 3 || data.status !== 'pending' || data.substatus === 'located')) ||
                tracker.status === 'cancelled'
        ),
};

export const documentRequestsTableColumns: Array<PageTableColumn<DocumentRequest>> = [
    {
        key: 'createdAt',
        name: 'Date Requested',
        alignment: 'left' as const,
        component: createSpecificDateCell('createdAt'),
        placeholder: (
            <div className="flex flex-col items-end">
                <Placeholder style={roundedStyle('10ch', '1rem')} />
                <Placeholder style={roundedStyle('12ch', '1rem')} className="mt-2" />
            </div>
        ),
    },
    {
        key: 'fileKinds',
        name: '# of Files',
        component: props =>
            DocumentTypesCell({ data: props.data, targetKind: props.data.trackers?.find(t => t.kind)?.kind }),
        placeholder: (
            <div className="flex flex-col">
                <Placeholder style={roundedStyle('8ch', '1.5rem')} />
                <Placeholder style={roundedStyle('8ch', '1.5rem')} className="mt-2" />
            </div>
        ),
    },
    {
        key: 'purchaseOrderNumber',
        name: 'PO Info',
        placeholder: <Placeholder style={roundedStyle('10ch', '1rem')} />,
        component: PoInfoCell,
    },

    {
        key: 'pendingTrackers',
        name: 'Pending Integrations',
        placeholder: <Placeholder style={roundedStyle('10ch', '1rem')} />,
        component: props =>
            ListCell({
                list: trackerTypeFilters.pending(props.data),
                verb: 'Pending',
                title: 'Pending Integrations',
            }),
    },
    {
        key: 'locatedTrackers',
        name: 'Located Integrations',
        placeholder: <Placeholder style={roundedStyle('10ch', '1rem')} />,
        component: props =>
            ListCell({
                list: trackerTypeFilters.located(props.data),
                verb: 'Located',
                title: 'Located Integrations',
            }),
    },
    {
        key: 'foundTrackers',
        name: 'Successful Integrations',
        placeholder: <Placeholder style={roundedStyle('10ch', '1rem')} />,
        component: props =>
            ListCell({
                list: trackerTypeFilters.finished(props.data),
                verb: 'Found',
                title: 'Successful Integrations',
            }),
    },
    {
        key: 'failedTrackers',
        name: 'Failed Integrations',
        placeholder: <Placeholder style={roundedStyle('10ch', '1rem')} />,
        component: props =>
            ListCell({
                list: trackerTypeFilters.failed(props.data),
                verb: 'Failed',
                title: 'Failed Integrations',
            }),
    },
];
