import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';

interface OwnProps {
    displayDuration: number;
    onFadeOut: () => void;
    children: ReactNode;
    className?: string;
    transitionDuration?: number;
}

const DEFAULT_TRANSITION_DURATION = 250;

export default function FadeWrapper({
    displayDuration,
    onFadeOut,
    children,
    className,
    transitionDuration,
}: OwnProps): ReactElement {
    const [visible, setVisible] = useState<boolean>(false);

    const handleTransitionEnd = useCallback(
        (_event: React.TransitionEvent) => {
            !visible && onFadeOut();
        },
        [onFadeOut, visible]
    );

    useEffect(() => {
        setTimeout(() => setVisible(true), 10);
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => setVisible(false), displayDuration);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [displayDuration]);

    const style: React.CSSProperties = {
        transitionProperty: 'opacity',
        transitionDuration: `${transitionDuration ?? DEFAULT_TRANSITION_DURATION}ms`,
        opacity: visible ? 1 : 0,
    };

    return (
        <div className={className} style={style} onTransitionEnd={handleTransitionEnd}>
            {children}
        </div>
    );
}
