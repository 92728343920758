import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { thirdPartyTrackPage } from './third-party';
import { Location } from 'history';

/**
 * Note: logged page navigation includes redirects.
 * E.g., 2 log entries for `/` => `/purchase-order-numbers`.
 */
export const RouteLogger = (): ReactElement => {
    const location: Location = useLocation();
    const [prevLocation, setPrevLocation] = React.useState<Location | null>(null);

    React.useEffect(() => {
        // Prevent double-logging in React Strict mode,
        // by checking for changes ourselves.
        if (location && location !== prevLocation) {
            thirdPartyTrackPage({ originalPath: location.pathname });
            setPrevLocation(location);
        }
    }, [location, prevLocation]);

    return <></>;
};
