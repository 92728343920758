import { ReactElement } from 'react';
import { spIcons } from '@casestack/supplypike-ui';
import DemoInputArray from './input-array';
import { Link } from 'react-router-dom';
import { AttachmentIcon, MessageIcon, InvoiceSelectedIcon, ArrowIcon } from './demo-icons';
import './demo.css';

export default function DisputingDemoInvoicesPage(): ReactElement {
    return (
        <>
            <div className="demo-header-box">
                <div className="demo-icon-container">
                    <InvoiceSelectedIcon />
                    <ArrowIcon />
                    <Link key={'attachments'} to={'/disputing-demo/attachments'}>
                        <AttachmentIcon />
                    </Link>

                    <ArrowIcon />
                    <MessageIcon />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '250px',
                        marginLeft: '10px',
                        marginTop: '6px',
                    }}
                >
                    <div className="demo-icon-text-box">
                        <span className="demo-light-text"> Invoices </span>
                    </div>
                    <div className="demo-icon-text-box">
                        <span className="demo-light-text"> Attachments </span>
                    </div>
                    <div className="demo-icon-text-box">
                        <span className="demo-light-text"> Messages </span>
                    </div>
                </div>
            </div>

            <div className="demo-gradient-background" />
            <div className="demo-main-background">
                <div className="demo-row-margin">
                    <div className="demo-title-rectangle" />
                    <div className="demo-filled-button-rectangle">
                        <div className="demo-button-filling" />
                    </div>
                </div>
                <div
                    style={{
                        paddingLeft: '31px',
                        marginTop: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div style={{ width: '20.5%' }}>
                        <span className="demo-text">Debit Memo Reference Number </span>
                    </div>
                    <div style={{ width: '15%' }}>
                        <span className="demo-text">CVS Purchase Order </span>
                    </div>
                    <div style={{ width: '15.5%', paddingLeft: '18px' }}>
                        <span className="demo-text">Disputed Amount </span>
                    </div>
                    <div style={{ width: '15.5%', paddingLeft: '4px' }}>
                        <span className="demo-text">Invoice Date</span>
                    </div>
                    <div>
                        <span className="demo-text">Comments </span>
                    </div>
                </div>
                <div className="demo-row-margin">
                    <div style={{ width: '20%', paddingRight: '30px' }}>
                        <div className="demo-title-underline-rectangle " />
                        <DemoInputArray
                            values={['154783', '243657', '422675', '426875', '842364', '125478', '146357', '']}
                        />
                    </div>
                    <div className="demo-box">
                        <div className="demo-title-underline-rectangle " />
                        <DemoInputArray
                            values={['5412367', '6547142', '6841236', '7123654', '7365478', '8512145', '9512367', '']}
                        />
                    </div>
                    <div className="demo-box">
                        <div className="demo-title-underline-rectangle " style={{ marginLeft: '12px' }} />
                        <DemoInputArray
                            values={['1002.23', '1500.24', '1456.00', '1054.46', '1125.50', '2495.00', '1794.85', '']}
                            symbol="$"
                        />
                    </div>
                    <div className="demo-box">
                        <div className="demo-title-underline-rectangle " />
                        <DemoInputArray values={['', '', '', '', '', '', '', '']} />
                    </div>
                    <div className="demo-comment-box">
                        <DemoInputArray
                            values={['', '', '', '', '', '', '', '']}
                            icon={<spIcons.normal.plusCircle className="demo-close-icon" />}
                        />
                    </div>
                </div>
                <div className="demo-footer-rectangle">
                    <Link key={'attachments'} to={'/disputing-demo/attachments'} style={{ textDecoration: 'none' }}>
                        <div className="demo-validate-rectangle">
                            <span className="demo-light-text">Validate</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}
