import { format, getWeek, setMonth, setYear } from 'date-fns';

import { dateToWmMonth, dateToWmWeek, dateToWmYear, WmMonth } from '@casestack/walmart-calendar';

import { DateSelectionTimeScale } from './time-scale';

/**
 * @param date A selectable Date.
 * @param timeScale Selection and pagination time scales.
 * @returns A string label for the selectable Date.
 */
export function dateSelectionOptionToLabel(date: Date, timeScale: DateSelectionTimeScale): string {
    switch (timeScale.selectionTimeScale) {
        case 'day': {
            switch (timeScale.paginationTimeScale) {
                case 'month': {
                    const dayOfMonth = date.getDate();
                    return dayOfMonth.toString();
                }
                case 'wmMonth': {
                    const dayOfMonth = date.getUTCDate();
                    return dayOfMonth.toString();
                }
            }
        }
        case 'week': {
            switch (timeScale.paginationTimeScale) {
                case 'year': {
                    const weekOfMonth = getWeek(date);
                    return weekOfMonth.toString();
                }
            }
        }
        case 'wmWeek': {
            switch (timeScale.paginationTimeScale) {
                case 'wmYear': {
                    const betterDate = ignoreDaylightSavings(date);
                    const wmWeek = dateToWmWeek(betterDate);
                    return wmWeek.week.toString();
                }
            }
        }
    }
}

/**
 * @param date A selectable Date.
 * @param timeScale Selection and pagination time scales.
 * @returns A string label for the page.
 */
export function datePageToLabel(date: Date, timeScale: DateSelectionTimeScale): string {
    switch (timeScale.paginationTimeScale) {
        case 'month': {
            return format(date, 'LLLL Y');
        }
        case 'wmMonth': {
            const wmMonth = dateToWmMonth(date);
            const monthDisplayId = wmMonthDisplayId(wmMonth);
            const monthDate = setMonth(setYear(new Date(), wmMonth.year), wmMonth.month);
            const monthName = format(monthDate, 'LLLL');
            return `${monthDisplayId}, ${monthName}`;
        }
        case 'year': {
            return format(date, 'Y');
        }
        case 'wmYear': {
            const wmYear = dateToWmYear(date);
            return `Walmart Year ${wmYear}`;
        }
    }
}

/**
 * e.g., `202401` means first walmart month of 2024.
 * This format is what you would see on a DSS report.
 */
function wmMonthDisplayId(wmMonth: WmMonth) {
    const wmMonthString = wmMonth.month.toString().padStart(2, '0');
    return `${wmMonth.year}${wmMonthString}`;
}

/**
 * @param date A selectable Date.
 * @param timeScale Selection and pagination time scales.
 * @returns A string label for the sub-page,
 * which is basically a row or column within a page.
 */
export function dateSubPageToLabel(date: Date, timeScale: DateSelectionTimeScale): string {
    switch (timeScale.subPageTimeScale) {
        case 'week': {
            // return the empty string, because the week has no label.
            // The day-of-week has a label, but that goes elsewhere.
            return '';
        }
        case 'wmWeek': {
            // return the empty string, because the week has no label.
            // The day-of-week has a label, but that goes elsewhere.
            return '';
        }
        case 'month': {
            const monthName = format(date, 'LLL');
            return monthName;
        }
        case 'wmMonth': {
            const wmMonth = dateToWmMonth(date);
            const monthDate = setMonth(setYear(new Date(), wmMonth.year), wmMonth.month);
            const monthName = format(monthDate, 'LLL');
            return monthName;
        }
    }
}

/**
 * A quick hack to ignore off-by-one-hour issues
 * when converting from UTC to local time.
 */
function ignoreDaylightSavings(date: Date): Date {
    const noDstDate = new Date(date);
    if (noDstDate.getUTCHours() === 23) {
        noDstDate.setUTCHours(24);
    }
    if (noDstDate.getUTCHours() === 1) {
        noDstDate.setUTCHours(0);
    }
    return noDstDate;
}
