import { spTypography } from '@casestack/style-themes';

import { DaySelectionTimeScale, WmDaySelectionTimeScale } from '../logic/time-scale';

export interface DayOfWeekLabelsProps {
    timeScale: DaySelectionTimeScale | WmDaySelectionTimeScale;
}

export function DayOfWeekLabels(props: DayOfWeekLabelsProps): JSX.Element {
    const labelStrings = getLabelStrings(props.timeScale);
    return (
        // Actually, we will have 4px more padding from the date-buttons,
        // so it will add to 16px.
        <div style={{ display: 'flex', flexDirection: 'row', height: 32, marginBottom: 12 }}>
            {labelStrings.map((label, i) => (
                <span key={i} style={{ display: 'flex', flexDirection: 'row', width: 48 }}>
                    <span className={spTypography.h3} style={{ margin: 'auto' }}>
                        {label}
                    </span>
                </span>
            ))}
        </div>
    );
}

function getLabelStrings(timeScale: DaySelectionTimeScale | WmDaySelectionTimeScale): string[] {
    switch (timeScale.subPageTimeScale) {
        case 'week':
            return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
        case 'wmWeek':
            return ['S', 'S', 'M', 'T', 'W', 'T', 'F'];
    }
}
