import { spIcons } from '@casestack/supplypike-ui';
import { ReactElement } from 'react';

import './simple-select.css';

export interface SimpleSelectOption<T> {
    id: string;
    label: string;
    value: T;
}

type SingleValue<T> = T | null;
type MultiValue<T> = T[];
export type OnChangeValue<T, IsMulti extends boolean> = IsMulti extends true ? MultiValue<T> : SingleValue<T>;

interface OwnProps<T, IsMulti extends boolean> {
    options: SimpleSelectOption<T>[];
    multiple?: IsMulti;
    value: OnChangeValue<T, IsMulti>;
    isEqual?: (a: T | null, b: T | null) => boolean;
    onChange: (value: OnChangeValue<T, IsMulti>) => void;
    clearText?: string;
}

/**
 * @todo: Improve a11y. Use arrow keys for nav?
 */
export default function SimpleSelect<T, IsMulti extends boolean = false>(props: OwnProps<T, IsMulti>): ReactElement {
    const isEqual = props.isEqual || ((a: T, b: T) => a === b);
    const handleClick = (value: T | null) => {
        if (!props.onChange) {
            return;
        }

        if (!props.multiple) {
            props.onChange(value as OnChangeValue<T, IsMulti>);
        } else if (value === null) {
            props.onChange([] as T[] as OnChangeValue<T, IsMulti>);
        } else {
            const selected = (props.value ?? []) as T[];
            if (selected.some(v => isEqual(v, value))) {
                props.onChange(selected.filter(v => !isEqual(v, value)) as OnChangeValue<T, IsMulti>);
            } else {
                props.onChange([...selected, value] as OnChangeValue<T, IsMulti>);
            }
        }
    };

    const noneSelected = Array.isArray(props.value) ? props.value.length === 0 : props.value === null;

    return (
        <div className="simple-select">
            {props.clearText && (
                <button type="button" className="simple-select-option" onClick={() => handleClick(null)}>
                    <span>{props.clearText}</span>
                    <div
                        className={`simple-select-option-check
              ${noneSelected ? 'visible' : 'invisible'}`}
                    >
                        <spIcons.normal.check />
                    </div>
                </button>
            )}
            {props.options.map(option => {
                const selected = Array.isArray(props.value)
                    ? props.value.some(v => isEqual(option.value, v))
                    : isEqual(option.value, props.value as T);
                return (
                    <button
                        type="button"
                        key={option.id}
                        className="simple-select-option"
                        onClick={() => handleClick(option.value)}
                    >
                        <span>{option.label}</span>
                        <div className={`simple-select-option-check ${selected ? 'visible' : 'invisible'}`}>
                            <spIcons.normal.check />
                        </div>
                    </button>
                );
            })}
        </div>
    );
}
