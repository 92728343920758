import { ReactElement } from 'react';
import { ModalTableColumn, CellValue } from './types';
import LinearLoader from '../linear-loader';

interface OwnProps<T extends Record<string, CellValue>> {
    requiredColumns: ModalTableColumn<T>[];
    optionalColumns: ModalTableColumn<T>[];
    loading?: boolean;
}

function getSize(size: string): string {
    if (size === 'small') {
        return 'w-2/12';
    } else if (size === 'medium') {
        return 'w-3/12';
    } else {
        return 'w-4/12';
    }
}

export default function ModalInputTableHeaders<T extends Record<string, CellValue>>(props: OwnProps<T>): ReactElement {
    const columnCount = 1 + props.optionalColumns.length + props.requiredColumns.length;

    return (
        <thead>
            <tr>
                {props.requiredColumns.map(col => {
                    const size = getSize(col.size);
                    return (
                        <th className={size} key={col.columnName}>
                            {col.title}
                        </th>
                    );
                })}
                {props.optionalColumns.map(col => {
                    const size = getSize(col.size);
                    return (
                        <th className={size} key={col.columnName}>
                            <span>{col?.title}</span>
                            <span className="sp-caption"> (optional)</span>
                        </th>
                    );
                })}
                <th className="w-1/12" />
            </tr>
            <tr>
                <th colSpan={columnCount} className="loader-container">
                    {props.loading && <LinearLoader />}
                </th>
            </tr>
        </thead>
    );
}
