import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthenticatedProps } from '../../../auth/page-with-auth';
import { useNavigate } from 'react-router';
import TableRootPage, { RetrievePageParams } from '../../common/table';
import { Page, File, Supplier } from '../../../types';
import { PageTableColumn } from '../../common/page-table';
import { createdAtColumn, fileNameColumn, getSuppliersColumn, getSourcesColumn, getDownloadColumn } from './columns';
import { downloadFile } from '../../../util/file-utils';
import { getSelectedSuppliers } from '../../../util/supplier-utils';
import AllFilesViewFiltersComponent from './filters';
import { AllFilesViewFilters } from './types';
import { UrlParamsContext } from '../../../contexts/url-params-context';
import Search from '../../common/search';
import { SORT_COLUMN_PARAM, SORT_ORDER_PARAM, TableSort } from '../../common/table/sort-utils';

const DEFAULT_PAGE_SIZE = 20;
const SUPPLIERS_KEY = 'supplier';

export default function AllFilesViewPage(props: AuthenticatedProps): ReactElement {
    const { apiClient, user } = props;
    const [selectedSuppliers, setSelectedSuppliers] = useState<Supplier[]>([]);
    const [integrationIdsToProvidersMap, setIntegrationIdsToProvidersMap] = useState<Map<string, string>>(new Map());
    const [filters, setFilters] = useState<AllFilesViewFilters>({});
    const [search, setSearch] = useState<string | undefined>();
    const [searchTooShort, setSearchTooShort] = useState<boolean>(false);
    const [sort, setSort] = useState<TableSort<File>>({ order: 'DESC', column: 'createdAt' });
    const { updateSearchParam } = useContext(UrlParamsContext);
    const navigate = useNavigate();
    const isAdmin = user?.roles?.some(r => r === 'supplypike.support');

    function handleClickDownload(supplierIds: string[], id: string) {
        props.apiClient.getFileContent(supplierIds, id).then(({ filename, blob }) => {
            downloadFile(filename, blob);
        });
    }

    useEffect(() => {
        setSelectedSuppliers(getSelectedSuppliers(props.suppliers ?? [], !!isAdmin));
    }, [props.suppliers, isAdmin]);

    // This updates the URL whenever the user changes their selectedSuppliers using the multiSupplier selector
    useEffect(() => {
        const selectedSupplierIds = selectedSuppliers?.map(x => x.id);
        updateSearchParam(SUPPLIERS_KEY, selectedSupplierIds ?? []);
    }, [selectedSuppliers, updateSearchParam]);

    // Create a mapping of this user's integration ids to the integration names
    useEffect(() => {
        const integrationsMap: Map<string, string> = new Map();
        for (const supplier of props.suppliers ?? []) {
            const allIntegrations = supplier.integrations;
            for (const integration of allIntegrations) {
                if (!integrationsMap.get(integration.id)) {
                    integrationsMap.set(integration.id, integration.name);
                }
            }
        }
        setIntegrationIdsToProvidersMap(integrationsMap);
    }, [props.suppliers]);

    /** Updates the URL whenever the sort params change */
    useEffect(() => {
        updateSearchParam(SORT_ORDER_PARAM, sort.order);
        updateSearchParam(SORT_COLUMN_PARAM, sort.column);
    }, [sort, updateSearchParam]);

    // For now, redirect non admin users to the PO page
    useEffect(() => {
        if (!isAdmin) {
            navigate('/purchase-order-numbers');
        }
    }, [isAdmin, navigate]);

    // Columns are memoized, because actions rely on props or need to change state.
    const columns = useMemo<Array<PageTableColumn<File>>>(() => {
        const suppliersColumn = getSuppliersColumn(selectedSuppliers);
        const sourcesColumn = getSourcesColumn(integrationIdsToProvidersMap);
        const downloadColumn = getDownloadColumn(handleClickDownload);
        return [fileNameColumn, suppliersColumn, sourcesColumn, createdAtColumn, downloadColumn];
        // Disable eslint for the next line so we dont get warnings for not including 'handleClickDownload' as a dep
    }, [selectedSuppliers, integrationIdsToProvidersMap]); // eslint-disable-line react-hooks/exhaustive-deps

    const retrievePage = useCallback(
        async (params: RetrievePageParams) => {
            const { pageSize, pageIndex, signal } = params;
            if (!apiClient || !selectedSuppliers.length) {
                return null;
            }
            const response = await apiClient.getAllFiles(
                selectedSuppliers.map(x => x.id),
                {
                    gteDate: filters.gteDate,
                    lteDate: filters.lteDate,
                    integrations: filters.integrations,
                    search,
                    pageSize,
                    pageIndex,
                    signal,
                    sort,
                }
            );

            return response;
        },
        [selectedSuppliers, apiClient, filters, search, sort]
    );

    const setFiltersCallback = useCallback(
        (newFilters: AllFilesViewFilters) => {
            // check if filters actually changed, to avoid infinite update loop
            if (Object.entries(newFilters).toString() !== Object.entries(filters).toString()) {
                setFilters(newFilters);
            }
        },
        [filters]
    );

    const filteredEmptyTableComponent = (
        <p className="m-4 sp-body">
            You have no files to display with these filters.
            <br />
            Try adjusting your filters.
        </p>
    );

    const emptyTableComponent = <p className="m-4 sp-body">You have no files to display.</p>;

    return (
        <TableRootPage<File>
            header="All Files"
            columns={columns}
            selectedTab="all-files"
            retrievePage={retrievePage}
            getRowKey={item => item.id}
            initialPageData={INITIAL_PAGE}
            apiClient={props.apiClient}
            supplier={props.supplier}
            suppliers={props.suppliers}
            user={user}
            multiSupplierView={true}
            selectedSuppliers={selectedSuppliers}
            hideCsvExport={true}
            emptyTableComponent={emptyTableComponent}
            filteredEmptyTableComponent={filteredEmptyTableComponent}
            filtersActive={Object.values(filters).some(v => v !== undefined)}
            searchTooShort={searchTooShort}
            sort={sort}
            setSort={setSort}
            filterComponent={
                <>
                    <Search
                        apiClient={props.apiClient}
                        supplier={props.supplier}
                        suppliers={props.suppliers}
                        selectedSuppliers={selectedSuppliers}
                        user={props.user}
                        setSearchCallback={setSearch}
                        setSearchTooShortCallback={setSearchTooShort}
                        label="Search File Names..."
                    />
                    <AllFilesViewFiltersComponent
                        selectedSuppliers={selectedSuppliers}
                        setSelectedSuppliers={setSelectedSuppliers}
                        setFiltersCallback={setFiltersCallback}
                        apiClient={apiClient}
                        supplier={props.supplier}
                        suppliers={props.suppliers}
                        user={user}
                    />
                </>
            }
        />
    );
}

/** Dummy data used before the first real page of table data. */
const now = new Date();
const INITIAL_PAGE: Page<File> = {
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 1,
    itemCount: DEFAULT_PAGE_SIZE,
    items: new Array(DEFAULT_PAGE_SIZE).fill(null).map((_, i) => ({
        id: i.toString(),
        supplierIds: ['supplier-id-1', 'supplier-id-2'],
        createdAt: now,
        integrationIds: ['integration-id'],
        name: 'file.pdf',
    })),
};
