import { ReactElement, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Toasts from './components/layout/toasts';
import DisputingDemoAttachmentsPage from './components/pages/demo/attachments-page';
import DisputingDemoInvoicesPage from './components/pages/demo/invoices-page';
import NotFoundPage from './components/pages/not-found-page';
import DocumentsPage from './components/pages/documents';
import ToastsContext, { ToastParams, ToastsContextValue } from './contexts/toasts-context';
import PageWithAuth, { AuthenticatedProps } from './auth/page-with-auth';
import { usePromiseRejectionHandler } from './promise-rejection-handler';
import { RouteLogger } from './route-logger';
import CoreSidebarRedirect from './components/common/core-sidebar-redirect';
import UrlParams from './contexts/url-params-context';
import { CoreSupplierUI, SupplypikeEnvironment, SupplypikeWrapper } from '@casestack/supplypike-wrapper';
import { getAuthConfig } from './auth/get-auth-config';
import '@casestack/supplypike-wrapper/style.css';
import PurchaseOrderNumbersPage from './components/pages/purchase-order-numbers-page/index';
import AllFilesViewPage from './components/pages/all-files-view';
import { Environment, getEnvironment } from './env/environment';
import { getApiService } from './api/common';

export const getWrapperEnvironment = (): SupplypikeEnvironment => {
    const env = getEnvironment();
    if (env === Environment.LOCAL || env === Environment.STAGING) {
        return SupplypikeEnvironment.staging;
    } else if (env === Environment.PRODUCTION) {
        return SupplypikeEnvironment.production;
    } else {
        throw new Error(`Unknown environment: ${env}`);
    }
};

export default function App(): ReactElement {
    const [toasts, setToasts] = useState<ToastParams[]>([]);

    // TODO: useMemo? useCallback?
    const toastsContextValue: ToastsContextValue = {
        all: () => toasts,
        add: params => setToasts(toasts.concat(params)),
        delete: key => setToasts(toasts.filter(tp => tp.key !== key)),
        clear: () => setToasts([]),
    };
    usePromiseRejectionHandler(toastsContextValue);

    /** We should wrap all "normal" pages with an authentication requirement */
    function wrapPageWithAuth(page: (innerProps: AuthenticatedProps) => ReactElement): ReactElement {
        return <PageWithAuth component={page} />;
    }

    const loginCallbackUrl = window.location.protocol + '//' + window.location.host + '/callback';

    const customSuppliers = async (): Promise<CoreSupplierUI[]> => {
        const api = getApiService();

        const sups = await api.getSuppliers();

        sups.sort((a, b) => a.name.localeCompare(b.name));

        sups.forEach(sup => {
            sup.label = sup.name;
        });

        return sups;
    };

    return (
        <SupplypikeWrapper
            authClientId={getAuthConfig().clientId}
            env={getWrapperEnvironment()}
            solution={'documentExplorer'}
            loginCallbackUrl={loginCallbackUrl}
            customSuppliers={customSuppliers}
            autoAuthenticate={true}
            stayOnSolutionOnSupplierChange={true}
            refreshOnSupplierChange={false}
        >
            <BrowserRouter>
                <RouteLogger />
                <UrlParams>
                    <ToastsContext.Provider value={toastsContextValue}>
                        <Routes>
                            {/* login page */}
                            <Route path="/callback" element={<div> Loading... </div>} />
                            {/* normal pages */}
                            <Route path="/" element={<CoreSidebarRedirect />} />
                            <Route
                                path="/purchase-order-numbers"
                                element={wrapPageWithAuth(PurchaseOrderNumbersPage)}
                            />
                            <Route path="/all-files" element={wrapPageWithAuth(AllFilesViewPage)} />
                            <Route path="/documents" element={wrapPageWithAuth(DocumentsPage)} />
                            {/* disputing demo pages */}
                            <Route path="/demo" element={<Navigate replace to="/disputing-demo/invoices" />} />
                            <Route
                                path="/disputing-demo"
                                element={<Navigate replace to="/disputing-demo/invoices" />}
                            />
                            <Route path="/disputing-demo/attachments" element={<DisputingDemoAttachmentsPage />} />
                            <Route path="/disputing-demo/invoices" element={<DisputingDemoInvoicesPage />} />
                            {/* This should stay on the bottom of the Routes list to match anything else */}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>

                        <Toasts />
                    </ToastsContext.Provider>
                </UrlParams>
            </BrowserRouter>
        </SupplypikeWrapper>
    );
}
