import { spColors } from '@casestack/style-themes';
import React, { ReactElement } from 'react';

interface OwnProps {
    href: string;
    children: React.ReactNode;
}

/**
 * Just a styled `<a href="...">` link, which stays blue and opens the link in a new tab.
 */
export default function Link(props: OwnProps): ReactElement {
    return (
        <a href={props.href} target="_blank" rel="noopener noreferrer" style={{ color: spColors.all.blue700 }}>
            {props.children}
        </a>
    );
}
