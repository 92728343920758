import * as React from 'react';

import { spColors } from '@casestack/style-themes';

import { DateSelectionOption, getClosestDateOption, getDateSelectionPage } from '../logic/date-selection';
import { maxDate, minDate } from '../logic/date-utils';
import { createTimeRange, TimeRange } from '../logic/time-range';
import { DateSelectionTimeScale } from '../logic/time-scale';
import { DateOptionComponent } from './date-option';
import { DatePageNav } from './date-page-nav';
import { DateSubPageLabel } from './date-sub-page-label';
import { DayOfWeekLabels } from './day-of-week-labels';

export interface CalendarProps {
    timeScale: DateSelectionTimeScale;
    onChange: (start: Date | undefined, end: Date | undefined) => void;
    onConsideredChange?: (start: Date | undefined, end: Date | undefined) => void;
    start?: Date;
    end?: Date;
    /** Defaults to `'empty range'` */
    noSelectImplies?: 'empty range' | 'unbounded range';
    /**
     * Defaults to `'atomic selection'`.
     * By atomic we mean minimum-size, based on `timeScale.selectionTimeScale`.
     */
    singleSelectImplies?: 'atomic selection' | 'unbounded start' | 'unbounded end';
}

export function Calendar(props: CalendarProps): JSX.Element {
    const startSelection = props.start
        ? getClosestDateOption(props.timeScale, props.start, 'backwards', 'start')
        : undefined;
    const endSelection = props.end ? getClosestDateOption(props.timeScale, props.end, 'forwards', 'end') : undefined;
    const [pageIndex, setPageIndex] = React.useState(0);
    const [page0Date] = React.useState(props.start ?? new Date());
    const [selection1, setSelection1] = React.useState<DateSelectionOption | undefined>(startSelection);
    const [selection2, setSelection2] = React.useState<DateSelectionOption | undefined>(endSelection);

    /*
     * Whatever the user mouses over or clicks on.
     * Used to update either selection1 or selection2, when clicked.
     */
    const [consideredOption, setConsideredOption] = React.useState<DateSelectionOption | undefined>(undefined);
    const [consideredRange, setConsideredRange] = React.useState<TimeRange | undefined>(undefined);
    const [hasUnsavedConsideredRange, setHasUnsavedConsideredRange] = React.useState(false);
    /*
     * Is the user mousing over a possible selection, or have they actually clicked it?
     */
    const [isConsideredOptionConfirmed, setIsConsideredOptionConfirmed] = React.useState(false);

    const [selectedRange, setSelectedRange] = React.useState<TimeRange | undefined>(undefined);
    const [hasLoadedSelectionFromProps, setHasLoadedSelectionFromProps] = React.useState(true);
    const [hasUnsavedSelectedRange, setHasUnsavedSelectedRange] = React.useState(false);

    /**
     * Decide whether `selection1` or `selection2` should be replaced by `consideredOption`.
     */
    function getSelectionToReplace(
        selection: DateSelectionOption,
        selection1: DateSelectionOption | undefined,
        selection2: DateSelectionOption | undefined
    ): 'selection1' | 'selection2' {
        if (selection1 !== undefined && selection2 !== undefined) {
            const diff1 = Math.abs(selection.id - selection1.id);
            const diff2 = Math.abs(selection.id - selection2.id);
            if (diff1 === 0) {
                return 'selection2';
            } else if (diff2 === 0) {
                return 'selection1';
            } else if (diff1 <= diff2) {
                return 'selection1';
            } else {
                return 'selection2';
            }
        } else if (selection1 === undefined) {
            return 'selection1';
        } else {
            return 'selection2';
        }
    }

    /**
     * Use two selected options ( or _considered_ options ) to define a `TimeRange`.
     */
    function getTimeRange(
        timeScale: DateSelectionTimeScale,
        endpoint1: DateSelectionOption | undefined,
        endpoint2: DateSelectionOption | undefined,
        noSelectImplies: 'empty range' | 'unbounded range' | undefined,
        singleSelectImplies: 'atomic selection' | 'unbounded start' | 'unbounded end' | undefined
    ): TimeRange | undefined {
        const minSelection = getClosestDateOption(timeScale, minDate, 'forwards', 'start');
        const maxSelection = getClosestDateOption(timeScale, maxDate, 'forwards', 'start');
        const singleSelection = endpoint1 ?? endpoint2;
        // double selection
        if (endpoint1 !== undefined && endpoint2 !== undefined) {
            return createTimeRange(timeScale, endpoint1, endpoint2);
        }
        // single selection
        else if (singleSelection !== undefined) {
            if (singleSelectImplies === 'unbounded start') {
                return createTimeRange(timeScale, minSelection, singleSelection);
            } else if (singleSelectImplies === 'unbounded end') {
                return createTimeRange(timeScale, singleSelection, maxSelection);
            } else {
                // atomic selection
                return createTimeRange(timeScale, singleSelection, singleSelection);
            }
        }
        // no selection
        else {
            if (noSelectImplies === 'unbounded range') {
                return createTimeRange(timeScale, minSelection, maxSelection);
            } else {
                // empty range
                return undefined;
            }
        }
    }

    /**
     * Update state if/when props change
     */
    const { timeScale, start, end } = props;
    React.useEffect(() => {
        const startSelection = start ? getClosestDateOption(timeScale, start, 'backwards', 'start') : undefined;
        const endSelection = end ? getClosestDateOption(timeScale, end, 'forwards', 'end') : undefined;
        setHasLoadedSelectionFromProps(true);
        setSelection1(startSelection);
        setSelection2(endSelection);
    }, [timeScale, start, end]);

    /*
     * Use `consideredOption`, `selection1`, and `selection2` to define a hovered range.
     */
    const { noSelectImplies, singleSelectImplies } = props;
    React.useEffect(() => {
        if (consideredOption !== undefined) {
            const selectionToReplace = getSelectionToReplace(consideredOption, selection1, selection2);
            const considered1 = selectionToReplace === 'selection1' ? consideredOption : selection1;
            const considered2 = selectionToReplace === 'selection2' ? consideredOption : selection2;
            setConsideredRange(getTimeRange(timeScale, considered1, considered2, noSelectImplies, singleSelectImplies));
        } else {
            setConsideredRange(selectedRange);
        }
        setHasUnsavedConsideredRange(true);
    }, [
        timeScale,
        noSelectImplies,
        singleSelectImplies,
        consideredOption,
        selection1,
        selection2,
        selectedRange,
        setSelectedRange,
    ]);

    /*
     * Use `selection` to update either `selection1` or `selection2`.
     * After the first two selections, any additional selections will
     * update whichever endpoint is nearest.
     * Instead, we might want a way for the user to explicitly select start and end.
     */
    React.useEffect(() => {
        if (isConsideredOptionConfirmed && consideredOption !== undefined) {
            if (getSelectionToReplace(consideredOption, selection1, selection2) === 'selection1') {
                setSelection1(consideredOption);
            } else {
                setSelection2(consideredOption);
            }
            setIsConsideredOptionConfirmed(false);
        }
    }, [isConsideredOptionConfirmed, consideredOption, selection1, selection2]);

    /*
     * Use `selection1` and `selection2` to define a selected range.
     */
    React.useEffect(() => {
        const minSelection = getClosestDateOption(timeScale, minDate, 'forwards', 'start');
        const maxSelection = getClosestDateOption(timeScale, maxDate, 'forwards', 'start');
        const singleSelection = selection1 ?? selection2;
        // double selection
        if (selection1 !== undefined && selection2 !== undefined) {
            setSelectedRange(createTimeRange(timeScale, selection1, selection2));
        }
        // single selection
        else if (singleSelection !== undefined) {
            if (singleSelectImplies === 'unbounded start') {
                setSelectedRange(createTimeRange(timeScale, minSelection, singleSelection));
            } else if (singleSelectImplies === 'unbounded end') {
                setSelectedRange(createTimeRange(timeScale, singleSelection, maxSelection));
            } else {
                // atomic selection
                setSelectedRange(createTimeRange(timeScale, singleSelection, singleSelection));
            }
        }
        // no selection
        else {
            if (noSelectImplies === 'unbounded range') {
                setSelectedRange(createTimeRange(timeScale, minSelection, maxSelection));
            } else {
                // empty range
                setSelectedRange(undefined);
            }
        }
        if (!hasLoadedSelectionFromProps) {
            setHasUnsavedSelectedRange(true);
        } else {
            setHasLoadedSelectionFromProps(false);
        }
    }, [
        hasLoadedSelectionFromProps,
        timeScale,
        noSelectImplies,
        singleSelectImplies,
        selection1,
        selection2,
        setSelectedRange,
    ]);

    const { onConsideredChange } = props;
    /*
     * Return the new time range to our parent.
     */
    React.useEffect(() => {
        if (onConsideredChange) {
            if (hasUnsavedConsideredRange && consideredRange !== undefined) {
                // For display purposes, we use distant dates to represent unbounded dates.
                // However, we explicitly return unbounded dates as `undefined`.
                const start =
                    consideredRange.start.startDate.getUTCFullYear() !== minDate.getUTCFullYear()
                        ? consideredRange.start.startDate
                        : undefined;
                const end =
                    consideredRange.end.endDate.getUTCFullYear() !== maxDate.getUTCFullYear()
                        ? consideredRange.end.endDate
                        : undefined;
                onConsideredChange(start, end);
            }
        }
        setHasUnsavedConsideredRange(false);
    }, [hasUnsavedConsideredRange, consideredRange, onConsideredChange]);

    const { onChange } = props;
    /*
     * Return the new time range to our parent.
     */
    React.useEffect(() => {
        if (hasUnsavedSelectedRange && selectedRange !== undefined) {
            // For display purposes, we use distant dates to represent unbounded dates.
            // However, we explicitly return unbounded dates as `undefined`.
            const start =
                selectedRange.start.startDate.getUTCFullYear() !== minDate.getUTCFullYear()
                    ? selectedRange.start.startDate
                    : undefined;
            const end =
                selectedRange.end.endDate.getUTCFullYear() !== maxDate.getUTCFullYear()
                    ? selectedRange.end.endDate
                    : undefined;
            onChange(start, end);
        }
        setHasUnsavedSelectedRange(false);
    }, [hasUnsavedSelectedRange, selectedRange, onChange]);

    const page = getDateSelectionPage(page0Date, props.timeScale, pageIndex);
    const now = new Date();
    const nowOption = getClosestDateOption(props.timeScale, now, 'backwards', 'start');

    function optionIsInRange(option: DateSelectionOption, range: TimeRange | undefined): boolean {
        if (range === undefined) {
            return false;
        } else {
            const isInRange = option.startDate >= range.start.startDate && option.endDate <= range.end.endDate;
            return isInRange;
        }
    }

    const pageFlexDirection = getPageFlexDirection(props.timeScale);
    const subPageFlexDirection = getSubPageFlexDirection(props.timeScale);

    /**
     * Manage backgroundColor and margin/padding based on
     * where this `DateSelectionOption` is, relative to the `selectedRange`.
     */
    function getOptionStyle(option: DateSelectionOption, optionIndexInSubPage: number, numOptionsInSubPage: number) {
        const verticalSpacing = 8;
        const horizontalSpacing = 16;
        const inRangeStyle: React.CSSProperties = {
            background: spColors.textThemes.light.background.blue100,
        };
        const isConsideringDoubleSelect = consideredRange && consideredRange.start.id === consideredRange.end.id;
        const range = isConsideringDoubleSelect ? selectedRange : consideredRange;
        const isStartOption = range !== undefined && option.id === range.start.id;
        const isEndOption = range !== undefined && option.id === range.end.id;
        const style = {
            marginTop: verticalSpacing / 2,
            marginBottom: verticalSpacing / 2,
            marginLeft: horizontalSpacing / 2,
            marginRight: horizontalSpacing / 2,
            ...(optionIsInRange(option, range) ? inRangeStyle : {}),
        };
        // replace some margins with padding so that background color changes.
        switch (subPageFlexDirection) {
            case 'row': {
                if (isStartOption && isEndOption) {
                    style.background = 'inherit';
                } else {
                    if (isStartOption) {
                        style.background = `linear-gradient(90deg, #FFFFFF 50%, ${spColors.textThemes.light.background.blue100} 50%)`;
                    } else if (optionIndexInSubPage !== 0) {
                        style.marginLeft = 0;
                        style.paddingLeft = horizontalSpacing / 2;
                    }
                    if (isEndOption) {
                        style.background = `linear-gradient(90deg, ${spColors.textThemes.light.background.blue100} 50%, #FFFFFF 50%)`;
                    } else if (optionIndexInSubPage !== numOptionsInSubPage - 1) {
                        style.marginRight = 0;
                        style.paddingRight = horizontalSpacing / 2;
                    }
                }
                return style;
            }
            case 'column': {
                if (isStartOption && isEndOption) {
                    style.background = 'inherit';
                } else {
                    if (isStartOption) {
                        style.background = `linear-gradient(180deg, #FFFFFF 50%, ${spColors.textThemes.light.background.blue100} 50%)`;
                    } else if (optionIndexInSubPage !== 0) {
                        style.marginTop = 0;
                        style.paddingTop = verticalSpacing / 2;
                    }
                    if (isEndOption) {
                        style.background = `linear-gradient(180deg, ${spColors.textThemes.light.background.blue100} 50%, #FFFFFF 50%)`;
                    } else if (optionIndexInSubPage !== numOptionsInSubPage - 1 && !isEndOption) {
                        style.marginBottom = 0;
                        style.paddingBottom = verticalSpacing / 2;
                    }
                }
                return style;
            }
        }
    }

    return (
        <div>
            <DatePageNav
                timeScale={props.timeScale}
                pageIndex={pageIndex}
                pageLabel={page.label}
                setPageIndex={pageIndex => setPageIndex(pageIndex)}
            ></DatePageNav>
            {props.timeScale.subPageTimeScale === 'week' || props.timeScale.subPageTimeScale === 'wmWeek' ? (
                <DayOfWeekLabels timeScale={props.timeScale} />
            ) : (
                <></>
            )}
            <div style={{ display: 'flex', flexDirection: pageFlexDirection }}>
                {page.subPages.map((subPage, i) => (
                    <div
                        key={i}
                        style={{
                            display: 'flex',
                            flexDirection: subPageFlexDirection,
                        }}
                    >
                        {props.timeScale.subPageTimeScale === 'month' ||
                        props.timeScale.subPageTimeScale === 'wmMonth' ? (
                            <DateSubPageLabel timeScale={props.timeScale} label={subPage.label} />
                        ) : (
                            <></>
                        )}
                        {subPage.options.map((option, j) => (
                            <div key={j} style={getOptionStyle(option, j, subPage.options.length)}>
                                <DateOptionComponent
                                    option={option}
                                    isSelected={option.id === selection1?.id || option.id === selection2?.id}
                                    isDoubleSelected={option.id === selection1?.id && option.id === selection2?.id}
                                    isNow={option.id === nowOption.id}
                                    onClick={selection => {
                                        setConsideredOption(selection);
                                        setIsConsideredOptionConfirmed(true);
                                    }}
                                    onMouseEnter={hoveredOption => {
                                        setConsideredOption(hoveredOption);
                                    }}
                                    onMouseLeave={hoveredOption => {
                                        if (consideredOption?.id === hoveredOption.id) {
                                            setConsideredOption(undefined);
                                        }
                                    }}
                                ></DateOptionComponent>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

function getPageFlexDirection(timeScale: DateSelectionTimeScale): 'row' | 'column' {
    switch (timeScale.paginationTimeScale) {
        case 'year':
        case 'wmYear':
            return 'row';
        default:
            return 'column';
    }
}

function getSubPageFlexDirection(timeScale: DateSelectionTimeScale): 'row' | 'column' {
    switch (timeScale.paginationTimeScale) {
        case 'year':
        case 'wmYear':
            return 'column';
        default:
            return 'row';
    }
}
