import { FC } from 'react';
import { format } from 'date-fns';

const DateCell: FC<{ date: Date; useTwoYearDate?: boolean; includeTimestamp?: boolean }> = ({
    date,
    useTwoYearDate,
    includeTimestamp,
}) => (
    <div>
        <div>{useTwoYearDate ? format(date, 'MM/dd/yy') : date.toLocaleDateString('en-US')}</div>
        {includeTimestamp && <div>{date.toLocaleTimeString('en-US')}</div>}
    </div>
);

export default DateCell;
