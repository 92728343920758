import { useCallback, useEffect } from 'react';
import { ToastsContextValue } from './contexts/toasts-context';
import { InternalError, SentryLogger } from './third-party/sentry-logger';

export const usePromiseRejectionHandler = (toasts: ToastsContextValue): void => {
    const callback = useCallback((event: PromiseRejectionEvent) => {
        event.preventDefault();
        const { reason } = event;
        if (reason instanceof InternalError) {
            SentryLogger.captureException(reason, { extra: reason.extra });
        } else if (reason instanceof Error) {
            SentryLogger.captureException(reason);
        } else {
            SentryLogger.captureMessage(reason.message ?? 'unhandled promise rejection', { extra: { reason } });
        }
        toasts.add({
            key: 'unhandled-rejection-error',
            color: 'red',
            title: 'Oh no, something went wrong!',
            body: 'Please try again in a few minutes.',
        });
        // exhaustive-deps disabled because we don't want this to run when toasts change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('unhandledrejection', callback);
        return () => window.removeEventListener('unhandledrejection', callback);
    }, [callback]);
};
