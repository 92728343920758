import { FC, ReactElement } from 'react';

import { spIcons } from '@casestack/supplypike-ui';

import { DocumentRequestFailedSubstatus, DocumentRequestPendingSubstatus } from '../../../api/types';
import { DocumentRequest } from '../../../types';

const StatusCell: FC<{ data: DocumentRequest }> = ({ data }) => {
    const getPair = (purchaseOrderNumber: DocumentRequest): { text: string; icon?: ReactElement } => {
        switch (purchaseOrderNumber.status) {
            case 'pending':
                return {
                    text:
                        purchaseOrderNumber.substatus === DocumentRequestPendingSubstatus.LOCATED
                            ? 'File Unavailable'
                            : 'Pending',
                    icon: <spIcons.small.pending />,
                };
            case 'failed':
                return {
                    text:
                        purchaseOrderNumber.substatus === DocumentRequestFailedSubstatus.LOCATED
                            ? 'File Unavailable'
                            : purchaseOrderNumber.substatus === DocumentRequestFailedSubstatus.CANCELLED
                            ? 'Cancelled'
                            : 'Not Found',
                    icon:
                        purchaseOrderNumber.substatus === DocumentRequestFailedSubstatus.CANCELLED ? (
                            <spIcons.small.cancelled />
                        ) : (
                            <spIcons.small.warning />
                        ),
                };
            case 'finished':
                return { text: 'Success', icon: <spIcons.small.check /> };
            default:
                return { text: 'Unknown' };
        }
    };

    const pair = getPair(data);
    return (
        <div className="flex justify-end items-center">
            <div>{pair.text}</div>
            <div className="ml-2">{pair.icon}</div>
        </div>
    );
};

export default StatusCell;
