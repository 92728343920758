import { Supplier, User } from '../types';
import { Segment } from './segment';
import { SentryLogger } from './sentry-logger';

const APP_PREFIX = '[de]';

export const initLogger = (): void => {
    SentryLogger.initialize();
};

export const initTracker = (): void => {
    Segment.initialize();
};

/**
 * Provide user metadata to third-party tools.
 * Currently, we use Segment and Sentry.
 */
export function thirdPartyIdentify(user: User, supplier?: Supplier): void {
    Segment.identify(user, supplier);
    if (user.organizationId) {
        Segment.group(user, supplier);
    }
    SentryLogger.setUserContext(user);
}

/** Logs page navigation, based on current URL. */
export function thirdPartyTrackPage(properties?: Record<string, unknown>): void {
    Segment.trackPage(properties);
}

/** Event name also serves as an ID */
export function thirdPartyTrackEvent(eventName: string, properties?: Record<string, unknown>): void {
    Segment.trackEvent(`${APP_PREFIX} ${eventName}`, properties);
}
