import { FC } from 'react';
import { TooltipWrapper } from '../../common/tooltip-wrapper';
import { spColors } from '@casestack/style-themes';

function OverflowBubbleTooltip(props: { title: string; items: string[] }) {
    return (
        <div style={{ margin: '8px' }}>
            <p className="sp-label" style={{ marginBottom: '8px' }}>
                {props.title}
            </p>
            {props.items.map(item => (
                <p className="sp-body" key={item}>
                    {item}
                </p>
            ))}
        </div>
    );
}

const ListCell: FC<{ list: string[]; verb: string; title: string }> = ({ list, verb, title }) => {
    const sortedList = list.sort();
    return (
        <>
            {list.length === 0 && <p>--</p>}
            {list.length <= 3 && list.length > 0 && (
                <div className="vertical-clamped-box">
                    <p>
                        {sortedList.map((ele, index) => (
                            <>
                                {ele}
                                {index < list.length - 1 && <br />}
                            </>
                        ))}
                    </p>
                </div>
            )}
            {list.length > 3 && (
                <TooltipWrapper
                    content={OverflowBubbleTooltip({ title, items: sortedList })}
                    anchor="top"
                    tooltipOffset={16}
                    useLight={true}
                >
                    <div
                        className="overflow-bubble"
                        style={{
                            borderWidth: '1px',
                            borderColor: spColors.all.neutral200,
                            borderRadius: '20px',
                            padding: '8px',
                        }}
                    >
                        {list.length} {verb}
                    </div>
                </TooltipWrapper>
            )}
        </>
    );
};

export default ListCell;
