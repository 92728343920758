import { ReactElement, useState } from 'react';
import { ApiClient } from '../api/types';
import Header from '../components/layout/header';
import { thirdPartyIdentify } from '../third-party';
import { Supplier, User } from '../types';
import { useActiveSupplier, useChangeActiveSupplier, useSuppliers, useUser } from '@casestack/supplypike-wrapper';
import { getApiService } from '../api/common';

export interface AuthenticatedProps {
    apiClient: ApiClient;
    supplier: Supplier | null;
    suppliers: Supplier[] | null;
    user: User | null;
}

interface OwnProps {
    component: (innerProps: AuthenticatedProps) => ReactElement;
}

export default function PageWithAuth(props: OwnProps): ReactElement {
    const [isIdentified, setIsIdentified] = useState(false);
    const user = useUser() ?? null;
    const isAdmin = user?.roles?.some(r => r === 'supplypike.support');

    const suppliers = useSuppliers() as Supplier[];
    const activeSupplier = useActiveSupplier() as Supplier;
    const changeSupplier = useChangeActiveSupplier();

    const retailerName = activeSupplier?.retailerName ?? 'Walmart';

    if (!isIdentified) {
        if (user) {
            if (activeSupplier) {
                thirdPartyIdentify(user, activeSupplier);
            } else {
                thirdPartyIdentify(user);
            }
            setIsIdentified(true);
        }
    }
    return (
        <div className="app-container">
            <Header
                suppliers={suppliers}
                supplier={activeSupplier}
                setSupplier={sup => sup && changeSupplier(sup.id)}
                retailerName={retailerName}
                isAdmin={isAdmin}
                // We hide the single supplier selector if we are currently on a multi-supplier page. This is only
                // necessary while we are supporting both single and multi supplier views.
                onMultiSupplierPage={!window.location.href.includes('/documents')}
            />
            <main className="app-main">
                <props.component
                    suppliers={suppliers}
                    apiClient={getApiService()}
                    supplier={activeSupplier}
                    user={user}
                />
            </main>
        </div>
    );
}
