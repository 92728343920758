import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import './modal.css';

interface OwnProps {
    visible?: boolean;
    onRequestClose: () => void;
    children: ReactNode;
    widthClass?: string;
    /** Whether or not to trigger 'onRequestClose' when a user clicks outside of the modal */
    closeOnOutsideClick?: boolean;
}

/**
 * A generic modal component. Maybe later we can make one that has the
 * title and footer integrated, but for now we can leave that dressing
 * to the user.
 * @todo Improve a11y.
 */
export default function Modal(props: OwnProps): null | ReactElement {
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const bodyStyle = document.body.style;
        if (props.visible) {
            overlayRef.current?.focus();
            bodyStyle.overflow = 'hidden';
        } else {
            bodyStyle.overflow = 'initial';
        }

        return () => {
            bodyStyle.overflow = 'initial';
        };
    }, [props.visible]);

    function handleClick(event: React.MouseEvent) {
        if (props.closeOnOutsideClick && event.target === overlayRef.current) {
            props.onRequestClose();
        }
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape' && props.visible) {
            props.onRequestClose();
            event.stopPropagation();
        }
    }

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return !props.visible ? null : (
        <div
            className="modal-overlay"
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            ref={overlayRef}
        >
            <section className={`modal ${props.widthClass ?? 'wider'}`} role="document">
                {props.children}
            </section>
        </div>
    );
    /* eslint-enable */
}
