import { ReactElement, useContext } from 'react';

import { spIcons } from '@casestack/supplypike-ui';

import { ApiClient } from '../../api/types';
import { FileAndDocument, DocumentRequest, Supplier } from '../../types';
import CloseButton from './close-button';
import Modal from './modal';
import Placeholder, { roundedStyle } from './placeholder';
import { thirdPartyTrackEvent } from '../../third-party';
import ToastsContext from '../../contexts/toasts-context';
import { downloadFile, viewFile } from '../../util/file-utils';

interface OwnProps {
    apiClient: ApiClient;
    supplier: Supplier;
    documentRequest: DocumentRequest;
    onRequestClose: () => void;
}

function FileListGroupItem(props: {
    apiClient: ApiClient;
    file: FileAndDocument;
    purchaseOrderNumber: DocumentRequest;
    supplier: Supplier;
}): ReactElement {
    function handleClickView() {
        thirdPartyTrackEvent('Document viewed', {
            type: props.file.kind,
            purchaseOrderNumber: props.purchaseOrderNumber.purchaseOrderNumber,
            file: props.file,
        });
        props.apiClient
            .getFileContentFromDocumentId(
                props.supplier.id,
                props.file.id,
                props.purchaseOrderNumber.purchaseOrderNumber
            )
            .then(({ blob }) => viewFile(blob));
    }

    function handleClickDownload() {
        props.apiClient
            .getFileContentFromDocumentId(
                props.supplier.id,
                props.file.id,
                props.purchaseOrderNumber.purchaseOrderNumber
            )
            .then(({ filename, blob }) => {
                thirdPartyTrackEvent('Document downloaded', {
                    type: props.file.kind,
                    purchaseOrderNumber: props.purchaseOrderNumber.purchaseOrderNumber,
                    file: props.file,
                });
                downloadFile(filename, blob);
            });
    }

    return (
        <li className="list-group-item flex justify-between items-center">
            <div>
                <div className="sp-body">{props.file.name}</div>
                <div className="sp-caption text-neutral-700">Type: {props.file.kind.toUpperCase()}</div>
                {props.file.source && <div className="sp-caption text-neutral-700">Source: {props.file.source}</div>}
                <div className="sp-caption text-neutral-700">
                    Scrape date: {props.file.updatedAt.toLocaleDateString('en-US')}
                </div>
            </div>
            <div className="flex">
                <button type="button" className="sp-base-button blue text" onClick={handleClickView}>
                    <spIcons.normal.view />
                    <span>View</span>
                </button>
                <button type="button" className="ml-2 sp-base-button blue text" onClick={handleClickDownload}>
                    <spIcons.normal.download />
                    <span>Download</span>
                </button>
            </div>
        </li>
    );
}

function PlaceholderFileListGroupItem(): ReactElement {
    return (
        <li className="list-group-item flex justify-between items-center">
            <div>
                <Placeholder className="mt-2" style={roundedStyle('20ch', 14)} />
                <Placeholder className="mt-2" style={roundedStyle('12ch', 12)} />
                <Placeholder className="mt-1" style={roundedStyle('12ch', 12)} />
                <Placeholder className="mt-1" style={roundedStyle('18ch', 12)} />
            </div>
            <div className="flex">
                <Placeholder style={roundedStyle(100, 40)} />
                <Placeholder className="ml-2" style={roundedStyle(140, 40)} />
            </div>
        </li>
    );
}

export default function DocumentRequestModal(props: OwnProps): ReactElement {
    const toasts = useContext(ToastsContext);

    const pon = props.documentRequest;
    const subtitle = [
        pon.destinationZip && `Destination ZIP Code: ${pon.destinationZip}`,
        pon.trackingNumber && `Tracking Number: ${pon.trackingNumber}`,
    ]
        .filter(s => s)
        .join(', ');

    async function downloadAllFiles() {
        const documentIds = props.documentRequest.files.map(f => f.id);
        try {
            const data = await props.apiClient.getZipOfFilesFromDocumentIds(props.supplier.id, documentIds);
            downloadFile(`${props.supplier.name} - PO # ${props.documentRequest.purchaseOrderNumber}.zip`, data);
        } catch (err) {
            toasts.add({
                key: 'download-files',
                title: 'Error',
                body: 'There was an error downloading the files. Please try again later.',
            });
        }
    }

    const hasFiles = pon && pon.files.length > 0;

    return (
        <Modal visible={true} onRequestClose={props.onRequestClose}>
            <div className="modal-close">
                <CloseButton onClick={props.onRequestClose} />
            </div>
            <header className="modal-header">
                <h2 className="modal-title sp-h2">
                    {props.supplier.name}: PO # {pon.purchaseOrderNumber}
                </h2>
                {subtitle && <h3 className="sp-subtitle">{subtitle}</h3>}
            </header>
            <div className="modal-body h-96">
                <div className="flex justify-between">
                    <div className="sp-subtitle">Files for this PO</div>
                    {!pon && <div className="sp-loading-spinner small ml-2" />}
                    <button disabled={!hasFiles} onClick={downloadAllFiles} className="sp-base-button blue solid">
                        Download All Files
                    </button>
                </div>
                <ul className="list-group mt-2">
                    {pon ? (
                        hasFiles ? (
                            pon.files.map(file => (
                                <FileListGroupItem
                                    key={file.id}
                                    apiClient={props.apiClient}
                                    file={file}
                                    purchaseOrderNumber={pon}
                                    supplier={props.supplier}
                                />
                            ))
                        ) : (
                            <p> No files found for this document </p>
                        )
                    ) : (
                        <>
                            <PlaceholderFileListGroupItem />
                            <PlaceholderFileListGroupItem />
                        </>
                    )}
                </ul>
            </div>
            <footer className="modal-footer flex justify-end">
                <button
                    type="button"
                    className="sp-base-button neutral outline-button"
                    onClick={() => props.onRequestClose()}
                >
                    Close
                </button>
            </footer>
        </Modal>
    );
}
