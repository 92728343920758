import { RefObject, useEffect } from 'react';

export function useOutsideClick(
    ref: RefObject<HTMLElement> | Array<RefObject<HTMLElement>>,
    handler: (e: MouseEvent | TouchEvent) => void
) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const eles = Array.isArray(ref) ? ref.map(r => r.current) : [ref.current];

            const { target } = event;

            if (!target) {
                return;
            }

            const hit = eles.some(ele => {
                return ele && ele.contains(target as Node);
            });

            if (hit) {
                return;
            }

            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
