export function downloadFile(filename: string, blob: Blob) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', filename);
    a.click();
}

export function viewFile(blob: Blob) {
    window.open(URL.createObjectURL(blob), '_blank');
}
