import { ModalTableColumn, CellValue } from './types';
import { ReactElement } from 'react';
import { TooltipWrapper } from '../tooltip-wrapper';
import { spIcons } from '@casestack/supplypike-ui';
import ModalInputCell from './modal-input-table-cell';
import { Integration } from '../../../types';
import { ValidationError } from '../../../api/types';
import { uniqueId } from 'lodash';

interface OwnProps<T extends Record<string, CellValue>> {
    carrierIntegrations?: Integration[];
    requiredColumns: ModalTableColumn<T>[];
    optionalColumns: ModalTableColumn<T>[];
    row: T;
    onChange: (row: T) => void;
    onClickDelete?: () => void;
    onPaste?: (data: string, index: number) => void;
    className?: string;
    disabled?: boolean;
    rowIndex: number;
}

const tdTextInputStyles = 'tight';
const divTextInputStyles = 'flex items-center';

/** Max length for a text input if the column does not specify a max length */
const textInputDefaultMaxLength = 100;

function findValidationErrors<T extends Record<string, CellValue>>(props: OwnProps<T>) {
    const errors: ValidationError<T>[] = [];
    const allCols = props.requiredColumns.concat(props.optionalColumns);
    for (const { columnName } of allCols) {
        const err = findValidationError(
            props.row.validationErrors as ValidationError<Record<string, CellValue>>[],
            columnName as string
        );
        if (err) {
            errors.push(err);
        }
    }
    return errors;
}

function findValidationError<T extends Record<string, CellValue>>(
    validationErrors?: ValidationError<T>[],
    field?: string
): ValidationError<T> | undefined {
    return validationErrors && validationErrors.find(ve => ve.field === field);
}

export default function ModalInputTableRow<T extends Record<string, CellValue>>(props: OwnProps<T>): ReactElement {
    /** Represents if the row has ANY errors */
    const rowValidationError = findValidationError<T>(
        props.row.validationErrors as ValidationError<Record<string, CellValue>>[]
    );

    /** Represents errors for specific fields inside the row */
    const fieldValidationErrors = findValidationErrors<T>(props);

    function getSharedInputCellProps(col: ModalTableColumn<T>) {
        return {
            row: props.row,
            field: col.columnName,
            label: col.title,
            inputType: col.inputType,
            maxLength: col.maxLength ?? textInputDefaultMaxLength,
            onChange: props.onChange,
            onPaste: props.onPaste,
            disabled: props.disabled,
            placeholder: col.placeholder,
            validationError: fieldValidationErrors.find(x => x.field === col.columnName),
            carrierIntegrations: props.carrierIntegrations,
            rowIndex: props.rowIndex,
        };
    }

    return (
        <tr className={props.className}>
            {props.requiredColumns.map((col, index) => {
                return (
                    <td className="tight" key={uniqueId('requiredCol-')}>
                        <div className="flex items-center">
                            {rowValidationError && index === 0 && (
                                <TooltipWrapper
                                    content={
                                        <div>
                                            <div className="sp-subtitle">Row invalid</div>
                                            <div className="sp-caption">{rowValidationError.message}</div>
                                        </div>
                                    }
                                >
                                    <spIcons.normal.exclamationPoint color="red" />
                                </TooltipWrapper>
                            )}
                            <ModalInputCell {...getSharedInputCellProps(col)} />
                        </div>
                    </td>
                );
            })}
            {props.optionalColumns.map(col => {
                return (
                    <td className={col.inputType === 'text' ? tdTextInputStyles : ''} key={uniqueId('optionalCol-')}>
                        <div className={col.inputType === 'text' ? divTextInputStyles : ''}>
                            <ModalInputCell {...getSharedInputCellProps(col)} />
                        </div>
                    </td>
                );
            })}
            <td className="tight">
                {props.onClickDelete && (
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="sp-icon-button neutral"
                            onClick={() => props.onClickDelete && props.onClickDelete()}
                            aria-label="Delete Row"
                            disabled={props.disabled}
                        >
                            <spIcons.normal.trash />
                        </button>
                    </div>
                )}
            </td>
        </tr>
    );
}
