import { ReactElement, ReactNode, useRef } from 'react';
import CloseButton from './close-button';

import './confirmation.css';

interface OwnProps {
    onConfirm: () => void;
    confirmText: string;
    onRequestClose: () => void;
    children: ReactNode;
}

export default function Confirmation(props: OwnProps): ReactElement {
    const containerRef = useRef<HTMLDivElement>(null);

    function handleClick(event: React.MouseEvent) {
        if (event.target === containerRef.current) {
            props.onRequestClose();
        }
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape') {
            props.onRequestClose();
            event.stopPropagation();
        }
    }

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/no-autofocus */
    return (
        <div
            className="confirmation-container"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            ref={containerRef}
        >
            <div className="confirmation">
                <div className="confirmation-close">
                    <CloseButton onClick={() => props.onRequestClose()} />
                </div>
                <div className="confirmation-content">{props.children}</div>
                <div className="confirmation-options">
                    <button
                        type="button"
                        className="sp-base-button text neutral"
                        onClick={() => props.onRequestClose()}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        className="sp-base-button solid red"
                        autoFocus
                        onClick={() => props.onConfirm()}
                        onKeyDown={handleKeyDown}
                    >
                        {props.confirmText}
                    </button>
                </div>
            </div>
        </div>
    );
    /* eslint-enable */
}
