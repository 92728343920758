import { spTypography } from '@casestack/style-themes';
import { spIcons } from '@casestack/supplypike-ui';

import { DateSelectionTimeScale } from '../logic/time-scale';

export interface DatePageNavProps {
    timeScale: DateSelectionTimeScale;
    pageIndex: number;
    pageLabel: string;
    setPageIndex: (pageIndex: number) => void;
}

export function DatePageNav(props: DatePageNavProps): JSX.Element {
    return (
        <div className="flex flex-row items-center justify-between">
            <h3 className={spTypography.h3}>{props.pageLabel}</h3>
            <div className="flex flex-row">
                <button className={'sp-icon-button neutral'} onClick={() => props.setPageIndex(props.pageIndex - 1)}>
                    <spIcons.normal.previousTab />
                </button>
                <button className={'sp-icon-button neutral'} onClick={() => props.setPageIndex(props.pageIndex + 1)}>
                    <spIcons.normal.nextTab />
                </button>
            </div>
        </div>
    );
}
