import React, { ReactElement, useState } from 'react';
import './demo.css';
import DemoInput from './demo-input';
import { spIcons } from '@casestack/supplypike-ui';
import { AttachmentSelectedIcon, MessageIcon, InvoiceIcon, ArrowIcon } from './demo-icons';
import { Link } from 'react-router-dom';

export default function DisputingDemoAttachmentsPage(): ReactElement {
    const [files, setFiles] = useState<File[]>([]);
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFiles(files => files.concat(event.target.files ? event.target.files[0] : []));
    }
    return (
        <>
            <div className="demo-header-box">
                <div className="demo-icon-container">
                    <Link key={'invoices'} to={'/disputing-demo/invoices'}>
                        <InvoiceIcon />
                    </Link>
                    <ArrowIcon />
                    <AttachmentSelectedIcon />
                    <ArrowIcon />
                    <MessageIcon />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '250px',
                        marginLeft: '10px',
                        marginTop: '6px',
                    }}
                >
                    <div className="demo-icon-text-box">
                        <span className="demo-light-text"> Invoices </span>
                    </div>
                    <div className="demo-icon-text-box">
                        <span className="demo-light-text"> Attachments </span>
                    </div>
                    <div className="demo-icon-text-box">
                        <span className="demo-light-text"> Messages </span>
                    </div>
                </div>
            </div>
            <div className="demo-gradient-background" />
            <div className="demo-main-background">
                <div className="demo-row-margin">
                    <div className="demo-rectangle6">
                        <div className="demo-attachment-gray-filler" />
                    </div>
                </div>
                <div className="demo-row-margin">
                    <DemoInput value="Choose a file..." className="demo-file-input" />
                    <div className="demo-button-container">
                        <label htmlFor={'browse-button'}>
                            <div className="demo-browse-button" style={{ cursor: 'pointer' }}>
                                <span className="demo-browse-text">Browse</span>
                            </div>
                        </label>
                        <input type="file" onChange={handleChange} style={{ display: 'none' }} id="browse-button" />
                        <div className="demo-upload-button">
                            <spIcons.normal.previousTab className="demo-arrow-up" />
                            <span className="demo-light-text ">Upload</span>
                        </div>
                    </div>
                </div>
                <div className="demo-row-margin">
                    <div style={{ marginTop: '47px', width: '100px' }}>
                        <span className="demo-text">Files</span>
                        <div className="demo-title-underline-rectangle" />
                    </div>
                </div>

                <div className="demo-line " />
                <div style={{ marginTop: '32px', width: '100px' }} />
                {files.map((file, i) => {
                    return (
                        <>
                            <div style={{ marginTop: '32px', width: '100px' }} />
                            <div className="demo-row-margin" key={i}>
                                <span className="demo-file-text">
                                    <spIcons.normal.document />
                                    {file?.name}
                                </span>
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    );
}
