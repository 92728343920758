import { ReactElement } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import './navbar.css';

const NAVBAR_ITEMS: { label: string; value: string }[] = [
    { label: 'Deduction Files', value: '/purchase-order-numbers' },
    { label: 'All Files', value: '/all-files' },
];

export default function Navbar(): ReactElement {
    const location = useLocation();

    return (
        <nav className="navbar">
            {location.pathname.includes('/purchase-order-numbers')}
            {NAVBAR_ITEMS.map(item => {
                const className = location.pathname.includes(item.value) ? 'navbar-item active' : 'navbar-item';
                return (
                    <Link key={item.value} to={item.value} className={className}>
                        {item.label}
                    </Link>
                );
            })}
        </nav>
    );
}
