import { ReactElement } from 'react';

import './linear-loader.css';

export default function LinearLoader(): ReactElement {
    return (
        <div className="linear-loader">
            <div className="linear-loader-bar" />
        </div>
    );
}
