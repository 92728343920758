import { unparse } from 'papaparse';

/**
 * Based on https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
 */
export function downloadCsv<T extends Record<string, unknown>>(
    fileName: string,
    rows: T[],
    columnWhiteList?: (keyof T)[],
    columnBlackList?: (keyof T)[],
    downloadTransform?: (x: T) => Record<string, unknown>
) {
    if (rows.length === 0) {
        return;
    }
    const firstRow = downloadTransform ? downloadTransform(rows[0]) : rows[0];
    const columnNames = Object.keys(firstRow).filter(
        columnName =>
            (!columnWhiteList || columnWhiteList.includes(columnName)) &&
            (!columnBlackList || !columnBlackList.includes(columnName))
    );
    const rowsToDownload: Record<string, unknown>[] = [];
    for (let i = 0; i < rows.length; i++) {
        const transformedRow = downloadTransform ? downloadTransform(rows[i]) : rows[i];
        rowsToDownload[i] = {};
        for (const columnName of columnNames) {
            rowsToDownload[i][columnName] = transformedRow[columnName];
        }
    }
    const csvString = unparse(rowsToDownload);

    // create a file and put the content, name and type
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // create a ObjectURL in order to download the created file
    const url = window.URL.createObjectURL(blob);

    // create a hidden link and set the href and click it
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
}
