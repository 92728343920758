import { ReactElement } from 'react';
import { Integration } from '../../../types';
import ModalInputTableRow from './modal-input-table-row';
import ModalInputTableHeaders from './modal-input-table-headers';
import { ModalTableColumn, CellValue } from './types';

import './modal-input-table.css';
import { uniqueId } from 'lodash';

interface OwnProps<T extends Record<string, CellValue>> {
    carrierIntegrations?: Integration[];
    requiredColumns: ModalTableColumn<T>[];
    optionalColumns: ModalTableColumn<T>[];
    rows: T[];
    /** map from row index to row data */
    rowsWithValidationErrors: Map<number, T>;
    setRow: (row: T, index: number) => void;
    addRows: (rows: T[], index?: number) => void;
    deleteRow: (index: number) => void;
    handlePaste?: (data: string, index: number) => void;
    loading?: boolean;
    review?: boolean;
    disableInput?: boolean;
}

const INITIAL_ROWS = 5;

function isRowEmpty<T extends Record<string, CellValue>>(row: T): boolean {
    if (!Object.keys(row).length) {
        return true;
    }
    return false;
}

export default function ModalInputTable<T extends Record<string, CellValue>>(props: OwnProps<T>): ReactElement {
    /** Initialize with (INITIAL_ROWS - 1) empty rows, excluding the 1 active row */
    const extraRows = new Array(Math.max(INITIAL_ROWS - props.rows.length, 0)).fill(null);

    /** Props that are the same for every row */
    const sharedRowProps = {
        carrierIntegrations: props.carrierIntegrations,
        requiredColumns: props.requiredColumns,
        optionalColumns: props.optionalColumns,
    };

    return (
        <table className="modal-input-table">
            <ModalInputTableHeaders
                optionalColumns={props.optionalColumns}
                requiredColumns={props.requiredColumns}
                loading={props.loading}
            />

            <tbody className={props.loading || props.disableInput ? 'opacity-50' : undefined}>
                {Array.from(props.rowsWithValidationErrors.entries()).map(([originalRowIndex, row], tableRowIndex) => {
                    const isLast = tableRowIndex === props.rowsWithValidationErrors.size - 1;
                    return (
                        <ModalInputTableRow<T>
                            {...sharedRowProps}
                            key={uniqueId('errorRow-')}
                            row={row}
                            onChange={row => props.setRow(row, originalRowIndex)}
                            onClickDelete={() => props.deleteRow(originalRowIndex)}
                            className={isLast ? 'thick-bottom' : undefined}
                            rowIndex={tableRowIndex}
                            disabled={props.loading || props.disableInput}
                        />
                    );
                })}

                {props.rows.map((row, originalRowIndex) => {
                    return (
                        !props.rowsWithValidationErrors.has(originalRowIndex) && (
                            <ModalInputTableRow<T>
                                {...sharedRowProps}
                                key={uniqueId('row-')}
                                row={row}
                                onPaste={props.handlePaste && originalRowIndex === 0 ? props.handlePaste : undefined}
                                onChange={row => props.setRow(row, originalRowIndex)}
                                onClickDelete={
                                    !props.review &&
                                    (originalRowIndex < props.rows.length - 1 ||
                                        !isRowEmpty(props.rows[originalRowIndex]))
                                        ? () => props.deleteRow(originalRowIndex)
                                        : undefined
                                }
                                rowIndex={originalRowIndex}
                                disabled={props.loading || props.review || props.disableInput}
                            />
                        )
                    );
                })}

                {extraRows.map(() => {
                    return (
                        <tr key={uniqueId('extraRow-')}>
                            {Array.from(props.requiredColumns).map(() => (
                                <td key={uniqueId('extraReqCol-')}>
                                    <div className="h-8 mt-1" />
                                </td>
                            ))}
                            {Array.from(props.optionalColumns).map(() => (
                                <td key={uniqueId('extraOptCol-')} />
                            ))}
                            <td />
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
