import { ModalTableColumn } from '../../../common/modal-input-table/types';
import { PonModalRow } from './types';

export const requiredColumns = [
    {
        columnName: 'purchaseOrderNumber',
        title: 'PO Number',
        size: 'medium',
        placeholder: 'Paste PO numbers here...',
        inputType: 'text',
        maxLength: 20,
    },
] as ModalTableColumn<PonModalRow>[];

export const optionalColumns = [
    {
        columnName: 'destinationZip',
        title: 'Dest. Zip',
        size: 'small',
        inputType: 'text',
        maxLength: 5,
    },
    {
        columnName: 'trackingNumber',
        title: 'Tracking Number',
        size: 'medium',
        inputType: 'text',
    },
    {
        columnName: 'suggestedIntegration',
        title: 'Carrier',
        size: 'medium',
        inputType: 'select',
    },
] as ModalTableColumn<PonModalRow>[];
