export enum Environment {
    LOCAL = 'local',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

export const getEnvironment = (): Environment => {
    const url = new URL(window.location.href);
    switch (url.hostname) {
        case 'localhost':
            return Environment.LOCAL;
        case 'explorer.staging.supplypike.com':
        case 'explorer-staging.supplypike.com':
            return Environment.STAGING;
        case 'explorer.apps.supplypike.com':
        case 'explorer.supplypike.com':
            return Environment.PRODUCTION;
        default:
            throw new Error(`getEnvironment() does not recognize hostname: ${url.hostname}`);
    }
};
