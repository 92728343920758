import { DateSelectionOption } from './date-selection';
import { DateSelectionTimeScale } from './time-scale';

export interface TimeRange {
    timeScale: DateSelectionTimeScale;
    start: DateSelectionOption;
    end: DateSelectionOption;
}

export function createTimeRange(
    timeScale: DateSelectionTimeScale,
    endpoint1: DateSelectionOption,
    endpoint2: DateSelectionOption
): TimeRange {
    if (endpoint1.startDate <= endpoint2.startDate) {
        return {
            timeScale: timeScale,
            start: endpoint1,
            end: endpoint2,
        };
    } else {
        return {
            timeScale: timeScale,
            start: endpoint2,
            end: endpoint1,
        };
    }
}
