import { MAX_MULTI_SEARCH_VALUES } from '../components/common/search';
import { getGlobalCoreInterface } from '@casestack/supplypike-wrapper';
import { InternalError } from '../third-party/sentry-logger';
import { Page, FileAndDocument, DocumentRequest, Supplier, File } from '../types';
import { isNotNullish } from '../util/filter-utils';
import { splitOnWhitespaceAndCommas } from '../util/string-utils';
import {
    ApiClient,
    ApiFileAndDocument,
    ApiDocumentRequest,
    CreateDocumentRequestDto,
    PurchaseOrderNumberValidationDto,
    ValidationError,
    AttributesNotValid,
    AddAttributesRequestRow,
    AttributesValid,
    MultiSupplierGetDocumentRequestParams,
    SingleSupplierGetDocumentRequestParams,
    ApiFile,
} from './types';
import { AllFilesViewFilters } from '../components/pages/all-files-view/types';

const CREATE_FIELDS: Array<keyof CreateDocumentRequestDto> = [
    'purchaseOrderNumber',
    'destinationZip',
    'trackingNumber',
    'suggestedIntegrationId',
];
const FILENAME_REGEXP = /filename="(.*)"/;

/** Types which we support serialializing into a `string` and then deserializing in sdi-api */
type URLSearchParamsSerializableValue = string | string[] | number | boolean;
/** We drop `null` and `undefined` values instead of sending "null" over the network */
type OptionalURLSearchParamsSerializableValue = URLSearchParamsSerializableValue | null | undefined;

export class ApiFetchError extends InternalError {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(public url: string, public init: RequestInit | undefined, public responseJSON: any) {
        super('unable to make api request', {
            url,
            ...init,
            response: responseJSON,
        });
    }
}

export class RealApiClient implements ApiClient {
    private readonly createdBySource = 'document-explorer';

    constructor(private apiBaseUrl: string) {}

    getSuppliers(): Promise<Supplier[]> {
        return this.apiFetchJSON(this.apiUrl('/suppliers'));
    }

    async validateAttributes(
        dtos: AddAttributesRequestRow[],
        supplierIds: string[]
    ): Promise<(AttributesValid | AttributesNotValid)[]> {
        const url = this.apiUrl(`/multi-supplier/add-attributes`, { validate: true });
        const requestBody = { rows: dtos, supplierIds };
        const response = await this.apiFetchJSON<(AttributesValid | AttributesNotValid)[]>(url, {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });
        return response;
    }

    async addAttributes(
        dtos: AddAttributesRequestRow[],
        supplierIds: string[]
    ): Promise<{ success: boolean; purchaseOrderNumber: string; supplierId: string }[]> {
        const url = this.apiUrl(`/multi-supplier/add-attributes`);
        const requestBody = { rows: dtos, supplierIds };
        const response = await this.apiFetchJSON<
            { success: boolean; purchaseOrderNumber: string; supplierId: string }[]
        >(url, {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });
        return response;
    }

    async validateDocumentRequests(
        supplierId: string,
        dtos: CreateDocumentRequestDto[]
    ): Promise<Map<number, ValidationError<CreateDocumentRequestDto>[]>> {
        const url = this.apiUrl(`/suppliers/${supplierId}/document-requests`, { validate: true });
        const errorsMap = new Map<number, ValidationError<CreateDocumentRequestDto>[]>();
        const batchErrorsMap = validateBatch(dtos);

        // TODO: Add request pool.
        for (let i = 0; i < dtos.length; i++) {
            const dto = dtos[i];
            const response = await this.apiFetchJSON<PurchaseOrderNumberValidationDto>(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify(dto),
                },
                status => [200, 400, 409].includes(status)
            );

            const validationErrors = response.message.map(m => {
                const createField = CREATE_FIELDS.find(cf => m.startsWith(cf));
                if (createField) {
                    return { field: createField, message: m.replace(createField, '') };
                } else {
                    return { message: m };
                }
            });

            const batchErrors = batchErrorsMap.get(i);
            if (batchErrors) {
                validationErrors.push(...batchErrors);
            }

            if (validationErrors.length > 0) {
                errorsMap.set(i, validationErrors);
            }
        }

        return errorsMap;
    }

    async createDocumentRequest(supplierId: string, dtos: CreateDocumentRequestDto[]): Promise<void> {
        const url = this.apiUrl(`/suppliers/${supplierId}/document-requests`);

        // TODO: Add request pool.
        // TODO: Add error handling, though validate should handle most issues.
        for (const dto of dtos) {
            await this.apiFetch(url, {
                method: 'POST',
                body: JSON.stringify({ ...dto, createdBySource: this.createdBySource }),
            });
        }
    }

    async getDocumentRequestsMultiSupplier({
        supplierIds,
        search,
        multiSearch,
        integrations,
        statuses,
        gteDate,
        lteDate,
        pageIndex,
        pageSize,
        signal,
        sort,
    }: MultiSupplierGetDocumentRequestParams): Promise<Page<DocumentRequest>> {
        const endpointUrl = `/multi-supplier/document-requests/search`;
        let searchString = search;
        if (multiSearch && searchString) {
            // Only search up to a max of MAX_MULTI_SEARCH_VALUES search values
            searchString = splitOnWhitespaceAndCommas(searchString).slice(0, MAX_MULTI_SEARCH_VALUES).join(',');
        }
        const url = this.apiUrl(endpointUrl, {
            integrations,
            statuses,
            'gte-date': gteDate?.toISOString(),
            'lte-date': lteDate?.toISOString(),
            page: pageIndex,
            size: pageSize,
            sort,
            multiSearch,
        });
        const requestInit: RequestInit = {
            signal,
            method: 'POST',
            body: JSON.stringify({ supplierIds, search: searchString }),
        };
        const responsePage = await this.apiFetchJSON<Page<ApiDocumentRequest>>(url, requestInit);
        return {
            ...responsePage,
            items: responsePage.items.map(this.convertApiDocumentRequest, this),
        };
    }

    async getDocumentRequests({
        supplierId,
        search,
        multiSearch,
        integrations,
        statuses,
        gteDate,
        lteDate,
        pageIndex,
        pageSize,
        signal,
        sort,
    }: SingleSupplierGetDocumentRequestParams): Promise<Page<DocumentRequest>> {
        let endpointUrl = `/suppliers/${supplierId}/document-requests`;
        let searchString = search;
        if (multiSearch && searchString) {
            endpointUrl += '/multisearch';
            // Only search up to a max of MAX_MULTI_SEARCH_VALUES search values
            searchString = splitOnWhitespaceAndCommas(searchString).slice(0, MAX_MULTI_SEARCH_VALUES).join(',');
        }
        const url = this.apiUrl(endpointUrl, {
            ...(!multiSearch && { search: searchString }),
            integrations,
            statuses,
            'gte-date': gteDate?.toISOString(),
            'lte-date': lteDate?.toISOString(),
            page: pageIndex,
            size: pageSize,
            sort,
        });
        const requestInit: RequestInit = {
            signal,
            ...(multiSearch && { method: 'POST', body: JSON.stringify({ search: searchString }) }),
        };
        const responsePage = await this.apiFetchJSON<Page<ApiDocumentRequest>>(url, requestInit);
        return {
            ...responsePage,
            items: responsePage.items.map(this.convertApiDocumentRequest, this),
        };
    }

    async getDocumentRequest(supplierId: string, documentRequestId: string): Promise<DocumentRequest> {
        const url = this.apiUrl(`/suppliers/${supplierId}/document-requests/${documentRequestId}`);
        const documentRequest = await this.apiFetchJSON<ApiDocumentRequest>(url);
        return this.convertApiDocumentRequest(documentRequest);
    }

    async getFileAndDocumentFromDocumentId(supplierId: string, documentId: string): Promise<FileAndDocument> {
        const url = this.apiUrl(`/suppliers/${supplierId}/files/${documentId}`);
        const file = await this.apiFetchJSON<ApiFileAndDocument>(url);
        return this.convertApiFileAndDocument(file);
    }

    async getFileContentFromDocumentId(
        supplierId: string,
        documentId: string,
        purchaseOrderNumber?: string
    ): Promise<{ filename: string; blob: Blob }> {
        const url = this.apiUrl(`/suppliers/${supplierId}/files/${documentId}/content`, {
            'purchase-order-number': purchaseOrderNumber,
        });
        const response = await this.apiFetch(url);
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameMatch = contentDisposition?.match(FILENAME_REGEXP);
        const filename = (filenameMatch && filenameMatch[1]) || 'unknown.pdf';
        const blob = await response.blob();
        return { filename, blob };
    }

    async getZipOfFilesFromDocumentIds(supplierId: string, documentIds: string[]): Promise<Blob> {
        const url = this.apiUrl(`/suppliers/${supplierId}/files/content`, { id: documentIds });
        const response = await this.apiFetch(url);
        if (!response.ok) {
            throw new Error(`Failed to get zip of files: ${response.statusText}`);
        }
        const blob = await response.blob();
        return blob;
    }

    async getAllFiles(supplierIds: string[], params: AllFilesViewFilters): Promise<Page<File>> {
        const endpointUrl = '/files/all';
        const url = this.apiUrl(endpointUrl, {
            supplierIds: supplierIds,
            search: params.search,
            integrations: params.integrations?.map(x => x.id),
            'gte-date': params.gteDate?.toISOString(),
            'lte-date': params.lteDate?.toISOString(),
            page: params.pageIndex,
            size: params.pageSize,
            sortOrder: params.sort?.order,
            sortColumn: params.sort?.column,
        });
        const responsePage = await this.apiFetchJSON<Page<ApiFile>>(url, { signal: params.signal });
        return {
            ...responsePage,
            items: responsePage.items.map(this.convertApiFile),
        };
    }

    async getFileContent(supplierIds: string[], id: string): Promise<{ filename: string; blob: Blob }> {
        const url = this.apiUrl(`/files/${id}/content`, {
            supplierIds,
        });
        const response = await this.apiFetch(url);
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameMatch = contentDisposition?.match(FILENAME_REGEXP);
        const filename = filenameMatch?.[1] || 'unknown.pdf';
        const blob = await response.blob();
        return { filename, blob };
    }

    private apiUrl(path: string, queryParams: Record<string, OptionalURLSearchParamsSerializableValue> = {}) {
        let url = `${this.apiBaseUrl}${path}`;
        const urlSearchParams = this.getUrlSearchParams(queryParams);
        const searchString = urlSearchParams.toString();
        if (searchString.length > 0) {
            url += `?${searchString}`;
        }
        return url;
    }

    private getUrlSearchParams(queryParams: Record<string, OptionalURLSearchParamsSerializableValue>): URLSearchParams {
        const urlSearchParams = new URLSearchParams();
        for (const [k, v] of Object.entries(queryParams)) {
            if (isNotNullish(v)) {
                // This simple serialization seems to be expected by sdi-api.
                // For example, `toString()` serializes arrays by adding commas like `[1,2,3] => "1,2,3"`
                // and sdi-api uses Nest's `ParseArrayPipe` function which expects commas as the default separator.
                urlSearchParams.set(k, v.toString());
            }
        }
        return urlSearchParams;
    }

    private async apiFetch(
        url: string,
        init?: RequestInit,
        validateStatus?: (status: number) => boolean
    ): Promise<Response> {
        const authToken = getGlobalCoreInterface().auth?.getAccessToken();

        if (!authToken) {
            throw new Error('No auth token available');
        }

        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            ...init,
        });
        validateStatus = validateStatus ?? (status => status >= 200 && status < 300);
        if (!validateStatus(response.status)) {
            throw new ApiFetchError(url, init, await response.json());
        }
        return response;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async apiFetchJSON<T extends Record<string, any>>(
        url: string,
        init?: RequestInit,
        validateStatus?: (status: number) => boolean
    ): Promise<T> {
        const res = await this.apiFetch(url, init, validateStatus);
        return await res.json();
    }

    private convertApiFileAndDocument(apiFileAndDocument: ApiFileAndDocument): FileAndDocument {
        return {
            ...apiFileAndDocument,
            createdAt: new Date(apiFileAndDocument.createdAt),
            updatedAt: new Date(apiFileAndDocument.updatedAt),
        };
    }

    private convertApiDocumentRequest(apiDr: ApiDocumentRequest): DocumentRequest {
        return {
            ...apiDr,
            files: apiDr.files.map(this.convertApiFileAndDocument, this),
            createdAt: new Date(apiDr.createdAt),
            updatedAt: new Date(apiDr.updatedAt),
        };
    }

    private convertApiFile(apiFile: ApiFile): File {
        return {
            ...apiFile,
            createdAt: new Date(apiFile.createdAt),
        };
    }
}

// Since we don't have a batch route, we'll need to do some whole batch validation on our end.
function validateBatch(dtos: CreateDocumentRequestDto[]): Map<number, ValidationError<CreateDocumentRequestDto>[]> {
    const map = new Map<number, ValidationError<CreateDocumentRequestDto>[]>();
    const uniqueKeys = new Set<string>();
    for (let i = 0; i < dtos.length; i++) {
        const dto = dtos[i];
        const uniqueKey = `${dto.purchaseOrderNumber}|${dto.destinationZip}|${dto.trackingNumber}`;
        if (uniqueKeys.has(uniqueKey)) {
            map.set(i, [{ message: 'already exists in batch' }]);
        } else {
            uniqueKeys.add(uniqueKey);
        }
    }
    return map;
}
