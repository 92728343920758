import { ReactElement, useEffect, useMemo, useState, useContext, useCallback } from 'react';
import { spIcons } from '@casestack/supplypike-ui';

import DocumentRequestModal from '../../common/purchase-order-number-modal';
import { PageTableColumn } from '../../common/page-table';
import Placeholder, { roundedStyle } from '../../common/placeholder';
import { DocumentRequestStatus } from '../../../api/types';
import { AuthenticatedProps } from '../../../auth/page-with-auth';
import { DocumentRequest, Page } from '../../../types';
import { thirdPartyTrackEvent } from '../../../third-party';
import { fileKindsColumn, getCreatedAtColumn, identifierColumns, statusColumn } from './columns';
import TableRootPage, { RetrievePageParams } from '../../common/table';
import Search from '../../common/search';
import { UrlParamsContext } from '../../../contexts/url-params-context';
import { Supplier } from '../../../types';

import PurchaseOrderNumbersFiltersComponent, { PurchaseOrderNumberFilters } from './filters';
import { TooltipWrapper } from '../../common/tooltip-wrapper';
import AddNewModal from './add-new-modal';
import PopoverButton from '../../common/popover-button';
import { AddNewModalTabName } from './add-new-modal/utils';
import './index.css';
import { getSelectedSuppliers } from '../../../util/supplier-utils';

/** Default number of Document Requests for fetch for the table */
const DEFAULT_PAGE_SIZE = 20;
const SUPPLIERS_KEY = 'supplier';

export default function PurchaseOrderNumbersPage(props: AuthenticatedProps): ReactElement {
    function handleRequestCloseModal(refresh?: boolean) {
        thirdPartyTrackEvent('Add New Modal closed');
        setShowModal(false);
        console.log(`refresh ${refresh} will be handled after the filter overhaul`);
    }

    function handleAddNewClick() {
        // If user is an admin show all possible modals they can open
        if (isAdmin || isImpersonating) {
            setModalPopoverIsOpen(true);
        } else {
            // If they are NOT an admin, just open the PON creation modal
            setSelectedModal('pon');
            setShowModal(true);
            thirdPartyTrackEvent('Add New Modal opened');
        }
    }

    function handleModalTabClick(tab: AddNewModalTabName) {
        setModalPopoverIsOpen(false);
        setSelectedModal(tab);
        setShowModal(true);
        thirdPartyTrackEvent('Add New Modal opened');
    }

    function getAddNewButtonIcon() {
        if (isAdmin || isImpersonating) {
            return spIcons.normal.dropdownCaret;
        } else {
            return spIcons.normal.addPlus;
        }
    }

    const [filters, setFilters] = useState<PurchaseOrderNumberFilters>({});
    const [search, setSearch] = useState<string | undefined>();
    const [searchTooShort, setSearchTooShort] = useState<boolean>(false);
    const [multiSearch, setMultiSearch] = useState<boolean>(false);
    const [selectedDocumentRequestAndSupplier, setSelectedDocumentRequestAndSupplier] = useState<{
        docReq: DocumentRequest;
        supplier: Supplier;
    } | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalPopoverIsOpen, setModalPopoverIsOpen] = useState<boolean>(false);
    const [selectedModal, setSelectedModal] = useState<AddNewModalTabName>('pon');
    const [selectedSuppliers, setSelectedSuppliers] = useState<Supplier[]>([]);
    const { updateSearchParam } = useContext(UrlParamsContext);

    const isAdmin = props.user?.roles?.some(r => r === 'supplypike.support');
    const isImpersonating = props.user?.impersonation.isImpersonating;
    const canCreate = props.suppliers?.find(x => x.canCreateDocumentRequests) || isAdmin || isImpersonating;

    const { apiClient } = props;

    useEffect(() => {
        setSelectedSuppliers(getSelectedSuppliers(props.suppliers ?? [], !!isAdmin));
    }, [props.suppliers, isAdmin]);

    // This updates the URL whenever the user changes their selectedSuppliers using the multiSupplier selector
    useEffect(() => {
        const selectedSupplierIds = selectedSuppliers.map(x => x.id);
        updateSearchParam(SUPPLIERS_KEY, selectedSupplierIds);
    }, [selectedSuppliers, updateSearchParam]);

    const retrievePage = useCallback(
        async (params: RetrievePageParams) => {
            const { pageSize, pageIndex, signal } = params;
            if (!apiClient || !selectedSuppliers.length) {
                return null;
            }
            const response = await apiClient.getDocumentRequestsMultiSupplier({
                supplierIds: selectedSuppliers.map(x => x.id),
                search,
                multiSearch,
                statuses: filters.statuses && filters.statuses.length > 0 ? filters.statuses : undefined,
                integrations:
                    filters.integrations && filters.integrations.length > 0
                        ? filters.integrations.map(i => i.id)
                        : undefined,
                gteDate: filters.gteDate,
                lteDate: filters.lteDate,
                pageSize,
                pageIndex,
                signal,
            });

            return response;
        },
        // We disable eslint for this line because it complains that 'multiSearch' is not in the dependency array.
        // We do not want to rerun this function whenever 'multiSearch' changes, so we just ignore the eslint error.
        [filters, search, apiClient, selectedSuppliers] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Drop selected doc req if any underlying thing changes
    useEffect(() => {
        setSelectedDocumentRequestAndSupplier(null);
    }, [props.suppliers]);

    const setFiltersCallback = useCallback(
        (newFilters: PurchaseOrderNumberFilters) => {
            // check if filters actually changed, to avoid infinite update loop
            if (Object.entries(newFilters).toString() !== Object.entries(filters).toString()) {
                setFilters(newFilters);
            }
        },
        [filters]
    );

    // Columns are memoized, because actions rely on props or need to change state.
    const columns = useMemo<Array<PageTableColumn<DocumentRequest>>>(() => {
        const createdAtColumn = getCreatedAtColumn({ useTwoYearDate: true, includeTimestamp: false });
        return [
            ...identifierColumns,
            {
                key: 'supplierName',
                name: 'Supplier Name',
                component: (params: { data: DocumentRequest }) => {
                    const supplierName = props.suppliers?.find(sup => sup.id === params.data.supplierId)?.name;
                    return (
                        <div className="w-full">
                            <TooltipWrapper key={params.data.id} content={supplierName}>
                                <p className="w-10/12 truncate">{supplierName}</p>
                            </TooltipWrapper>
                        </div>
                    );
                },
                placeholder: <Placeholder style={roundedStyle('12ch', '1rem')} />,
            },
            {
                key: 'source',
                name: 'Source',
                component: (params: { data: DocumentRequest }) => {
                    const integrationId = params.data.files[0]?.integrationId;
                    const integration = props.suppliers
                        ?.flatMap(sup => sup.integrations)
                        .find(i => i.id === integrationId);
                    return <div>{integration?.name}</div>;
                },
                placeholder: <Placeholder style={roundedStyle('12ch', '1rem')} />,
            },
            fileKindsColumn,
            createdAtColumn,
            statusColumn,
            {
                key: 'viewDetails',
                name: 'View Details',
                alignment: 'right' as const,
                component: (params: { data: DocumentRequest }) => {
                    return (
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="sp-icon-button neutral"
                                disabled={params.data.files.length === 0}
                                onClick={() => {
                                    const pon = params.data;
                                    const supplier = props.suppliers?.find(sup => sup.id === pon.supplierId);
                                    if (!supplier) {
                                        return;
                                    }
                                    thirdPartyTrackEvent('PON Detail Modal opened', {
                                        purchaseOrderNumber: pon.purchaseOrderNumber,
                                    });
                                    setSelectedDocumentRequestAndSupplier({ docReq: pon, supplier });
                                }}
                            >
                                <spIcons.normal.document />
                            </button>
                        </div>
                    );
                },
                placeholder: <Placeholder style={roundedStyle(40, 40)} className="inline-block" />,
            },
        ];
    }, [props.suppliers]);

    return (
        <>
            {props.apiClient && props.suppliers && selectedDocumentRequestAndSupplier && (
                <DocumentRequestModal
                    apiClient={props.apiClient}
                    supplier={selectedDocumentRequestAndSupplier.supplier}
                    documentRequest={selectedDocumentRequestAndSupplier.docReq}
                    onRequestClose={() => {
                        thirdPartyTrackEvent('PON Detail Modal closed');
                        setSelectedDocumentRequestAndSupplier(null);
                    }}
                />
            )}
            {props.suppliers && showModal && (
                <AddNewModal
                    apiClient={props.apiClient}
                    onRequestClose={handleRequestCloseModal}
                    // This 'supplier' prop will be removed once we are fully switched over to the multi supplier view.
                    // For now just passing in a random supplier since it is not actually used when 'multiSupplierView' is true
                    supplier={props.suppliers[0]}
                    suppliers={props.suppliers}
                    selectedSuppliers={selectedSuppliers}
                    isAdmin={isAdmin}
                    selectedModal={selectedModal}
                    multiSupplierView={true}
                    isImpersonating={isImpersonating}
                />
            )}
            <TableRootPage<DocumentRequest>
                header="Deduction Files"
                columns={columns}
                selectedTab="pos"
                retrievePage={retrievePage}
                getRowKey={item =>
                    item.purchaseOrderNumber ?? '' + item.destinationZip ?? '' + item.invoiceNumber ?? ''
                }
                initialPageData={INITIAL_PAGE}
                apiClient={props.apiClient}
                supplier={props.supplier}
                suppliers={props.suppliers}
                user={props.user}
                topRightComponent={
                    <div className="flex justify-end align-center flex-grow">
                        <TooltipWrapper content={!canCreate && CANNOT_CREATE_MESSAGE} anchor="right">
                            <PopoverButton
                                theme="tabs"
                                key="modal-tabs"
                                label={'Add New'}
                                icon={getAddNewButtonIcon()}
                                disabled={!props.suppliers || !canCreate}
                                showPopover={modalPopoverIsOpen}
                                onClick={handleAddNewClick}
                                onRequestClose={() => setModalPopoverIsOpen(false)}
                            >
                                <button className="modal-tab" onClick={() => handleModalTabClick('pon')}>
                                    PO Numbers
                                </button>
                                <TooltipWrapper content={isAdmin && ADD_ATTRIBUTES_IMPERSONATION_MESSAGE} anchor="left">
                                    <button
                                        disabled={isAdmin}
                                        style={isAdmin ? { backgroundColor: 'var(--neutral-200)' } : {}}
                                        className="modal-tab"
                                        onClick={() => handleModalTabClick('attributes')}
                                    >
                                        Attributes
                                    </button>
                                </TooltipWrapper>
                            </PopoverButton>
                        </TooltipWrapper>
                    </div>
                }
                filterComponent={
                    <>
                        <Search
                            apiClient={props.apiClient}
                            supplier={props.supplier}
                            suppliers={props.suppliers}
                            selectedSuppliers={selectedSuppliers}
                            user={props.user}
                            setSearchCallback={setSearch}
                            setSearchTooShortCallback={setSearchTooShort}
                            setMultiSearchCallback={setMultiSearch}
                            enableMultiSearch={true}
                            label="Search PO Numbers..."
                        />
                        <PurchaseOrderNumbersFiltersComponent
                            apiClient={props.apiClient}
                            supplier={props.supplier}
                            suppliers={props.suppliers}
                            selectedSuppliers={selectedSuppliers}
                            user={props.user}
                            setFiltersCallback={setFiltersCallback}
                            useMultiSupplierView={true}
                            setSelectedSuppliers={setSelectedSuppliers}
                        />
                    </>
                }
                filtersActive={Object.values(filters).some(v => v !== undefined)}
                searchTooShort={searchTooShort}
                multiSupplierView={true}
                selectedSuppliers={selectedSuppliers}
            />
        </>
    );
}

const CANNOT_CREATE_MESSAGE =
    'Your subscription doesn’t allow you to create new document requests. Reach out to upgrade.';

const ADD_ATTRIBUTES_IMPERSONATION_MESSAGE = 'Please impersonate to add attributes';

/** Dummy data used before the first real page of table data. */
const now = new Date();
const INITIAL_PAGE: Page<DocumentRequest> = {
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 1,
    itemCount: DEFAULT_PAGE_SIZE,
    items: new Array(DEFAULT_PAGE_SIZE).fill(null).map((_, i) => ({
        id: i.toString(),
        supplierId: 'initial-supplier-id',
        purchaseOrderNumber: i.toString(),
        createdBy: 'initial-created-by',
        createdByName: 'initial-created-by-name',
        status: DocumentRequestStatus.PENDING,
        files: [],
        createdAt: now,
        updatedAt: now,
    })),
};
