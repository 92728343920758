import { ReactElement } from 'react';

export default function InvoiceSelectedIcon(): ReactElement {
    return (
        <>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23.5555" cy="23.3651" r="23.3651" fill="#D6FCED" />
                <path
                    d="M34.0567 18.9471L24.2162 9.10651H23.3713H23.058H13.792V36.9044H34.0613V18.9488L34.0567 18.9471ZM24.2162 10.7444L31.8443 18.3724H24.2162V10.7444ZM14.9502 35.7461V10.2647H23.058V19.5307H32.9031V35.7461H14.9502V35.7461Z"
                    fill="#24B599"
                />
            </svg>
        </>
    );
}
