// Unix epoch
export const minDate = new Date(0);
export const maxDate = new Date('3000-01-01');

export function addUtcWeeks(date: Date, numWeeks: number): Date {
    return addUtcDays(date, 7 * numWeeks);
}

/**
 * Adds days, ignoring any DST shifts in the user's timezone.
 */
export function addUtcDays(date: Date, numDays: number): Date {
    const oneDayMs = 86400000;
    return addMs(date, oneDayMs * numDays, false);
}

/**
 * We ignore DST if `useDst` is false.
 * We ignore leap seconds, because that is how Javascript `Date`s work:
 * https://stackoverflow.com/questions/53019726/where-are-the-leap-seconds-in-javascript
 */
function addMs(date: Date, msToAdd: number, useDst: boolean): Date {
    const newDate = new Date(date.getTime() + msToAdd);
    const tzOffsetDelta = useDst ? newDate.getTimezoneOffset() - date.getTimezoneOffset() : 0;
    const oneHourMs = 60000;
    return new Date(newDate.getTime() + tzOffsetDelta * oneHourMs);
}
