import { AllFilesViewFilters } from '../components/pages/all-files-view/types';
import { Page, FileAndDocument, DocumentRequest, Supplier, DocumentAttributes, File } from '../types';

export enum FileKind {
    BOL = 'bol',
    POD = 'pod',
    PENDING = 'pending',
    OTHER = 'other',
}

export function isFileKind(x: unknown): x is FileKind {
    return typeof x === 'string' && Object.values<string>(FileKind).includes(x);
}

// Similar to the normal type, but dates are strings.
export interface ApiFileAndDocument {
    supplierId: string;
    integrationId: string;
    id: string;
    name: string;
    kind: FileKind;
    createdAt: string;
    updatedAt: string;
}

export enum DocumentRequestStatus {
    PENDING = 'pending',
    FAILED = 'failed',
    FINISHED = 'finished',
}

export enum DocumentRequestPendingSubstatus {
    LOCATED = 'located',
}

export enum DocumentRequestFailedSubstatus {
    LOCATED = 'located',
    CANCELLED = 'cancelled',
}

export type DocumentRequestSubstatus = DocumentRequestPendingSubstatus | DocumentRequestFailedSubstatus;

export const purchaseOrderNumberStatuses: (DocumentRequestStatus | DocumentRequestPendingSubstatus)[] = [
    DocumentRequestStatus.PENDING,
    DocumentRequestStatus.FAILED,
    DocumentRequestStatus.FINISHED,
    DocumentRequestPendingSubstatus.LOCATED,
];

export function isDocumentRequestStatus(x: unknown): x is DocumentRequestStatus {
    return typeof x === 'string' && (purchaseOrderNumberStatuses as string[]).includes(x);
}

export enum DocumentPageSort {
    DOCUMENT_REQUEST_CREATED_AT_DESC = 'document_request_created_at_desc',
    DOCUMENT_REQUEST_CREATED_AT_ASC = 'document_request_created_at_asc',
    CARRIER_TRACKER_UPDATED_AT_DESC = 'carrier_tracker_updated_at_desc',
    CARRIER_TRACKER_UPDATED_AT_ASC = 'carrier_tracker_updated_at_asc',
}

export function isDocumentPageSort(x: unknown): x is DocumentPageSort {
    return typeof x === 'string' && Object.values<string>(DocumentPageSort).includes(x);
}

export interface ApiFile {
    id: string;
    supplierIds: string[];
    integrationIds: string[];
    name: string;
    createdAt: string;
}

// Similar to the normal type, but dates are strings.
export interface ApiDocumentRequest {
    id: string;
    supplierId: string;
    purchaseOrderNumber?: string;
    invoiceNumber?: string;
    destinationZip?: string;
    trackingNumber?: string;
    createdBy: string;
    createdByName: string;
    createdBySource?: string;
    status: DocumentRequestStatus;
    substatus?: DocumentRequestSubstatus;
    files: ApiFileAndDocument[];
    createdAt: string;
    updatedAt: string;
}

interface GetDocumentRequestParams {
    search?: string;
    multiSearch?: boolean;
    integrations?: string[];
    statuses?: (DocumentRequestStatus | DocumentRequestPendingSubstatus)[];
    gteDate?: Date;
    lteDate?: Date;
    pageSize: number;
    pageIndex: number;
    signal?: AbortSignal;
    sort?: DocumentPageSort;
}

export interface SingleSupplierGetDocumentRequestParams extends GetDocumentRequestParams {
    supplierId: string;
}

export interface MultiSupplierGetDocumentRequestParams extends GetDocumentRequestParams {
    supplierIds: string[];
}

export interface CreateDocumentRequestDto {
    purchaseOrderNumber?: string;
    invoiceNumber?: string;
    destinationZip?: string;
    trackingNumber?: string;
    suggestedIntegrationId?: string;
    createdBySource?: string;
}

export interface DocumentRequestResponse {
    itemCount: number;
    items: ApiDocumentRequest[];
    pageCount: number;
    pageIndex: number;
    pageSize: number;
}

export interface AddAttributesRequestRow {
    purchaseOrderNumber?: string;
    invoiceNumber?: string;
    destinationZip?: string;
    attributes: DocumentAttributes;
}

export interface PurchaseOrderNumberValidationDto {
    statusCode: number;
    message: string[];
    error?: string;
}

export interface ValidationError<T> {
    field?: keyof T;
    message: string;
}

export interface AttributesNotValid {
    isValid: false;
    errorMessage?: string;
}

export interface AttributesValid {
    isValid: true;
    purchaseOrderNumber: string;
    invoiceNumber: string;
    destinationZip: string;
}

export interface ApiClient {
    getSuppliers(): Promise<Supplier[]>;
    validateDocumentRequests(
        supplierId: string,
        dtos: CreateDocumentRequestDto[]
    ): Promise<Map<number, ValidationError<CreateDocumentRequestDto>[]>>;
    createDocumentRequest(supplierId: string, dtos: CreateDocumentRequestDto[]): Promise<void>;
    addAttributes(
        dtos: AddAttributesRequestRow[],
        supplierIds: string[]
    ): Promise<{ success: boolean; purchaseOrderNumber: string; supplierId: string }[]>;
    validateAttributes(
        rows: AddAttributesRequestRow[],
        supplierIds: string[]
    ): Promise<(AttributesValid | AttributesNotValid)[]>;
    getDocumentRequestsMultiSupplier(params: MultiSupplierGetDocumentRequestParams): Promise<Page<DocumentRequest>>;
    getDocumentRequests(params: SingleSupplierGetDocumentRequestParams): Promise<Page<DocumentRequest>>;
    getDocumentRequest(supplierId: string, documentRequestId: string): Promise<DocumentRequest>;
    getFileAndDocumentFromDocumentId(supplierId: string, documentId: string): Promise<FileAndDocument>;
    getFileContentFromDocumentId(
        supplierId: string,
        documentId: string,
        purchaseOrderNumber?: string
    ): Promise<{ filename: string; blob: Blob }>;
    getZipOfFilesFromDocumentIds(supplierId: string, documentIds: string[]): Promise<Blob>;
    getAllFiles(supplierIds: string[], params: AllFilesViewFilters): Promise<Page<File>>;
    getFileContent(supplierIds: string[], id: string): Promise<{ filename: string; blob: Blob }>;
}
