import { ReactElement } from 'react';

export default function AttachmentSelectedIcon(): ReactElement {
    return (
        <>
            <svg width="47" height="47" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25.6349" cy="25" r="25" fill="#FFE2CC" />
                <path
                    d="M27.0445 29.672C25.5735 29.672 24.1901 29.099 23.1497 28.0587C22.6743 27.5832 22.6743 26.812 23.1497 26.3365C23.6252 25.8611 24.3964 25.8611 24.8719 26.3365C25.4522 26.9169 26.2239 27.2363 27.0445 27.2363C27.8652 27.2363 28.6368 26.9169 29.2172 26.3361L32.4577 23.0956C33.6558 21.8975 33.6558 19.9484 32.4577 18.7503C31.2596 17.5522 29.3105 17.5522 28.1124 18.7503L26.7251 20.1376C26.2497 20.613 25.4784 20.613 25.003 20.1376C24.5275 19.6621 24.5275 18.8909 25.003 18.4154L26.3907 17.0286C28.5384 14.8809 32.0325 14.8813 34.1802 17.0286C36.328 19.1763 36.328 22.6705 34.1802 24.8182L30.9397 28.0587C29.899 29.099 28.516 29.672 27.0445 29.672Z"
                    fill="#FFB061"
                />
                <path
                    d="M20.7765 35.9402C19.3054 35.9402 17.922 35.3672 16.8817 34.3268C14.7339 32.1791 14.7339 28.685 16.8817 26.5372L20.1222 23.2967C22.2699 21.1495 25.764 21.1495 27.9117 23.2967C28.3872 23.7722 28.3872 24.5434 27.9117 25.0189C27.4363 25.4943 26.6651 25.4943 26.1896 25.0189C24.9915 23.8208 23.0424 23.8208 21.8443 25.0189L18.6038 28.2594C17.4057 29.4575 17.4057 31.4066 18.6038 32.6047C19.1841 33.185 19.9558 33.5049 20.7765 33.5049C21.5971 33.5049 22.3688 33.1855 22.9491 32.6051L24.3364 31.2179C24.8118 30.7424 25.583 30.7424 26.0585 31.2179C26.5339 31.6933 26.5339 32.4645 26.0585 32.94L24.6712 34.3273C23.6309 35.3672 22.248 35.9402 20.7765 35.9402Z"
                    fill="#FFB061"
                />
            </svg>
        </>
    );
}
