import { ReactElement } from 'react';

export default function AttachmentIcon(): ReactElement {
    return (
        <>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23.6349" cy="23.3651" r="22.3651" stroke="#FFD3AB" strokeWidth="2" />
                <path
                    d="M24.9524 27.7316C23.5775 27.7316 22.2846 27.1961 21.3123 26.2238C20.8679 25.7794 20.8679 25.0586 21.3123 24.6143C21.7566 24.1699 22.4774 24.1699 22.9218 24.6143C23.4642 25.1567 24.1854 25.4552 24.9524 25.4552C25.7194 25.4552 26.4405 25.1567 26.9829 24.6139L30.0115 21.5853C31.1313 20.4655 31.1313 18.6439 30.0115 17.5241C28.8918 16.4044 27.0701 16.4044 25.9504 17.5241L24.6538 18.8207C24.2095 19.265 23.4887 19.265 23.0443 18.8207C22.6 18.3763 22.6 17.6555 23.0443 17.2112L24.3413 15.915C26.3485 13.9078 29.6142 13.9082 31.6214 15.915C33.6287 17.9223 33.6287 21.1879 31.6214 23.1952L28.5929 26.2238C27.6202 27.1961 26.3276 27.7316 24.9524 27.7316Z"
                    fill="#FFD3AB"
                />
                <path
                    d="M19.094 33.5899C17.7191 33.5899 16.4262 33.0544 15.4539 32.0821C13.4467 30.0748 13.4467 26.8092 15.4539 24.8019L18.4825 21.7733C20.4898 19.7664 23.7554 19.7664 25.7627 21.7733C26.2071 22.2177 26.2071 22.9385 25.7627 23.3828C25.3183 23.8272 24.5975 23.8272 24.1532 23.3828C23.0334 22.2631 21.2118 22.2631 20.092 23.3828L17.0635 26.4114C15.9437 27.5312 15.9437 29.3528 17.0635 30.4726C17.6058 31.0149 18.327 31.3139 19.094 31.3139C19.861 31.3139 20.5822 31.0154 21.1246 30.473L22.4211 29.1764C22.8655 28.7321 23.5863 28.7321 24.0306 29.1764C24.475 29.6208 24.475 30.3416 24.0306 30.7859L22.7341 32.0825C21.7618 33.0544 20.4693 33.5899 19.094 33.5899Z"
                    fill="#FFD3AB"
                />
            </svg>
        </>
    );
}
