import { ReactNode } from 'react';

export interface TooltipProps {
    title: string;
    children?: ReactNode;
}

export function Tooltip(props: TooltipProps): JSX.Element {
    return (
        <div className="sp-tooltip light space-y-2 w-64" style={{ position: 'absolute', top: 0, left: 20 }}>
            <h3 className="sp-h3">{props.title}</h3>
            <span className="sp-caption">{props.children}</span>
        </div>
    );
}
