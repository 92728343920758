import { Navigate, useSearchParams } from 'react-router-dom';
import { ReactElement } from 'react';

// handle the url format that core sidebar uses when sending users to DE
export default function CoreSidebarRedirect(): ReactElement {
    const [searchParams] = useSearchParams();
    const querystring = searchParams.get('supplier') !== null ? `?supplier=${searchParams.get('supplier')}` : '';

    return <Navigate replace to={`/purchase-order-numbers${querystring}`} />;
}
