import { alphabetically } from './string-utils';

/** Returns the provided retailers with prioritized retailers on top, followed by all other retailers sorted alphabetically. */
export function getRetailersInPriorityOrder(retailers: string[]): string[] {
    // Get all retailers that this user has access to that are also in 'retailersWithPriorities' and sort based on their priority
    const prioritizedRetailers = retailersWithPriorities
        .filter(retailerWithPriority => retailers.includes(retailerWithPriority.retailerName))
        .sort(x => x.priority)
        .map(x => x.retailerName);
    // Then get all retailers NOT in 'retailersWithPriorities' and sort alphabetically
    const allOtherRetailers = retailers
        .filter(retailer => !prioritizedRetailers.includes(retailer))
        .sort(alphabetically);
    return [...prioritizedRetailers, ...allOtherRetailers];
}

/**
 * These priorities are based off of the ordering of retailers on Core's side bar.
 * Whenever we add more DN teams for other retailers, we will need to add them to this list
 * so that they show up first on our retailer drop downs.
 */
const retailersWithPriorities: { retailerName: string; priority: number }[] = [
    { retailerName: 'Walmart', priority: 1 },
    { retailerName: 'Kroger', priority: 2 },
    { retailerName: 'Target', priority: 3 },
    { retailerName: 'Amazon', priority: 4 },
    { retailerName: 'Home Depot', priority: 5 },
    { retailerName: 'CVS', priority: 6 },
    { retailerName: 'Lowes', priority: 7 },
];
