import { FC, ReactElement, useState } from 'react';
import { spIcons } from '@casestack/supplypike-ui';
import { Page, Supplier } from '../../types';
import PopoverButton from './popover-button';
import Placeholder, { roundedStyle } from './placeholder';
import SimpleSelect from './simple-select';
import './page-table.css';
import DownloadCsvButton, { MAX_DOWNLOADABLE_RECORDS } from './download-csv-button';
import { SortOrder, TableSort } from './table/sort-utils';
import HeaderCell from './table/header-cell';

type PageTableAlignment = 'left' | 'center' | 'right';
export interface PageTableColumnWithKey<T> {
    key: keyof T;
    name: string;
    component?: never;
    placeholder?: ReactElement;
    alignment?: PageTableAlignment;
    supportsSort?: boolean;
}

export interface PageTableColumnWithComponent<T> {
    key: string;
    name: string;
    component: FC<{ data: T }>;
    placeholder?: ReactElement;
    alignment?: PageTableAlignment;
    supportsSort?: boolean;
}

export type PageTableColumn<T extends Record<string, unknown>> =
    | PageTableColumnWithKey<T>
    | PageTableColumnWithComponent<T>;

export interface OnRequestPageParams {
    pageIndex: number;
    pageSize: number;
    // pageSort?
}

export interface RetrievePageParams {
    pageIndex: number;
    pageSize: number;
    signal: AbortSignal;
    // pageSort?
}

const pageSizeOptions = [10, 20, 50, 100] as const;

interface OwnProps<T extends Record<string, unknown>> {
    columns: PageTableColumn<T>[];
    page: Page<T>;
    getRowKey: (data: T) => string;
    retrievePage: (params: RetrievePageParams) => Promise<Page<T> | null>;
    onRequestPageChange: (params: OnRequestPageParams) => void;
    emptyElement?: ReactElement;
    loading?: boolean;
    enableDownload?: boolean;
    downloadColumnWhiteList?: (keyof T)[];
    downloadColumnBlackList?: (keyof T)[];
    downloadTransform?: (x: T) => Record<string, unknown>;
    multiSupplierView?: boolean;
    suppliers?: Supplier[];
    selectedSuppliers?: Supplier[];
    sort?: TableSort<T>;
    setSort?: (sort: TableSort<T>) => void;
}

export function alignmentClassName(params: { alignment?: PageTableAlignment; useFlexboxAlignment?: boolean }): string {
    const { alignment, useFlexboxAlignment } = params;
    switch (alignment) {
        case 'left':
            return useFlexboxAlignment ? 'justify-start' : 'text-left';
        case 'center':
            return useFlexboxAlignment ? 'justify-center' : 'text-center';
        case 'right':
            return useFlexboxAlignment ? 'justify-end' : 'text-right';
        default:
            return useFlexboxAlignment ? 'justify-start' : 'text-left';
    }
}

interface PageTableControlsProps<T extends Record<string, unknown>> {
    page: Page<unknown>;
    retrievePage: (params: RetrievePageParams) => Promise<Page<T> | null>;
    onRequestPageChange: (params: OnRequestPageParams) => void;
    loading?: boolean;
    enableDownload?: boolean;
    downloadColumnWhiteList?: (keyof T)[];
    downloadColumnBlackList?: (keyof T)[];
    downloadTransform?: (x: T) => Record<string, unknown>;
    suppliers?: Supplier[];
    selectedSuppliers?: Supplier[];
}

function PageTableControls<T extends Record<string, unknown>>(props: PageTableControlsProps<T>): ReactElement {
    const { page, retrievePage, onRequestPageChange, loading, enableDownload } = props;
    const [showPageSizePopover, setShowPageSizePopover] = useState(false);
    const pageStartIndex = page.pageIndex * page.pageSize;
    const pageEndIndex = pageStartIndex + (page.pageSize - 1);
    const numberFormat = new Intl.NumberFormat();

    async function retrieveAllPages(signal: AbortSignal): Promise<T[]> {
        const firstPage = await retrievePage({ pageSize: 1000, pageIndex: 0, signal });
        const pages = [firstPage];
        if (!firstPage) {
            return [];
        }
        for (let i = 1; i < firstPage.pageCount; i++) {
            // we could use signal.throwIfAborted(); if on node 17 or higher
            if (signal.aborted) {
                // i would throw signal.reason in there too, but that's not available either
                const error = new Error();
                error.name = 'AbortError';
                throw error;
            }
            const page = await retrievePage({ pageSize: 1000, pageIndex: i, signal });
            pages.push(page);
        }
        return pages.flatMap(x => x?.items ?? []);
    }

    return (
        <div className="flex w-full justify-between">
            <div className="flex items-center">
                {loading && <div className="sp-loading-spinner small" />}
                {enableDownload ? (
                    <DownloadCsvButton
                        fileName="document-explorer-data.csv"
                        getData={retrieveAllPages}
                        columnWhiteList={props.downloadColumnWhiteList}
                        columnBlackList={props.downloadColumnBlackList}
                        downloadTransform={props.downloadTransform}
                        disabled={page.itemCount > MAX_DOWNLOADABLE_RECORDS}
                        selectedSuppliers={props.selectedSuppliers}
                        suppliers={props.suppliers}
                    ></DownloadCsvButton>
                ) : (
                    <></>
                )}
            </div>
            <div className="flex items-center">
                {loading && <div className="sp-loading-spinner small" />}
                <div className="flex items-center ml-4">
                    Per page:
                    <PopoverButton
                        theme="text"
                        className="ml-2"
                        label={
                            <span className="flex items-center">
                                {page.pageSize}
                                <span className="ml-2" />
                                <spIcons.small.smallCaret />
                            </span>
                        }
                        showPopover={showPageSizePopover}
                        onClick={() => setShowPageSizePopover(true)}
                        onRequestClose={() => setShowPageSizePopover(false)}
                    >
                        <SimpleSelect<number>
                            options={pageSizeOptions.map(x => {
                                return { id: x.toString(10), label: x.toString(10), value: x };
                            })}
                            value={page.pageSize}
                            onChange={value => {
                                setShowPageSizePopover(false);
                                onRequestPageChange({ pageSize: value?.valueOf() ?? page.pageSize, pageIndex: 0 });
                            }}
                        />
                    </PopoverButton>
                </div>

                <div className="text-neutral-700 ml-4">
                    {/* display as 1-indexed instead of 0-indexed */}
                    {numberFormat.format(Math.min(pageStartIndex + 1, page.itemCount))} -{' '}
                    {numberFormat.format(Math.min(pageEndIndex + 1, page.itemCount))} of{' '}
                    {numberFormat.format(page.itemCount)}
                </div>

                <button
                    className="sp-icon-button neutral ml-4"
                    disabled={loading || page.pageIndex <= 0}
                    onClick={() => onRequestPageChange({ pageSize: page.pageSize, pageIndex: page.pageIndex - 1 })}
                >
                    <spIcons.normal.previousTab />
                </button>

                <button
                    className="sp-icon-button neutral ml-2"
                    disabled={loading || page.pageIndex + 1 >= page.pageCount}
                    onClick={() => onRequestPageChange({ pageSize: page.pageSize, pageIndex: page.pageIndex + 1 })}
                >
                    <spIcons.normal.nextTab />
                </button>
            </div>
        </div>
    );
}

function PageTableRow<T extends Record<string, unknown>>(props: {
    columns: PageTableColumn<T>[];
    item: T;
    loading?: boolean;
    multiSupplierView?: boolean;
}): ReactElement {
    const { item, columns } = props;

    return (
        <tr>
            {columns.map(column => {
                const renderCellContent = () => {
                    if (props.loading) {
                        return column.placeholder || <Placeholder style={roundedStyle('100%', '1rem')} />;
                    } else if (column.component) {
                        return column.component({ data: item });
                    } else if (isObjKey(column.key, item)) {
                        return '' + (item[column.key] ?? '');
                    } else {
                        return undefined;
                    }
                };

                return (
                    <td
                        key={column.key.toString()}
                        className={alignmentClassName({ alignment: column.alignment })}
                        style={!props.multiSupplierView ? { verticalAlign: 'top' } : {}}
                    >
                        {renderCellContent()}
                    </td>
                );
            })}
        </tr>
    );
}

/**
 * A basic table with page controls.
 */
export default function PageTable<T extends Record<string, unknown>>(props: OwnProps<T>): ReactElement {
    const pageTableControls = <PageTableControls<T> {...props} />;

    function handleSortClick(column: keyof T) {
        if (props.setSort) {
            if (column === props.sort?.column) {
                const sortOrder: SortOrder = props.sort?.order === 'DESC' ? 'ASC' : 'DESC';
                props.setSort({ column: column, order: sortOrder });
            } else {
                props.setSort({ column: column, order: 'DESC' });
            }
        }
    }

    return (
        <div className="page-table-vertical-container">
            <div className="page-table-top">{pageTableControls}</div>
            <div className="page-table-horizontal-container">
                <table className="page-table">
                    <thead>
                        <tr>
                            {props.columns.map(column => {
                                return HeaderCell({ column, sort: props.sort, handleSortClick });
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props.page.items.map((item, index) => (
                            <PageTableRow
                                key={props.getRowKey(item) + index}
                                columns={props.columns}
                                item={item}
                                loading={props.loading}
                                multiSupplierView={props.multiSupplierView}
                            />
                        ))}
                    </tbody>
                </table>
                {!props.loading && props.page.items.length === 0 && props.emptyElement}
            </div>
            <div className="page-table-bottom">{pageTableControls}</div>
        </div>
    );
}

function isObjKey<T extends Record<PropertyKey, unknown>>(key: PropertyKey, obj: T): key is keyof T {
    return key in obj;
}
