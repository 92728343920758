import Confirmation from '../confirmation';
import { thirdPartyTrackEvent } from '../../../third-party';

interface OwnProps {
    message: string;
    event: string;
    closeConfirmationVisible: boolean;
    resetConfirmationVisible: boolean;
    setCloseConfirmationVisible: (setVisible: boolean) => void;
    setResetConfirmationVisible: (setVisible: boolean) => void;
    onRequestClose: (refresh?: boolean) => void;
    reset: () => void;
}

export default function ModalInputTableConfirmationPopup(props: OwnProps) {
    return (
        <>
            {props.closeConfirmationVisible && (
                <Confirmation
                    onConfirm={props.onRequestClose}
                    confirmText="Cancel"
                    onRequestClose={() => props.setCloseConfirmationVisible(false)}
                >
                    <p className="sp-h2 text-center">{props.message}</p>
                    <p className="sp-body text-center mt-2">You can always restart the process.</p>
                </Confirmation>
            )}

            {props.resetConfirmationVisible && (
                <Confirmation
                    onConfirm={() => {
                        thirdPartyTrackEvent(props.event);
                        props.reset();
                    }}
                    confirmText="Start Over"
                    onRequestClose={() => props.setResetConfirmationVisible(false)}
                >
                    <p className="sp-h2 text-center">Are you sure you want to start over?</p>
                    <p className="sp-body text-center mt-2">This will clear all your current rows.</p>
                </Confirmation>
            )}
        </>
    );
}
