/** Currently only works for English. */
export function pluralize(count: number, thing: string, plural?: string): string {
    return `${count} ${count === 1 ? thing : plural ?? thing + 's'}`;
}

/** Determines if the string has likely been copied from a table. */
export function isTabularString(string: string): boolean {
    return /[\t\n]/.test(string);
}

/** Splits string on whitespace and commas, also filters out any empty strings */
export function splitOnWhitespaceAndCommas(string: string): string[] {
    return string.split(/,|\s/g).filter(x => x.length);
}

/** Truncates a string with 'suffix' after 'maxLength' number of characters */
export function truncate(string: string, maxLength: number, suffix = '...') {
    return string.length > maxLength ? string.slice(0, maxLength - suffix.length) + suffix : string;
}

/** Used in conjunction with the .sort() method to sort an array of strings alphabetically */
export function alphabetically(a: string, b: string): number {
    return a.localeCompare(b);
}

export function capitalizeFirstCharacter(s: string) {
    if (s.length > 0) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    return s;
}
