import { format } from 'date-fns';
import * as React from 'react';

import { spTypography } from '@casestack/style-themes';
import { SpButton, spIcons } from '@casestack/supplypike-ui';

import { Calendar } from './inner/components/calendar';
import { DateSelectionTimeScale } from './inner/logic/time-scale';

export interface DateRangeInputProps {
    label: string;
    tooltip?: React.ReactNode;
    timeScale: DateSelectionTimeScale;
    start?: Date;
    end?: Date;
    /** Defaults to `'empty range'` */
    noSelectImplies?: 'empty range' | 'unbounded range';
    /**
     * Defaults to `'atomic selection'`.
     * By atomic we mean minimum-size, based on `timeScale.selectionTimeScale`.
     */
    singleSelectImplies?: 'atomic selection' | 'unbounded start' | 'unbounded end';
    onRequestClose: () => void;
    onChange: (start: Date | undefined, end: Date | undefined) => void;
}

export function dateRangeToString(start: Date | undefined, end: Date | undefined): string {
    if (!start) {
        if (!end) {
            return 'No filter';
        } else {
            return `${format(end, 'MM/dd/yyyy')} and before`;
        }
    } else {
        if (!end) {
            return `${format(start, 'MM/dd/yyyy')} and after`;
        }
        const startStr = format(start, 'MM/dd/yyyy');
        const endStr = format(end, 'MM/dd/yyyy');
        if (startStr === endStr) {
            return startStr;
        } else {
            return `${startStr} - ${endStr}`;
        }
    }
}

export function DateRangeInput(props: DateRangeInputProps): JSX.Element {
    const [start, setStart] = React.useState<Date | undefined>(props.start);
    const [end, setEnd] = React.useState<Date | undefined>(props.end);
    const [consideredStart, setConsideredStart] = React.useState<Date | undefined>(props.start);
    const [consideredEnd, setConsideredEnd] = React.useState<Date | undefined>(props.end);
    const [hasUnappliedChanges, setHasUnappliedChanges] = React.useState<boolean>(false);
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

    return (
        <div className="space-y-4">
            <div className="flex flex-row items-center">
                <h2 className={spTypography.h2} style={{ paddingRight: 8 }}>
                    {props.label}
                </h2>
                {props.tooltip ? (
                    <span style={{ position: 'relative' }}>
                        <spIcons.small.info
                            onMouseEnter={() => {
                                setShowTooltip(true);
                            }}
                            onMouseLeave={() => {
                                setShowTooltip(false);
                            }}
                        ></spIcons.small.info>
                        {showTooltip ? props.tooltip : <></>}
                    </span>
                ) : (
                    <></>
                )}
                <button
                    className={'sp-icon-button neutral'}
                    style={{ marginLeft: 'auto' }}
                    onClick={() => props.onRequestClose()}
                >
                    <spIcons.normal.x />
                </button>
            </div>
            <div className="flex flex-row justify-start">
                <span className="sp-h3">{dateRangeToString(consideredStart, consideredEnd)}</span>
            </div>
            <Calendar
                timeScale={props.timeScale}
                start={start}
                end={end}
                noSelectImplies={props.noSelectImplies}
                singleSelectImplies={props.singleSelectImplies}
                onChange={(start: Date | undefined, end: Date | undefined) => {
                    setStart(start);
                    setEnd(end);
                    setHasUnappliedChanges(true);
                }}
                onConsideredChange={(start: Date | undefined, end: Date | undefined) => {
                    setConsideredStart(start);
                    setConsideredEnd(end);
                }}
            />
            <div className="flex flex-row justify-end space-x-2">
                <SpButton
                    label="Clear"
                    theme="text"
                    colorTheme="neutral"
                    disabled={start === undefined && end === undefined}
                    onClick={() => {
                        setStart(undefined);
                        setEnd(undefined);
                        setHasUnappliedChanges(true);
                    }}
                ></SpButton>
                <SpButton
                    label="Apply"
                    theme="solid"
                    colorTheme="blue"
                    onClick={() => {
                        if (hasUnappliedChanges) {
                            props.onChange(start, end);
                        }
                        props.onRequestClose();
                    }}
                ></SpButton>
            </div>
        </div>
    );
}
