import { CellValue } from '../../../common/modal-input-table/types';

type RowType = Record<string, CellValue>;

export type AddNewModalTabName = 'pon' | 'attributes';

export function setRow<T extends RowType>(
    rows: T[],
    row: T,
    index: number,
    setRows: (rows: T[]) => void,
    state: string,
    addBlank?: boolean
) {
    const nextRows = [...rows.slice(0, index), row, ...rows.slice(index + 1, rows.length)];
    addBlank && state === 'initial' && addFinalBlank(nextRows);
    setRows(nextRows);
}

export function addRows<T extends RowType>(
    rows: T[],
    newRows: T[],
    setRows: (rows: T[]) => void,
    state: string,
    index?: number,
    addBlank?: boolean
) {
    const nextRows = [...rows.slice(0, index), ...newRows];
    addBlank && state === 'initial' && addFinalBlank(nextRows);
    setRows(nextRows);
}

export function deleteRow<T extends RowType>(
    rows: T[],
    index: number,
    setRows: (rows: T[]) => void,
    state: string,
    reset: () => void,
    addBlank?: boolean
) {
    const nextRows = [...rows.slice(0, index), ...rows.slice(index + 1, rows.length)];
    if (nextRows.length === 0) {
        // If the user deletes all rows, consider that an attempt to "Start Over".
        reset();
    } else {
        addBlank && state === 'initial' && addFinalBlank(nextRows);
        setRows(nextRows);
    }
}

export function isRowBlank<T extends RowType>(row: T) {
    return row.purchaseOrderNumber === undefined && row.destinationZip === undefined;
}

export function addFinalBlank<T extends RowType>(nextRows: T[]) {
    const lastRow = nextRows[nextRows.length - 1];
    if (!lastRow || !isRowBlank(lastRow)) {
        nextRows.push({} as T);
    }
}
