import { spIcons } from '@casestack/supplypike-ui';
import { ReactElement } from 'react';

import './search-input.css';

interface OwnProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    isMultiSearch?: boolean;
    onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
}

export default function SearchInput(props: OwnProps): ReactElement {
    return (
        <div className="search-input-wrapper">
            <input
                type="text"
                className="search-input"
                value={props.value}
                onChange={event => props.onChange(event.target.value)}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onFocus={e => props.onFocus?.(e)}
            />
            <div className="search-input-icon-wrapper">
                <spIcons.normal.search className="fill-current" height={22} width={22} />
            </div>
            <spIcons.normal.x
                className={`absolute right-2 bottom-2 cursor-pointer fill-current end ${
                    props.isMultiSearch ? '' : 'hidden'
                }`}
                onClick={() => props.onChange('')}
            />
        </div>
    );
}
