import { TooltipWrapper } from '../tooltip-wrapper';
import { spIcons } from '@casestack/supplypike-ui';
import { ReactElement } from 'react';
import { ValidationError } from '../../../api/types';
import ModalTableTextInput from './modal-table-text-input';
import ModalTableIntegrationSelect from './modal-table-integration-select';
import { Integration } from '../../../types';
import { isTabularString } from '../../../util/string-utils';
import { CellValue } from './types';

interface OwnProps<T extends Record<string, CellValue>> {
    row: T;
    field: keyof T;
    label: string;
    maxLength: number;
    inputType: 'text' | 'select';
    validationError?: ValidationError<T>;
    onChange: (row: T) => void;
    onPaste?: (data: string, index: number) => void;
    placeholder?: string;
    disabled?: boolean;
    carrierIntegrations?: Integration[];
    rowIndex: number;
}

/** Currently only supports TextInput and IntegrationSelect inputs */
export default function ModalInputTableCell<T extends Record<string, CellValue>>(props: OwnProps<T>): ReactElement {
    return (
        <>
            {props.validationError && (
                <TooltipWrapper
                    content={
                        <div>
                            <div className="sp-subtitle">{props.field + ' invalid'}</div>
                            <div className="sp-caption">{props.validationError.message}</div>
                        </div>
                    }
                >
                    <spIcons.normal.exclamationPoint color="red" />
                </TooltipWrapper>
            )}

            {props.inputType === 'text' ? (
                <ModalTableTextInput
                    label={props.label}
                    value={(props.row[props.field] as string) ?? ''}
                    onChange={value =>
                        props.onChange({
                            ...props.row,
                            [props.field]: value.length > 0 ? value.toUpperCase() : undefined,
                        })
                    }
                    changeOnBlur={true}
                    onPaste={data => {
                        if (isTabularString(data) && props.onPaste) {
                            props.onPaste(data, props.rowIndex);
                            return true;
                        } else {
                            return false;
                        }
                    }}
                    placeholder={props.onPaste ? props.placeholder : undefined}
                    className="sp-tabular-body"
                    maxLength={props.maxLength}
                    disabled={props.disabled}
                />
            ) : (
                <ModalTableIntegrationSelect
                    options={props.carrierIntegrations ?? []}
                    value={
                        (props.row.suggestedIntegration as Integration)
                            ? (props.row.suggestedIntegration as Integration)
                            : null
                    }
                    onChange={integration =>
                        props.onChange({
                            ...props.row,
                            [props.field]: integration,
                        })
                    }
                    disabled={props.disabled}
                    placeholder="Unknown"
                    clearText="Unknown"
                />
            )}
        </>
    );
}
