import { spIcons } from '@casestack/supplypike-ui';
import { ReactElement, useState } from 'react';
import { Integration } from '../../../types';
import Popover from '../../common/popover';
import SimpleSelect, { OnChangeValue } from '../../common/simple-select';

import './modal-table-integration-select.css';

interface OwnProps {
    options: Integration[];
    value: Integration | null;
    onChange: (value: Integration | null) => void;
    placeholder: string;
    clearText?: string;
    disabled?: boolean;
}

/**
 * @todo Abstract this to make it more useful?
 */
export default function ModalTableIntegrationSelect(props: OwnProps): ReactElement {
    const [showPopover, setShowPopover] = useState(false);

    function handleChange(value: OnChangeValue<Integration, false>) {
        props.onChange(value);
        setShowPopover(false);
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Escape' && showPopover) {
            setShowPopover(false);
            event.stopPropagation();
        }
    }

    return (
        <div className="relative">
            <button
                type="button"
                className="integration-select"
                onClick={() => setShowPopover(true)}
                onKeyDown={handleKeyDown}
                disabled={props.disabled}
            >
                {props.value ? (
                    <span>{props.value.name}</span>
                ) : (
                    <span className="text-neutral-700">{props.placeholder}</span>
                )}
                {!props.disabled && <spIcons.small.smallCaret />}
            </button>
            <Popover visible={showPopover} onRequestClose={() => setShowPopover(false)}>
                <SimpleSelect
                    options={props.options.map(i => ({ id: i.id, label: i.name, value: i }))}
                    value={props.value}
                    isEqual={(a, b) => a?.id === b?.id}
                    onChange={handleChange}
                    clearText={props.clearText}
                />
            </Popover>
        </div>
    );
}
