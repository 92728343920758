import { ReactElement, useContext, useState } from 'react';
import ToastsContext, { ToastParams } from '../../contexts/toasts-context';
import FadeWrapper from '../common/fade-wrapper';
import Toast from '../common/toast';

import './toasts.css';

function toastDisplayDuration(toast: ToastParams) {
    return (toast.title.length + toast.body.length) * 50;
}

export default function Toasts(): ReactElement {
    const value = useContext(ToastsContext);
    const [closedToastKeys, setClosedToastKeys] = useState<string[]>([]);

    return (
        <div className="toasts">
            {value.all().map(toast => {
                const closed = closedToastKeys.some(k => k === toast.key);
                const displayDuration = !closed ? toastDisplayDuration(toast) : 0;
                return (
                    <FadeWrapper
                        key={toast.key}
                        displayDuration={displayDuration}
                        onFadeOut={() => value.delete(toast.key)}
                    >
                        <Toast
                            color={toast.color}
                            title={toast.title}
                            body={toast.body}
                            onRequestClose={() => setClosedToastKeys([...closedToastKeys, toast.key])}
                            className="mt-2"
                        />
                    </FadeWrapper>
                );
            })}
        </div>
    );
}
