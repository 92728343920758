import { AllFilesViewFilters } from '../components/pages/all-files-view/types';
import { Integration, Page, FileAndDocument, DocumentRequest, Supplier, File } from '../types';
import {
    CreateDocumentRequestDto,
    FileKind,
    DocumentRequestStatus,
    ValidationError,
    ApiClient,
    AttributesNotValid,
    AddAttributesRequestRow,
    AttributesValid,
    SingleSupplierGetDocumentRequestParams,
    MultiSupplierGetDocumentRequestParams,
} from './types';

const FAKE_INTEGRATIONS: Integration[] = [
    { id: 'fake-ups', name: 'Fake UPS', isCarrier: true, usesDestinationZip: true, usesTrackingNumber: false },
    { id: 'fake-fedex', name: 'Fake FedEx', isCarrier: true, usesDestinationZip: true, usesTrackingNumber: true },
    { id: 'fake-saia', name: 'Fake Saia', isCarrier: true, usesDestinationZip: true, usesTrackingNumber: false },
    { id: 'fake-odfl', name: 'Fake ODFL', isCarrier: true, usesDestinationZip: false, usesTrackingNumber: false },
    { id: 'fake-ward', name: 'Fake Ward', isCarrier: true, usesDestinationZip: false, usesTrackingNumber: false },
    {
        id: 'fake-dropbox',
        name: 'Fake Dropbox',
        isCarrier: false,
        usesDestinationZip: false,
        usesTrackingNumber: false,
    },
    {
        id: 'fake-google-drive',
        name: 'Fake Google Drive',
        isCarrier: false,
        usesDestinationZip: false,
        usesTrackingNumber: false,
    },
];

const FAKE_USERS: { id: string; name: string }[] = [
    { id: 'fake-user-1', name: 'Gene Smith' },
    { id: 'fake-user-2', name: 'John Wright' },
    { id: 'fake-user-3', name: 'Rachel Taylor' },
    { id: 'machine', name: 'Machine' },
];

export class FakeApiClient implements ApiClient {
    private static START_OF_YEAR = new Date('2021-01-01');
    private static ONE_DAY = 864e5;

    private integrations: Integration[];
    private documentRequests: DocumentRequest[];
    private files: FileAndDocument[];

    constructor() {
        this.integrations = FAKE_INTEGRATIONS;

        this.files = [];
        for (let i = 0; i < 500; i++) {
            this.files.push(this.fakeFile(this.sample(this.integrations)));
        }
        this.files = this.files.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf());

        this.documentRequests = [];
        for (let i = 0; i < 200; i++) {
            const pon = this.fakeDocumentRequest();

            // Sample from file pool so that files will be "real".
            for (let j = 0; j < 1 + Math.floor(Math.random() * 3); j++) {
                pon.files.push(this.sample(this.files));
            }

            this.documentRequests.push(pon);
        }
        this.documentRequests = this.documentRequests.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf());
    }

    getSuppliers(): Promise<Supplier[]> {
        return this.delayedResponse([
            {
                id: 'fake-supplier',
                name: 'Fake Supplier',
            } as Supplier,
        ]);
    }

    validateAttributes(
        dtos: AddAttributesRequestRow[],
        _supplierIds: string[]
    ): Promise<(AttributesValid | AttributesNotValid)[]> {
        const results: (AttributesValid | AttributesNotValid)[] = [];
        for (const dto of dtos) {
            if (Object.keys(dto.attributes).length) {
                results.push({
                    isValid: true,
                    purchaseOrderNumber: dto.purchaseOrderNumber ?? '',
                    invoiceNumber: dto.invoiceNumber ?? '',
                    destinationZip: dto.destinationZip ?? '',
                });
            } else {
                results.push({ isValid: false, errorMessage: 'No attributes provided' });
            }
        }
        return this.delayedResponse(results);
    }

    addAttributes(
        _dtos: AddAttributesRequestRow[],
        _supplierIds: string[]
    ): Promise<{ success: boolean; purchaseOrderNumber: string; supplierId: string }[]> {
        return this.delayedResponse([]);
    }

    /**
     * Implements some basic validations for local testing.
     */
    validateDocumentRequests(
        _supplierId: string,
        dtos: CreateDocumentRequestDto[]
    ): Promise<Map<number, ValidationError<CreateDocumentRequestDto>[]>> {
        const map = new Map<number, ValidationError<CreateDocumentRequestDto>[]>();
        const batchMap = validateBatch(dtos);

        for (let i = 0; i < dtos.length; i++) {
            const dto = dtos[i];
            const errors: ValidationError<CreateDocumentRequestDto>[] = [];

            if (!dto.purchaseOrderNumber || !/^\d{10}$/.test(dto.purchaseOrderNumber)) {
                errors.push({ field: 'purchaseOrderNumber', message: 'must be 10 digits' });
            }

            if (dto.destinationZip && !/^\d{5}$/.test(dto.destinationZip)) {
                errors.push({ field: 'destinationZip', message: 'must be 5 digits' });
            }

            const batchMapErrors = batchMap.get(i);
            if (batchMapErrors) {
                errors.push(...batchMapErrors);
            }

            if (errors.length > 0) {
                map.set(i, errors);
            }
        }

        return this.delayedResponse(map);
    }

    createDocumentRequest(): Promise<void> {
        return this.delayedResponse(undefined);
    }

    async getDocumentRequestsMultiSupplier(
        params: MultiSupplierGetDocumentRequestParams
    ): Promise<Page<DocumentRequest>> {
        let filtered = this.documentRequests;

        const { search } = params;
        if (search) {
            filtered = filtered.filter(
                dr => dr.purchaseOrderNumber?.startsWith(search) || dr.invoiceNumber?.startsWith(search)
            );
        }

        const { integrations } = params;
        if (integrations) {
            filtered = filtered.filter(dr => dr.files.some(file => integrations.includes(file.integrationId)));
        }

        const pageIndex = params.pageIndex;
        const pageSize = params.pageSize;
        const itemIndex = pageIndex * pageSize;
        const items = filtered.slice(itemIndex, itemIndex + pageSize);
        return this.delayedResponse({
            pageIndex,
            pageSize,
            pageCount: Math.floor(filtered.length / pageSize),
            itemCount: filtered.length,
            items,
        });
    }

    async getDocumentRequests(params: SingleSupplierGetDocumentRequestParams): Promise<Page<DocumentRequest>> {
        let filtered = this.documentRequests;

        const { search } = params;
        if (search) {
            filtered = filtered.filter(
                dr => dr.purchaseOrderNumber?.startsWith(search) || dr.invoiceNumber?.startsWith(search)
            );
        }

        const { integrations } = params;
        if (integrations) {
            filtered = filtered.filter(dr => dr.files.some(file => integrations.includes(file.integrationId)));
        }

        const pageIndex = params.pageIndex;
        const pageSize = params.pageSize;
        const itemIndex = pageIndex * pageSize;
        const items = filtered.slice(itemIndex, itemIndex + pageSize);
        return this.delayedResponse({
            pageIndex,
            pageSize,
            pageCount: Math.floor(filtered.length / pageSize),
            itemCount: filtered.length,
            items,
        });
    }

    getAllFiles(_supplierIds: string[], params: AllFilesViewFilters): Promise<Page<File>> {
        return this.delayedResponse({
            pageIndex: params.pageIndex ?? 0,
            pageSize: params.pageSize ?? 0,
            pageCount: 0,
            itemCount: 0,
            items: [],
        });
    }

    getFileContent(_supplierIds: string[], _id: string): Promise<{ filename: string; blob: Blob }> {
        return this.delayedResponse({ filename: 'file.pdf', blob: new Blob([], { type: 'application/pdf' }) });
    }

    getDocumentRequest(supplierId: string, documentRequestId: string): Promise<DocumentRequest> {
        const found = this.documentRequests.find(dr => dr.id === documentRequestId && dr.supplierId === supplierId);

        if (!found) {
            throw new Error('Document Request not found');
        }

        return this.delayedResponse(found);
    }

    getFileAndDocumentFromDocumentId(supplierId: string, documentId: string): Promise<FileAndDocument> {
        const found = this.files.find(f => f.supplierId === supplierId && f.id === documentId);

        if (!found) {
            throw new Error('File not found');
        }

        return this.delayedResponse(found);
    }

    getFileContentFromDocumentId(supplierId: string, documentId: string): Promise<{ filename: string; blob: Blob }> {
        const found = this.files.find(f => f.supplierId === supplierId && f.id === documentId);

        if (!found) {
            throw new Error('File not found');
        }

        return this.delayedResponse({ filename: found.name, blob: new Blob([], { type: 'application/pdf' }) });
    }

    getZipOfFilesFromDocumentIds(_supplierId: string, _documentIds: string[]): Promise<Blob> {
        return this.delayedResponse(new Blob([], { type: 'application/zip' }));
    }

    private sample<T>(elements: T[]): T {
        return elements[Math.floor(Math.random() * elements.length)];
    }

    private randomDigits(length: number) {
        return new Array(length)
            .fill(0)
            .map(() => Math.floor(Math.random() * 10))
            .join('');
    }

    private delayedResponse<T>(response: T): Promise<T> {
        return new Promise(resolve => setTimeout(() => resolve(response), 100 + Math.random() * 1000));
    }

    private fakeFile(integration: Integration): FileAndDocument {
        const createdAt = new Date(FakeApiClient.START_OF_YEAR.valueOf() + Math.random() * 28 * FakeApiClient.ONE_DAY);
        const updatedAt = new Date(createdAt.valueOf() + Math.random() * 7 * FakeApiClient.ONE_DAY);
        const id = this.randomDigits(10);

        return {
            supplierId: 'fake-supplier',
            integrationId: integration.id,
            id,
            name: `Fake File ${id}.pdf`,
            kind: this.sample([FileKind.BOL, FileKind.POD, FileKind.OTHER]),
            createdAt,
            updatedAt,
        };
    }

    private fakeDocumentRequest(files: FileAndDocument[] = []): DocumentRequest {
        const createdAt = new Date(FakeApiClient.START_OF_YEAR.valueOf() + Math.random() * 28 * FakeApiClient.ONE_DAY);
        const updatedAt = new Date(createdAt.valueOf() + Math.random() * 7 * FakeApiClient.ONE_DAY);
        const user = this.sample(FAKE_USERS);

        return {
            id: this.randomDigits(20),
            supplierId: 'fake-supplier',
            purchaseOrderNumber: this.randomDigits(10),
            destinationZip: this.randomDigits(5),
            createdBy: user.id,
            createdByName: user.name,
            status: DocumentRequestStatus.FINISHED,
            files,
            createdAt,
            updatedAt,
        };
    }
}

// class UnimplementedError extends Error {
//   constructor() {
//     super('Unimplemented');
//   }
// }

// Since we don't have a batch route, we'll need to do some whole batch validation on our end.
function validateBatch(dtos: CreateDocumentRequestDto[]): Map<number, ValidationError<CreateDocumentRequestDto>[]> {
    const map = new Map<number, ValidationError<CreateDocumentRequestDto>[]>();
    const uniqueKeys = new Set<string>();
    for (let i = 0; i < dtos.length; i++) {
        const dto = dtos[i];
        const uniqueKey = `${dto.purchaseOrderNumber}|${dto.destinationZip}|${dto.trackingNumber}`;
        if (uniqueKeys.has(uniqueKey)) {
            map.set(i, [{ message: 'already exists in batch' }]);
        } else {
            uniqueKeys.add(uniqueKey);
        }
    }
    return map;
}
