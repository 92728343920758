import CONFIG from '../config';
import { FakeApiClient } from './fake';
import { RealApiClient } from './real';
import { ApiClient } from './types';

export const getApiService = (): ApiClient => {
    const apiClient = CONFIG.apiBaseUrl === 'fake' ? new FakeApiClient() : new RealApiClient(CONFIG.apiBaseUrl);

    return apiClient;
};
