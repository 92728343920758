import { ReactElement, useState } from 'react';
import './demo.css';

interface OwnProps {
    value: string;
    className: string;
    symbol?: string;
}

export default function DemoInput(props: OwnProps): ReactElement {
    const [inputValue, setInputValue] = useState(props.value);

    return (
        <>
            <div className="demo-input-text">
                {props.symbol ? <div className="demo-symbol"> {props.symbol}</div> : ''}
                <input
                    className={props.className}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    type="text"
                    disabled
                ></input>
            </div>
        </>
    );
}
