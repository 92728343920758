import { alignmentClassName, PageTableColumn } from '../page-table';
import { TableSort } from './sort-utils';
import { spIcons } from '@casestack/supplypike-ui';

interface OwnProps<T extends Record<string, unknown>> {
    column: PageTableColumn<T>;
    sort?: TableSort<T>;
    handleSortClick?: (column: keyof T) => void;
}

export default function HeaderCell<T extends Record<string, unknown>>(props: OwnProps<T>) {
    const { column, sort, handleSortClick } = props;

    /** If we are sorting by this column or not. */
    const sortOnColumn = sort?.column === column.key;

    /**
     * Only add these styles if sorting is enabled for this table.
     * See https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state for details on the 'group' keyword.
     */
    const sortHeaderClassNames = 'group flex items-center cursor-pointer pr-2 pl-2 rounded-full hover:bg-neutral-200';

    /**
     * How we want to rotate the sort icon.
     * E.g. either rotated 90 or -90 degrees to represent ASC or DESC sorting.
     */
    const sortIconRotationClassName = sort?.order === 'DESC' || !sortOnColumn ? 'rotate-90' : '-rotate-90';

    /**
     * If we want to display the sort icon or not.
     * Adds on hover styles to show the icon when hovering for columns we are not currently sorting by.
     * See var 'sortHeaderClassNames' description for details on 'group-hover'.
     */
    const showSortIconClassName = sortOnColumn ? 'visible' : 'invisible group-hover:visible group-hover:opacity-60';

    return sort && handleSortClick ? (
        <th key={column.key.toString()} className={alignmentClassName({ alignment: column.alignment })}>
            <div
                className={`flex items-center ${alignmentClassName({
                    alignment: column.alignment,
                    useFlexboxAlignment: true,
                })}`}
                onClick={() => column.supportsSort && handleSortClick(column.key)}
            >
                <div className={column.supportsSort ? sortHeaderClassNames : 'flex items-center'}>
                    {column.name}
                    <span className={`ml-2 ${sortIconRotationClassName} ${showSortIconClassName}`}>
                        <spIcons.normal.chevron />
                    </span>
                </div>
            </div>
        </th>
    ) : (
        // If sorting is not supported, just return the column name with the proper alignment.
        <th key={column.key.toString()} className={alignmentClassName({ alignment: column.alignment })}>
            {column.name}
        </th>
    );
}
