import { ReactElement, useEffect, useMemo, useState } from 'react';

import './modal-table-text-input.css';

interface OwnProps {
    label: string;
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    changeOnBlur?: boolean;
    onPaste?: (data: string) => boolean; // handled or not
    className?: string;
    maxLength?: number;
    required?: boolean;
    pattern?: string;
    disabled?: boolean;
    invalid?: boolean;
}

export default function ModalTableTextInput(props: OwnProps): ReactElement {
    const [value, setValue] = useState(props.value);

    const className = useMemo(() => {
        return ['modal-table-text-input', props.invalid && 'invalid', props.className].filter(s => s).join(' ');
    }, [props.invalid, props.className]);

    useEffect(() => setValue(props.value), [props.value]);

    return (
        <input
            type="text"
            className={className}
            aria-label={props.label}
            placeholder={props.placeholder}
            value={value}
            disabled={props.disabled}
            onBlur={() => props.changeOnBlur && props.onChange(value.trim())}
            onChange={event => (props.changeOnBlur ? setValue(event.target.value) : props.onChange(event.target.value))}
            onPaste={event =>
                props.onPaste && props.onPaste(event.clipboardData.getData('text')) && event.preventDefault()
            }
            maxLength={props.maxLength}
            required={props.required}
            pattern={props.pattern}
        />
    );
}
