import { FC } from 'react';
import { DocumentRequest } from '../../../types';
import { PageTableColumn, PageTableColumnWithKey } from '../../common/page-table';
import Placeholder, { roundedStyle } from '../../common/placeholder';
import DateCell from '../../common/table/date-cell';
import DocumentTypesCell from '../../common/table/document-types-cell';
import StatusCell from '../../common/table/status-cell';

function createSpecificDateCell(key: 'createdAt' | 'updatedAt', useTwoYearDate?: boolean, includeTimestamp?: boolean) {
    const SpecificDateCell: FC<{ data: DocumentRequest }> = ({ data }) => (
        <DateCell date={data[key]} useTwoYearDate={useTwoYearDate} includeTimestamp={includeTimestamp} />
    );
    return SpecificDateCell;
}

export const purchaseOrderNumberColumn: PageTableColumnWithKey<DocumentRequest> = {
    key: 'purchaseOrderNumber',
    name: 'PO Number',
    placeholder: <Placeholder style={roundedStyle('10ch', '1rem')} />,
};

export const invoiceNumberColumn: PageTableColumnWithKey<DocumentRequest> = {
    key: 'invoiceNumber',
    name: 'Invoice Number',
    placeholder: <Placeholder style={roundedStyle('5ch', '1rem')} />,
};

export const destinationZipColumn: PageTableColumnWithKey<DocumentRequest> = {
    key: 'destinationZip',
    name: 'Destination ZIP',
    placeholder: <Placeholder style={roundedStyle('5ch', '1rem')} />,
};

export const identifierColumns: Array<PageTableColumn<DocumentRequest>> = [
    purchaseOrderNumberColumn,
    invoiceNumberColumn,
    destinationZipColumn,
];

export const fileKindsColumn: PageTableColumn<DocumentRequest> = {
    key: 'fileKinds',
    name: 'Type(s)',
    component: DocumentTypesCell,
    placeholder: (
        <div className="flex flex-col">
            <Placeholder style={roundedStyle('8ch', '1.5rem')} />
            <Placeholder style={roundedStyle('8ch', '1.5rem')} className="mt-2" />
        </div>
    ),
};

export const createdByNameColumn: PageTableColumn<DocumentRequest> = {
    key: 'createdByName',
    name: 'Created By',
    alignment: 'right' as const,
    placeholder: <Placeholder style={roundedStyle('8ch', '1rem')} className="inline-block" />,
};

export const statusColumn: PageTableColumn<DocumentRequest> = {
    key: 'status',
    name: 'Status',
    alignment: 'right' as const,
    component: StatusCell,
    placeholder: <Placeholder style={roundedStyle('8ch', '1rem')} className="inline-block" />,
};

export function getCreatedAtColumn(params: {
    useTwoYearDate?: boolean;
    includeTimestamp?: boolean;
}): PageTableColumn<DocumentRequest> {
    return {
        key: 'createdAt',
        name: 'Create Date',
        alignment: 'right' as const,
        component: createSpecificDateCell('createdAt', params.useTwoYearDate, params.includeTimestamp),
        placeholder: (
            <div className="flex flex-col items-end">
                <Placeholder style={roundedStyle('10ch', '1rem')} />
                <Placeholder style={roundedStyle('12ch', '1rem')} className="mt-2" />
            </div>
        ),
    };
}
