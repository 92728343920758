import { spIcons } from '@casestack/supplypike-ui';

interface OwnProps {
    onClick: () => void;
}
export default function MultiSearchPrompt(props: OwnProps) {
    return (
        <div className="sp-dropdown-select-menu bg-neutral-000 light sp-body self-start">
            <div
                className="sp-dropdown-item flex items-center px-4 py-2 cursor-pointer select-none"
                onClick={() => props.onClick()}
                role="menuitem"
            >
                <div className="sp-dropdown-item-label-wrapper flex-grow flex justify-between flex-nowrap">
                    <span>Search Multiple…</span>
                    <spIcons.normal.chevron />
                </div>
            </div>
        </div>
    );
}
