import { addDays, addWeeks, getWeeksInMonth, startOfWeek, subMilliseconds } from 'date-fns';

import { dateToWmMonth, wmWeeksInWmMonth } from '@casestack/walmart-calendar';

import { dateSelectionOptionToLabel, dateSubPageToLabel } from './date-label';
import { DateSelectionOption, DateSelectionSubPage } from './date-selection';
import { addUtcDays, addUtcWeeks } from './date-utils';
import {
    DateSelectionTimeScale,
    DaySelectionTimeScale,
    WeekSelectionTimeScale,
    WmDaySelectionTimeScale,
    WmWeekSelectionTimeScale,
} from './time-scale';

export function initSubPages(subPageStartDates: Date[], timeScale: DateSelectionTimeScale): DateSelectionSubPage[] {
    switch (timeScale.paginationTimeScale) {
        case 'month': {
            return initSubPagesFromWeekStartDates(subPageStartDates, timeScale);
        }
        case 'wmMonth': {
            return initSubPagesFromWmWeekStartDates(subPageStartDates, timeScale);
        }
        case 'year': {
            return initSubPagesFromMonthStartDates(subPageStartDates, timeScale);
        }
        case 'wmYear': {
            return initSubPagesFromWmMonthStartDates(subPageStartDates, timeScale);
        }
    }
}

function initSubPagesFromWeekStartDates(
    subPageStartDates: Date[],
    timeScale: DaySelectionTimeScale
): DateSelectionSubPage[] {
    const subPages: DateSelectionSubPage[] = subPageStartDates.map(x => {
        const subPageStart = x;
        const options: DateSelectionOption[] = [];
        for (let i = 0; i < 7; i++) {
            const dayStart = addDays(subPageStart, i);
            const dayEnd = subMilliseconds(addDays(dayStart, 1), 1);
            const option: DateSelectionOption = {
                id: dayStart.getTime(),
                label: dateSelectionOptionToLabel(dayStart, timeScale),
                startDate: dayStart,
                endDate: dayEnd,
                isDisabled: true,
            };
            options.push(option);
        }
        const subPage: DateSelectionSubPage = {
            id: x.getTime(),
            label: dateSubPageToLabel(x, timeScale),
            options: options,
        };
        return subPage;
    });
    return subPages;
}

function initSubPagesFromWmWeekStartDates(
    subPageStartDates: Date[],
    timeScale: WmDaySelectionTimeScale
): DateSelectionSubPage[] {
    const subPages: DateSelectionSubPage[] = subPageStartDates.map(x => {
        const subPageStart = x;
        const options: DateSelectionOption[] = [];
        for (let i = 0; i < 7; i++) {
            const dayStart = addUtcDays(subPageStart, i);
            const dayEnd = subMilliseconds(addUtcDays(dayStart, 1), 1);
            const option: DateSelectionOption = {
                id: dayStart.getTime(),
                label: dateSelectionOptionToLabel(dayStart, timeScale),
                startDate: dayStart,
                endDate: dayEnd,
                isDisabled: true,
            };
            options.push(option);
        }
        const subPage: DateSelectionSubPage = {
            id: x.getTime(),
            label: dateSubPageToLabel(x, timeScale),
            options: options,
        };
        return subPage;
    });
    return subPages;
}

function initSubPagesFromMonthStartDates(
    subPageStartDates: Date[],
    timeScale: WeekSelectionTimeScale
): DateSelectionSubPage[] {
    const subPages: DateSelectionSubPage[] = subPageStartDates.map(x => {
        const numWeeksInSubPage = getWeeksInMonth(x);
        return initWeekSubPage(x, numWeeksInSubPage, timeScale);
    });
    return subPages;
}

function initSubPagesFromWmMonthStartDates(subPageStartDates: Date[], timeScale: WmWeekSelectionTimeScale) {
    const subPages: DateSelectionSubPage[] = subPageStartDates.map(x => {
        const wmMonth = dateToWmMonth(x);
        const wmWeeksInSubPage = wmWeeksInWmMonth(wmMonth);
        const numWeeksInSubPage = wmWeeksInSubPage.length;
        return initWeekSubPage(x, numWeeksInSubPage, timeScale);
    });
    return subPages;
}

function initWeekSubPage(
    subPageStartDate: Date,
    numWeeks: number,
    timeScale: WeekSelectionTimeScale | WmWeekSelectionTimeScale
): DateSelectionSubPage {
    const firstWeekStart = getFirstWeekStart(subPageStartDate, timeScale);
    const options: DateSelectionOption[] = [];
    for (let i = 0; i < numWeeks; i++) {
        const weekStart =
            timeScale.selectionTimeScale === 'wmWeek' ? addUtcWeeks(firstWeekStart, i) : addWeeks(firstWeekStart, i);
        const weekEndTemp =
            timeScale.selectionTimeScale === 'wmWeek' ? addUtcWeeks(weekStart, 1) : addWeeks(weekStart, 1);
        const weekEnd = subMilliseconds(weekEndTemp, 1);
        const option: DateSelectionOption = {
            id: weekStart.getTime(),
            label: dateSelectionOptionToLabel(weekStart, timeScale),
            startDate: weekStart,
            endDate: weekEnd,
            isDisabled: true,
        };
        options.push(option);
    }
    const subPage: DateSelectionSubPage = {
        id: subPageStartDate.getTime(),
        label: dateSubPageToLabel(subPageStartDate, timeScale),
        options: options,
    };
    return subPage;
}

function getFirstWeekStart(subPageStartDate: Date, timeScale: WeekSelectionTimeScale | WmWeekSelectionTimeScale): Date {
    const isWmWeek = timeScale.selectionTimeScale === 'wmWeek';
    if (isWmWeek) {
        return subPageStartDate;
    } else {
        return startOfWeek(subPageStartDate);
    }
}
