import React, { ReactElement, useEffect, useMemo, useContext } from 'react';
import { SpButton } from '@casestack/supplypike-ui';

import PopoverButton from '../../common/popover-button';
import SimpleSelect, { SimpleSelectOption } from '../../common/simple-select';
import { AuthenticatedProps } from '../../../auth/page-with-auth';
import {
    DocumentRequestStatus,
    DocumentRequestPendingSubstatus,
    isDocumentRequestStatus,
    purchaseOrderNumberStatuses,
    FileKind,
    DocumentPageSort,
    isFileKind,
} from '../../../api/types';
import { thirdPartyTrackEvent } from '../../../third-party';
import { UrlParamsContext } from '../../../contexts/url-params-context';
import { capitalizeFirstCharacter } from '../../../util/string-utils';
import { getFileKindsFilterLabel, getStatusFilterLabel } from '../filters-utils';

export interface DocumentFilters {
    fileKinds?: FileKind[];
    statuses?: (DocumentRequestStatus | DocumentRequestPendingSubstatus)[];
    sort?: DocumentPageSort;
}

export interface DocumentFiltersProps extends AuthenticatedProps {
    setFiltersCallback: (filters: DocumentFilters) => void;
}

const FILTER_KEYS: (keyof DocumentFilters)[] = ['fileKinds', 'statuses' /*, 'sort'*/];

const fileKindOptions: SimpleSelectOption<FileKind>[] = [
    {
        id: FileKind.BOL,
        label: 'Bill of Lading',
        value: FileKind.BOL,
    },
    {
        id: FileKind.POD,
        label: 'Proof of Delivery',
        value: FileKind.POD,
    },
    {
        id: FileKind.OTHER,
        label: 'Other',
        value: FileKind.OTHER,
    },
];

// sort temporarily disabled for performance reasons
/*const sortOptions: SimpleSelectOption<DocumentPageSort>[] = [
    {
        id: DocumentPageSort.DOCUMENT_REQUEST_CREATED_AT_DESC,
        label: 'Newest Requests First',
        value: DocumentPageSort.DOCUMENT_REQUEST_CREATED_AT_DESC,
    },
    {
        id: DocumentPageSort.DOCUMENT_REQUEST_CREATED_AT_ASC,
        label: 'Oldest Requests First',
        value: DocumentPageSort.DOCUMENT_REQUEST_CREATED_AT_ASC,
    },
    {
        id: DocumentPageSort.CARRIER_TRACKER_UPDATED_AT_DESC,
        label: 'Newest Activity First',
        value: DocumentPageSort.CARRIER_TRACKER_UPDATED_AT_DESC,
    },
    {
        id: DocumentPageSort.CARRIER_TRACKER_UPDATED_AT_ASC,
        label: 'Oldest Activity First',
        value: DocumentPageSort.CARRIER_TRACKER_UPDATED_AT_ASC,
    },
];*/

export default function DocumentFiltersComponent(props: DocumentFiltersProps): ReactElement {
    const { searchParams, updateSearchParam, deleteSearchParam } = useContext(UrlParamsContext);

    const statusOptions: SimpleSelectOption<DocumentRequestStatus | DocumentRequestPendingSubstatus>[] = useMemo(
        () => purchaseOrderNumberStatuses.map(s => ({ id: s, label: capitalizeFirstCharacter(s), value: s })) ?? [],
        []
    );

    /** parse the URL filters and if we turn off a filter if we have selected all options (or no options) */
    const filters = useMemo(() => {
        //const rawSort = searchParams.get('sort');
        const betterFilters: DocumentFilters = {
            //sort: isDocumentPageSort(rawSort) ? rawSort : undefined,
            fileKinds: searchParams.getAll('fileKinds').reduce((agg: FileKind[], curr) => {
                if (isFileKind(curr)) {
                    return [...agg, curr];
                }
                return agg;
            }, []),
            statuses: searchParams.getAll('statuses').reduce((agg: DocumentRequestStatus[], curr) => {
                if (isDocumentRequestStatus(curr)) {
                    return [...agg, curr];
                }
                return agg;
            }, []),
        };
        if (betterFilters.statuses?.length === 0 || betterFilters.statuses?.length === statusOptions.length) {
            betterFilters.statuses = undefined;
        }

        if (betterFilters.fileKinds?.length === 0 || betterFilters.fileKinds?.length === fileKindOptions.length) {
            betterFilters.fileKinds = undefined;
        }
        return betterFilters;
    }, [searchParams, statusOptions]);

    function updateFilters(key: keyof DocumentFilters, values: string[]) {
        updateSearchParam(key, values);
        deleteSearchParam('pageIndex');
        thirdPartyTrackEvent('Filters updated', { filters: filters });
    }

    function resetFilters() {
        for (const key of FILTER_KEYS) {
            deleteSearchParam(key);
        }
        deleteSearchParam('pageIndex');
        thirdPartyTrackEvent('Filters reset', { filters: filters });
    }

    const hasActiveFilters = Object.values(filters).some(v => v !== undefined);

    // send filters data to our parent
    const { setFiltersCallback } = props;
    useEffect(() => {
        setFiltersCallback(filters);
    }, [filters, setFiltersCallback]);

    return (
        <React.Fragment>
            <PopoverButton
                theme="filter"
                className="mt-2 ml-2"
                label={getStatusFilterLabel(filters.statuses)}
                active={!!filters.statuses}
                disabled={!props.supplier}
            >
                <SimpleSelect
                    options={statusOptions}
                    multiple={true}
                    clearText="All Statuses"
                    value={filters.statuses ?? []}
                    onChange={values => updateFilters('statuses', values)}
                />
            </PopoverButton>
            <PopoverButton
                theme="filter"
                className="mt-2 ml-2"
                label={getFileKindsFilterLabel(filters.fileKinds)}
                active={!!filters.fileKinds}
                disabled={!props.supplier}
            >
                <SimpleSelect
                    options={fileKindOptions}
                    multiple={true}
                    clearText="All Document Types"
                    value={filters.fileKinds ?? []}
                    onChange={values => updateFilters('fileKinds', values)}
                />
            </PopoverButton>
            {/*<PopoverButton
                theme="filter"
                className="mt-2 ml-2"
                label={filters.sort ? sortOptions.find(s => s.value === filters.sort)?.label : 'Sort By'}
                active={!!filters.sort}
                disabled={!props.supplier}
            >
                <SimpleSelect
                    options={sortOptions}
                    multiple={false}
                    clearText={'Default'}
                    value={filters.sort ?? null}
                    onChange={values => updateFilters('sort', values ? [values] : [])}
                />
            </PopoverButton>*/}
            <div className="flex align-center" style={{ width: 340 }}>
                <div className="mt-2 ml-2">
                    <SpButton
                        label="reset filters"
                        theme="text"
                        colorTheme="neutral"
                        disabled={!props.supplier || !hasActiveFilters}
                        onClick={resetFilters}
                    ></SpButton>
                </div>
            </div>
        </React.Fragment>
    );
}
