import {
    addMonths,
    addYears,
    eachMonthOfInterval,
    eachWeekOfInterval,
    startOfMonth,
    startOfYear,
    subMilliseconds,
} from 'date-fns';

import {
    addWmMonths,
    dateToWmMonth,
    dateToWmYear,
    wmMonthsInYear,
    wmMonthToDate,
    wmWeeksInWmMonth,
    wmYearToDate,
} from '@casestack/walmart-calendar';

import { datePageToLabel } from './date-label';
import { initSubPages } from './sub-page';
import { DateSelectionTimeScale } from './time-scale';

export interface DateSelectionOption {
    id: number;
    label: string;
    startDate: Date;
    endDate: Date;
    isDisabled: boolean;
}
export interface DateSelectionSubPage {
    id: number;
    label: string;
    options: DateSelectionOption[];
}

export interface DateSelectionPage {
    pageIndex: number;
    label: string;
    subPages: DateSelectionSubPage[];
}

/**
 * @param page0Date Any Date which falls within page zero.
 * @param timeScale Selection and pagination time scales.
 * @param pageOffset Any integer ( negative OK ).
 * @returns All the Dates which should be on the page.
 * Each Date corresponds to the beginning of a selectable time-range.
 */
export function getDateSelectionPage(
    page0Date: Date,
    timeScale: DateSelectionTimeScale,
    pageOffset: number
): DateSelectionPage {
    switch (timeScale.paginationTimeScale) {
        case 'month': {
            const page0Month = startOfMonth(page0Date);
            /**
             * addMonths() sets the day-of-month to the last day of the new month,
             * if it would otherwise be too large, as seen here:
             * https://github.com/date-fns/date-fns/blob/master/src/addMonths/index.ts#L44
             * addMonths() can also "add" a negative number of months,
             * subMonths() just calls addMonths(), as seen here:
             * https://github.com/date-fns/date-fns/blob/master/src/subMonths/index.ts#L31
             */
            const pageStart = addMonths(page0Month, pageOffset);
            const nextPageStart = addMonths(page0Month, pageOffset + 1);
            const pageEnd = subMilliseconds(nextPageStart, 1);
            switch (timeScale.subPageTimeScale) {
                case 'week': {
                    const subPageStartDates = eachWeekOfInterval({
                        start: pageStart,
                        end: pageEnd,
                    });
                    const subPages = initSubPages(subPageStartDates, timeScale);
                    enableDatesInPage(pageStart, pageEnd, subPages);
                    const page: DateSelectionPage = {
                        pageIndex: pageOffset,
                        label: datePageToLabel(pageStart, timeScale),
                        subPages: subPages,
                    };
                    return page;
                }
            }
        }
        case 'wmMonth': {
            const wmMonth0 = dateToWmMonth(page0Date);
            const wmMonth = addWmMonths(wmMonth0, pageOffset);
            const nextWmMonth = addWmMonths(wmMonth0, pageOffset + 1);
            const pageStart = wmMonthToDate(wmMonth);
            const nextPageStart = wmMonthToDate(nextWmMonth);
            const pageEnd = subMilliseconds(nextPageStart, 1);
            switch (timeScale.subPageTimeScale) {
                case 'wmWeek': {
                    const wmWeeks = wmWeeksInWmMonth(wmMonth);
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const subPageStartDates = wmWeeks.map(x => x.start!);
                    const subPages = initSubPages(subPageStartDates, timeScale);
                    enableDatesInPage(pageStart, pageEnd, subPages);
                    const page: DateSelectionPage = {
                        pageIndex: pageOffset,
                        label: datePageToLabel(pageStart, timeScale),
                        subPages: subPages,
                    };
                    return page;
                }
            }
        }
        case 'year': {
            const year0Start = startOfYear(page0Date);
            const pageStart = addYears(year0Start, pageOffset);
            const nextPageStart = addYears(year0Start, pageOffset + 1);
            const pageEnd = subMilliseconds(nextPageStart, 1);
            switch (timeScale.subPageTimeScale) {
                case 'month': {
                    const subPageStartDates = eachMonthOfInterval({
                        start: pageStart,
                        end: pageEnd,
                    });
                    const subPages = initSubPages(subPageStartDates, timeScale);
                    enableDatesInPage(pageStart, pageEnd, subPages);
                    const page: DateSelectionPage = {
                        pageIndex: pageOffset,
                        label: datePageToLabel(pageStart, timeScale),
                        subPages: subPages,
                    };
                    return page;
                }
            }
        }
        case 'wmYear': {
            const wmYear0 = dateToWmYear(page0Date);
            const wmYear = wmYear0 + pageOffset;
            const nextWmYear = wmYear0 + pageOffset + 1;
            const pageStart = wmYearToDate(wmYear);
            const nextPageStart = wmYearToDate(nextWmYear);
            const pageEnd = subMilliseconds(nextPageStart, 1);
            switch (timeScale.subPageTimeScale) {
                case 'wmMonth': {
                    const subPageStartDates = wmMonthsInYear(wmYear).map(x => wmMonthToDate(x));
                    const subPages = initSubPages(subPageStartDates, timeScale);
                    enableDatesInPage(pageStart, pageEnd, subPages);
                    const page: DateSelectionPage = {
                        pageIndex: pageOffset,
                        label: datePageToLabel(pageStart, timeScale),
                        subPages: subPages,
                    };
                    return page;
                }
            }
        }
    }
}

/**
 * Mutates `subPages`.
 * Enable an option only if it actually lies within the page.
 * This is not true for all options, because we show entire sub-pages
 * even if part of the sub-page is outside the page.
 */
function enableDatesInPage(pageStartDate: Date, pageEndDate: Date, subPages: DateSelectionSubPage[]) {
    for (let i = 0; i < subPages.length; i++) {
        const subPage = subPages[i];
        for (let j = 0; j < subPage.options.length; j++) {
            const option = subPage.options[j];
            if (option.endDate >= pageStartDate && option.endDate <= pageEndDate) {
                option.isDisabled = false;
            }
        }
    }
}

export function getClosestDateOption(
    timeScale: DateSelectionTimeScale,
    date: Date,
    direction: 'forwards' | 'backwards' | 'either',
    compareTo: 'start' | 'end'
): DateSelectionOption {
    const page = getDateSelectionPage(date, timeScale, 0);
    return getClosestDateOptionInPage(page, date, direction, compareTo);
}

/**
 * @param page Should contain at least one DateSelectionOption.
 * @param date any Date.
 * @returns The closest DateSelectionOption in the page.
 */
function getClosestDateOptionInPage(
    page: DateSelectionPage,
    date: Date,
    direction: 'forwards' | 'backwards' | 'either',
    compareTo: 'start' | 'end'
): DateSelectionOption {
    let closestOption: DateSelectionOption | null = null;
    let closestDistance: number = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < page.subPages.length; i++) {
        const subPage = page.subPages[i];
        for (let j = 0; j < subPage.options.length; j++) {
            const option = subPage.options[j];
            const distance = getDistanceToOption(date, option, direction, compareTo);
            if (distance < closestDistance) {
                closestOption = option;
                closestDistance = distance;
            }
        }
    }
    if (closestOption === null) {
        throw new Error('getClosestDateOptionInPage found no option!  Was the page parameter empty?');
    }
    return closestOption;
}

function getDistanceToOption(
    date: Date,
    option: DateSelectionOption,
    direction: 'forwards' | 'backwards' | 'either',
    compareTo: 'start' | 'end'
) {
    const optionDate = compareTo === 'start' ? option.startDate : option.endDate;
    switch (direction) {
        case 'forwards': {
            const distance = optionDate.getTime() - date.getTime();
            if (distance < 0) {
                return Number.MAX_SAFE_INTEGER;
            } else {
                return Math.abs(distance);
            }
        }
        case 'backwards': {
            const distance = optionDate.getTime() - date.getTime();
            if (distance > 0) {
                return Number.MAX_SAFE_INTEGER;
            } else {
                return Math.abs(distance);
            }
        }
        case 'either': {
            const distance = optionDate.getTime() - date.getTime();
            return Math.abs(distance);
        }
    }
}
