import { SpButton, spIcons } from '@casestack/supplypike-ui';
import { useState, ReactElement, useRef, useEffect } from 'react';
import { splitOnWhitespaceAndCommas } from '../../util/string-utils';
import Modal from './modal';
import ContextNotification from './context-notification';

interface OwnProps {
    title: string;
    label: string;
    maxSearchValues: number;
    initialValue?: string;
    onCancel(): void;
    onSearch(input: string): void;
}

export default function MultiSearchModal(props: OwnProps): ReactElement {
    const textAreaRef = useRef<null | HTMLTextAreaElement>(null);
    const [input, setInput] = useState<string>(props.initialValue || '');
    const [numSearchValues, setNumSearchValues] = useState<number>(0);

    const numberFormatter = Intl.NumberFormat('en-US');

    const handleSearch = () => {
        return props.onSearch(input);
    };

    // Makes the textarea element below focused when the modal is first opened
    useEffect(() => {
        textAreaRef?.current?.focus();
    }, []);

    useEffect(() => {
        setNumSearchValues(splitOnWhitespaceAndCommas(input).length);
    }, [input]);

    return (
        <Modal visible={true} onRequestClose={props.onCancel} widthClass="medium">
            <div className="flex flex-col w-6/8 bg-neutral-000 rounded-lg m-2 p-6 max-w-604px">
                <div className="flex flex-nowrap items-center justify-between mb-8">
                    <div className="sp-h2">{props.title}</div>
                    <div>
                        <button className="sp-icon-button neutral" onClick={props.onCancel}>
                            <spIcons.normal.x />
                        </button>
                    </div>
                </div>
                <div className="mb-2">
                    <textarea
                        ref={textAreaRef}
                        className="sp-textarea w-full modal-textarea"
                        rows={6}
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder="Enter PO Numbers or Invoice Numbers..."
                    />
                </div>
                <div className="mb-6 sp-body">{props.label}</div>
                {numSearchValues > props.maxSearchValues && (
                    <div className="mb-6">
                        <ContextNotification
                            theme="yellow"
                            icon={<spIcons.small.warning />}
                            title={`Only the first ${numberFormatter.format(
                                props.maxSearchValues
                            )} numbers will be searched.`}
                        >
                            Looks like you have added {numberFormatter.format(numSearchValues)} numbers. You can reduce
                            the list of numbers or try searching the last{' '}
                            {numSearchValues - props.maxSearchValues === 1
                                ? 'value'
                                : `${numberFormatter.format(numSearchValues - props.maxSearchValues)} values`}{' '}
                            separately.
                        </ContextNotification>
                    </div>
                )}
                <div className="flex justify-end border-t border-neutral-200 pt-2 gap-4">
                    <SpButton theme="outline-button" colorTheme="neutral" label="Cancel" onClick={props.onCancel} />
                    <SpButton colorTheme="blue" label="Search" onClick={handleSearch} disabled={!numSearchValues} />
                </div>
            </div>
        </Modal>
    );
}
