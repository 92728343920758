import { createContext } from 'react';
import { ToastColor } from '../components/common/toast';

export interface ToastParams {
    key: string;
    color?: ToastColor;
    title: string;
    body: string;
}

export interface ToastsContextValue {
    all: () => ToastParams[];
    add: (params: ToastParams) => void;
    delete: (key: string) => void;
    clear: () => void;
}

const ToastsContext = createContext<ToastsContextValue>({
    all: () => [],
    add: _params => undefined,
    delete: _index => undefined,
    clear: () => undefined,
});

export default ToastsContext;
